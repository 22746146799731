import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createManualRequest, lisingWalletConfirmation, WalletApproval } from "../../redux/wallets/actions";
import { RootState } from "../../redux/store";
import CommonSearchBar from "../../layouts/SearchBar";
import { Button, Card, Nav, Tab } from "react-bootstrap";
import Table from "../../components/Table";
import { listingUsers } from "../../redux/users/actions";
import { WalletTableColumns } from "../wallet/data";
import Pagination from '../../components/customPaginationComponent/customPagination';
import CreateWalletRequestModal from "./CreateManualWalletRequest";
import { listingHotels } from "../../redux/hotels/actions";
import { listingOutlets } from "../../redux/outlets/actions";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import { tab } from "@testing-library/user-event/dist/tab";

const WalletListPage = () => {
  // imports of the page
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, limit, name ,tab} = useParams();
  const { walletList } = useSelector((state: RootState) => state.WalletReducer);
  const { hotelsList } = useSelector((state: RootState) => state.HotelsReducer);
  const { outletsList } = useSelector(
    (state: RootState) => state.OutletsReducer
  );
  const { usersList } = useSelector(
    (state: RootState) => state.UsersReducer
  );
    //   states
    const[currentPage, setCurrentPage] = useState(page)
    const [newWalletRequestModal, setWAlletRequestModal] = useState(false);
    const [selectedhotel, setSelectedhotel] = useState('');
  // initial loading of a page
  useEffect(() => {
    dispatch(lisingWalletConfirmation({ name: name === "all" ? "" : name, page:page, limit:limit }));
    dispatch(listingHotels({ page: page, limit: limit}));
    dispatch(listingUsers({page: page,limit:limit,userStatus: "VERIFIED"}))
  }, []);

    //   list wallet search as per name
    const searchWalletsList = (searchName:any) => {
        console.log("the search wallet list by name", searchName.target.value)
        navigate(`/wallet/0/50/${searchName.target.value ? searchName.target.value : "all"}`)
        dispatch(lisingWalletConfirmation({ name: name === "all" ? "" : name }));

    }

    // column definition for the table
    const columns: any = [
        // {
        //   Header: "ID",
        //   accessor: "id",
        //   sort:false,
        // },
        {
          Header: "Amount",
          accessor: "amount",
          sort:false,
        },
        {
          Header: "Name",
          accessor: "user",
          sort: false,
        },
        {
          Header: "Approve",
          accessor: "adminApproved",
          sort:false,
        },
      ];
// confirm wallet
const confirmWallet = (e: any,details: any) => {
    console.log("the wallet details", e, details)
    dispatch(WalletApproval(details?._id,{name:  name === "all" ? "" : name, page: page, limit: limit}))
}
// modal controls
const openModal = () => setWAlletRequestModal(!newWalletRequestModal)

const addHotelUsersForAddSlots = (e:any) => {
    console.log("the ee",e, selectedhotel)
    const payload = { "user":e.users[0].value,
      "hotel":selectedhotel,
      "outlet":e.outlet[0].value,
      "remarks":e.remarks,
      "amount":e.amount
    }
    dispatch(createManualRequest(payload))
    setWAlletRequestModal(!newWalletRequestModal)
    dispatch(lisingWalletConfirmation({ name: name === "all" ? "" : name, page:page, limit:limit }));
}
const filterValueChangeByHotelsOrOutlets = (fieldname: any, e: any) => {
    console.log("the name , e", fieldname, e);
    if (fieldname === "hotels") {
      setSelectedhotel(e[0]?.value)
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotel:e[0]?.value ? e[0]?.value : '',
          page: page,
          limit: limit,
        })
      );
    } else if (fieldname === "outlets") {
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          outlet: e[0]?.value ? e[0]?.value : '',
          page: page,
          limit: limit,
        })
      );
    }
  };


  const searchByName = (name: any, value: any) => {
    if (name === "hotels") {
      dispatch(listingHotels({ hotelName: value ? value : '' }));
    }
    if(name === "user"){
      dispatch(listingUsers({userStatus: "VERIFIED", name: value? value : ''}))
    }
    if(name === "outlets") {
       selectedhotel && ( dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, { name: value ? value : '' , hotel: selectedhotel})
      ))
    }
  };

  // filter options
  const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.hotelName ? list.hotelName : " ",
      })
    );
    return hotel;
  };
  const outletOptionsListForSearch = (data: any) => {
   
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.outletName ? list.outletName : " ",
      })
    );
    return hotel;
  };

  const setUserListOptions = (data:any) => {
    console.log("the users",data)
    let users: any = [];
    data?.data?.map((list: any, index: any) =>
      users.push({
        id: index,
        value: list._id,
        label: list.name ? list.name : " ",
      })
    );
    return users;
  }

  return (
    <>
      <h1>Wallet List page</h1>
      {/* create manual wallet */}
      <Button
        style={{float:'right'}}
        variant="success"
        className="waves-effect waves-light"
        onClick={openModal}
        >
        <i className="mdi mdi-plus-circle me-1"></i> Add New Wallet Request
        </Button>
        {/* modal */}
        <CreateWalletRequestModal
        responsiveModalForAddWalletRequest={newWalletRequestModal}
        toggleResponsiveModalForAddWalletRequest={openModal}
        addHotelUsersForAddSlots={addHotelUsersForAddSlots}
        filterValueChangeByHotelsOrOutlets={filterValueChangeByHotelsOrOutlets}
        hotelsList={hotelOptionsListForSearch(hotelsList) ? hotelOptionsListForSearch(hotelsList) : []}
        outletsList={outletOptionsListForSearch(outletsList) ?  outletOptionsListForSearch(outletsList) : []}    
        setResponsiveModalForAddWalletRequest={setWAlletRequestModal}
        searchByName={searchByName}
        usersList={setUserListOptions(usersList)}
        />
          <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={tab === "0" ? "first" : "second"}
        onSelect={(e) =>
          navigate(
            `/wallet/${page}/${limit}/${e === "first" ? "0" : "1"}/${name}`
          )
        }
      >
         <Nav as="ul" variant="tabs" className="nav nav-tabs nav-bordered">
          <Nav.Item as="li">
            <Nav.Link eventKey="first" className="cursor-pointer">
              Wallet List
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="second" className="cursor-pointer">
              Confirmed Wallet List
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
        <Tab.Pane eventKey="first">
{/* header */}
<CommonSearchBar
        name={name}
        searchTitle={"Search Users..."}
        search={(e: any) => searchWalletsList(e)}
        filters={[]}
      />
      {/* table */}
      <Card>
        <Card.Body>
            <Table
            columns={columns}
            data={WalletTableColumns(walletList,confirmWallet)}
            isSortable={true}
            />
        <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={walletList?.totalCount}
        pageSize={10}
        onPageChange={(page: any) => {
            setCurrentPage(page)
            navigate(`/wallet/${page}/${limit}/${name}`);
            // dispatch(listingUsers({userStatus: userStatus,page: page,limit: limit}))
        }}
      />
            </Card.Body>
          </Card>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
          {/* header */}
<CommonSearchBar
        name={name}
        searchTitle={"Search Users..."}
        search={(e: any) => searchWalletsList(e)}
        filters={[]}
      />
      {/* table */}
      <Card>
        <Card.Body>
            <Table
            columns={columns}
            data={WalletTableColumns(walletList,confirmWallet)}
            isSortable={true}
            />
        <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={walletList?.totalCount}
        pageSize={10}
        onPageChange={(page: any) => {
            setCurrentPage(page)
            navigate(`/wallet/${page}/${limit}/${name}`);
            // dispatch(listingUsers({userStatus: userStatus,page: page,limit: limit}))
        }}
      />
            </Card.Body>
          </Card>
        </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      
    </>
  );
};

export default WalletListPage;
