// constants
import { InvoiceActionTypes } from "./constants";

const INIT_STATE = {
  invoicelist: [],
  invoiceNameList: [],
  invoicelistLoading: false,
  downloadInvoiceLoading: false,
  sendInvoiceLoading: false,
  invoiceById: {},
  updateInvoiceLoading: false,
  createInvoiceLaoding: false,
  createDebitCreditNotesLoading: false,
  mergeInvoiceLoading: false,
  splitInvoiceLoading: false,
  invoiceByIdLoading: false,
  page: 0,
  limit: 10,
};

interface AuthActionType {
  type:
    | InvoiceActionTypes.API_INVOICES_RESPONSE_ERROR
    | InvoiceActionTypes.API_INVOICES_RESPONSE_SUCCESS
    | InvoiceActionTypes.CREATE_DEBIT_CREDIT_NOTE
    | InvoiceActionTypes.CREATE_DEBIT_CREDIT_NOTE_LOADING
    | InvoiceActionTypes.CREATE_NEW_INVOICES
    | InvoiceActionTypes.CREATE_NEW_INVOICES_LOADING
    | InvoiceActionTypes.DOWNLOAD_INOVICE
    | InvoiceActionTypes.DOWNLOAD_INOVICE_LOADING
    | InvoiceActionTypes.GET_INVOICE_BY_ID
    | InvoiceActionTypes.GET_INVOICE_BY_ID_LOADING
    | InvoiceActionTypes.LIST_INVOICE
    | InvoiceActionTypes.LIST_INVOICE_LOADING
    | InvoiceActionTypes.MERGE_INVOICE
    | InvoiceActionTypes.MERGE_INVOICE_LOADING
    | InvoiceActionTypes.RESET_INVOICES
    | InvoiceActionTypes.SEND_INVOICE
    | InvoiceActionTypes.SEND_INVOICE_LOADING
    | InvoiceActionTypes.SPLIT_INVOICE
    | InvoiceActionTypes.SPLIT_INVOICE_LOADING
    | InvoiceActionTypes.UPDATE_INVOICE
    | InvoiceActionTypes.UPDATE_INVOICE_LOADING
    | InvoiceActionTypes.INVOICE_LIST_NAMES;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  invoicelist: any[],
  invoiceNameList: any[],
  invoicelistLoading: boolean,
  downloadInvoiceLoading: boolean,
  sendInvoiceLoading: boolean,
  invoiceByIdLoading: boolean;
  invoiceById: {},
  updateInvoiceLoading: boolean,
  createInvoiceLaoding: boolean,
  createDebitCreditNotesLoading: boolean,
  mergeInvoiceLoading: boolean,
  splitInvoiceLoading: boolean,
  page: number,
  limit: number,
}

const InvoiceReducer = (
  state: State = INIT_STATE,
  action: AuthActionType
): any => {
  switch (action.type) {
    case InvoiceActionTypes.API_INVOICES_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case InvoiceActionTypes.CREATE_DEBIT_CREDIT_NOTE_LOADING: {
          return {
            ...state,
            createDebitCreditNotesLoading: true
          };
        }
        case InvoiceActionTypes.CREATE_DEBIT_CREDIT_NOTE: {
          return {
            ...state,
            createDebitCreditNotesLoading: false
          };
        }
        case InvoiceActionTypes.CREATE_NEW_INVOICES_LOADING: {
          return {
            ...state,
            createInvoiceLaoding: true
          };
        }
        case InvoiceActionTypes.CREATE_NEW_INVOICES: {
          return {
            ...state,
            createInvoiceLaoding: false
          };
        }
        case InvoiceActionTypes.DOWNLOAD_INOVICE_LOADING: {
          return {
            ...state,
            downloadInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.DOWNLOAD_INOVICE: {
          return {
            ...state,
            downloadInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.GET_INVOICE_BY_ID_LOADING: {
          return {
            ...state,
            invoiceByIdLoading: true
          };
        }
        case InvoiceActionTypes.GET_INVOICE_BY_ID: {
          return {
            ...state,
            invoiceByIdLoading: false,
            invoiceById: action.payload.data
          };
        }
        case InvoiceActionTypes.LIST_INVOICE_LOADING: {
          return {
            ...state,
            invoicelistLoading: true
          };
        }
        case InvoiceActionTypes.LIST_INVOICE: {
          return {
            ...state,
            invoicelistLoading: false,
            invoicelist:action.payload.data
          };
        }
        case InvoiceActionTypes.MERGE_INVOICE_LOADING: {
          return {
            ...state,
            mergeInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.MERGE_INVOICE: {
          return {
            ...state,
            mergeInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.SPLIT_INVOICE_LOADING: {
          return {
            ...state,
            splitInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.SPLIT_INVOICE: {
          return {
            ...state,
            splitInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.SEND_INVOICE_LOADING: {
          return {
            ...state,
            sendInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.SEND_INVOICE: {
          return {
            ...state,
            sendInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.UPDATE_INVOICE_LOADING: {
          return {
            ...state,
            updateInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.UPDATE_INVOICE: {
          return {
            ...state,
            updateInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.INVOICE_LIST_NAMES: {
          return {
            ...state,
            invoiceNameList: action.payload.data
          };
        }
        default:
          return { ...state };
      }

    case InvoiceActionTypes.API_INVOICES_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case InvoiceActionTypes.CREATE_DEBIT_CREDIT_NOTE_LOADING: {
          return {
            ...state,
            createDebitCreditNotesLoading: true
          };
        }
        case InvoiceActionTypes.CREATE_DEBIT_CREDIT_NOTE: {
          return {
            ...state,
            createDebitCreditNotesLoading: false
          };
        }
        case InvoiceActionTypes.CREATE_NEW_INVOICES_LOADING: {
          return {
            ...state,
            createInvoiceLaoding: true
          };
        }
        case InvoiceActionTypes.CREATE_NEW_INVOICES: {
          return {
            ...state,
            createInvoiceLaoding: false
          };
        }
        case InvoiceActionTypes.DOWNLOAD_INOVICE_LOADING: {
          return {
            ...state,
            downloadInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.DOWNLOAD_INOVICE: {
          return {
            ...state,
            downloadInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.GET_INVOICE_BY_ID_LOADING: {
          return {
            ...state,
            invoiceByIdLoading: true
          };
        }
        case InvoiceActionTypes.GET_INVOICE_BY_ID: {
          return {
            ...state,
            invoiceByIdLoading: false,
            invoiceById: action.payload.data
          };
        }
        case InvoiceActionTypes.LIST_INVOICE_LOADING: {
          return {
            ...state,
            invoicelistLoading: true
          };
        }
        case InvoiceActionTypes.LIST_INVOICE: {
          return {
            ...state,
            invoicelistLoading: false,
            invoicelist:action.payload.data
          };
        }
        case InvoiceActionTypes.MERGE_INVOICE_LOADING: {
          return {
            ...state,
            mergeInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.MERGE_INVOICE: {
          return {
            ...state,
            mergeInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.SPLIT_INVOICE_LOADING: {
          return {
            ...state,
            splitInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.SPLIT_INVOICE: {
          return {
            ...state,
            splitInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.SEND_INVOICE_LOADING: {
          return {
            ...state,
            sendInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.SEND_INVOICE: {
          return {
            ...state,
            sendInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.UPDATE_INVOICE_LOADING: {
          return {
            ...state,
            updateInvoiceLoading: true
          };
        }
        case InvoiceActionTypes.UPDATE_INVOICE: {
          return {
            ...state,
            updateInvoiceLoading: false
          };
        }
        case InvoiceActionTypes.INVOICE_LIST_NAMES: {
          return {
            ...state,
            invoiceNameList: action.payload.data
          };
        }
     
        default:
          return { ...state };
      }

    case InvoiceActionTypes.RESET_INVOICES:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default InvoiceReducer;
