import { Routes, Route } from "react-router-dom"
import { searchFilterForGroups } from "./data"
import AttendanceLisitingPage from "./AttendanceListingPage"
import EditAttendanceDetails from "./editAttendanceDetails"


const Attendance = () => {
  
        return(
            <>
            <Routes>
              <Route path="/" element={<AttendanceLisitingPage />} />
              <Route 
              path={`/:${searchFilterForGroups[0].key}/:${searchFilterForGroups[1].key}/:${searchFilterForGroups[2].key}/:${searchFilterForGroups[3].key}/:${searchFilterForGroups[4].key}`}
              element={<AttendanceLisitingPage />} />
               <Route 
          path={`?startDate=`}
          element={<AttendanceLisitingPage />} />
          <Route 
          path={`?endDate=`}
          element={<AttendanceLisitingPage />} />
              <Route 
              path={`edit/:id`}
              element={<EditAttendanceDetails />} />
               <Route 
              path={`delete/:id`}
              element={<AttendanceLisitingPage />} />
            </Routes>
            </>
        )

}

export default Attendance