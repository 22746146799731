import { APICore } from "./apiCore";


const api = new APICore();

// account
function listUsers(payload: any) {
  let baseUrl = `user/getUsers`
  const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
        
    return api.get(`${baseUrl}?${params}`)
}

function listUsersPaymentHistory(data: any) {
  let baseUrl = `user/paymentHistory`
  // const params =  Object.keys(data.payload)
  //           .map((key) => key + "=" + data.payload[key])
  //           .join("&")
        
  return api.get(`${baseUrl}/${data.id}?page=${data.page}&limit=${data.limit}`)

}
function listUsersRedeemHistory(data: any) {
  console.log("hthe vl",data)
  let baseUrl = `user/redeemHistory`
  // const params =  Object.keys(data.payload)
  //           .map((key) => key + "=" + data.payload[key])
  //           .join("&")
        
    return api.get(`${baseUrl}/${data.id}?page=${data.page}&limit=${data.limit}`)
}
function listCountries():any{
  let baseUrl = `user/countries`
    return api.get(`${baseUrl}`)
}
function listusersById(id: any) {
  console.log("te id", id)
  const baseUrl = "user/getUser";
  return api.get(`${baseUrl}/${id}`,{});
}
function listusersSlotsById({page,limit,id,type}:any) {
  const baseUrl = "user/";
  if(type === "upComingSlots"){
    return api.get(`${baseUrl}${type}/?page=${page}&limit=${limit}&userId=${id}&status=waiting`,{});
  }
  else{
    return api.get(`${baseUrl}${type}/?page=${page}&limit=${limit}userId=${id}`,{});
  }

}


function listInstitutions(searchParams: any){
  const baseUrl = "user/institutions";
  return api.get(`${baseUrl}`,{searchParams})
}

function creatUsers(payload: any) {
  const baseUrl = "support/add/";
  return api.create(`${baseUrl}`, payload);
}

function editUsers(params: any) {
  const baseUrl = `user/updateProfile`;
  return api.updatePatch(`${baseUrl}`, params);
}
function editUsersStatus(params: any) {
  const baseUrl = `user/update`;
  return api.updatePatch(`${baseUrl}`,params );
}

function deleteUsers(id: any) {
  const baseUrl = `user/delete/${id}`;
  return api.updatePatch(`${baseUrl}`,{});
}

export { listusersSlotsById,listUsers,listCountries, editUsersStatus, creatUsers, editUsers, listUsersRedeemHistory, deleteUsers ,listUsersPaymentHistory, listusersById, listInstitutions};
