import { APICore } from "./apiCore";


const api = new APICore();

// account
function listgroups(payload: any) {
  const baseUrl = "/usergroups/list";
  console.log('theparams',payload)
  const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
  return api.get(`${baseUrl}?${params}`)
  // return axios.get(`${baseUrl}`, params);
  // if(!params.name && !params.hotel && !params.outlet){

  //   return api.get(`${baseUrl}?page=${params?.page}&limit=${params?.limit}`, {});
  // }
  // if(params.name && !params.hotel && !params.outlet){
  //   return api.get(`${baseUrl}?page=${params?.page}&limit=${params?.limit}&groupTitle=${params.name? params.name : null}`, {});
  // }
  // if(params.hotel && !params.name && !params.outlet){

  //   return api.get(`${baseUrl}?page=${params?.page}&limit=${params?.limit}&hotel=${params.hotel ? params.hotel : null}`, {});
  // }
  // if(params.outlet && !params.name && !params.hotel){

  //   return api.get(`${baseUrl}?page=${params?.page}&limit=${params?.limit}&outlet=${params.outlet ? params.outlet : null}`, {});
  // }
  // if(params.name && params.hotel && params.outlet){

  //   return api.get(`${baseUrl}?page=${params?.page}&limit=${params?.limit}&groupTitle=${params.name? params.name : null}&hotel=${params.hotel ? params.hotel : null}&outlet=${params.outlet ? params.outlet : null}`, {});
  // }
  }
function addgroups(params: any) {
  const baseUrl = "/usergroups/add";
  console.log('theparams',params)
  // return axios.get(`${baseUrl}`, params);
  return api.create(`${baseUrl}`, params);
}
function listGroupsById(id: any) {
  const baseUrl = "/usergroups/list";
  return api.get(`${baseUrl}/${id}`,{} );
}
function deleteGroup(id: string) {
  const baseUrl = `/usergroups/delete/${id}`;
  return api.updatePatch(`${baseUrl}`,{});
}

function editGroups(params: any) {
  const baseUrl = `usergroups/edit/`;
  return api.updatePatch(`${baseUrl}${params.id}`, params.data);
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { listgroups,addgroups,listGroupsById,editGroups, deleteGroup, signup, forgotPassword };
