
// constants
import { ChatActionTypes } from "./constants";


const INIT_STATE = {
  chatsLoading: false,
  chats:[],
  messageLoading:false,
  messages:[],
};



interface AuthActionType {
  type:
    | ChatActionTypes.GET_CHATS
    | ChatActionTypes.GET_CHATS_LOADING
    | ChatActionTypes.GET_MESSAGES
    | ChatActionTypes.GET_MESSAGES_LOADING
    | ChatActionTypes.API_RESPONSE_ERROR
    | ChatActionTypes.API_RESPONSE_SUCCESS
    | ChatActionTypes.RESET;
  payload: {
    actionType?: string;
    data?: any | {};
    error?: string;
  };
}

interface State {
  chatsLoading: Boolean,
  chats:any[],
  messageLoading:Boolean,
  messages:any[],
}

const ChatReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case ChatActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ChatActionTypes.GET_CHATS: {
          return {
            ...state,
            chats: action.payload.data,
            chatsLoading: false,
          };
        }
        case ChatActionTypes.GET_CHATS_LOADING: {
          return {
            ...state,
            chatsLoading: true,
          };
        }
        case ChatActionTypes.GET_MESSAGES:{
          return{
            ...state,
            messages: action.payload.data,
            messageLoading: false
          }
        }
        case ChatActionTypes.GET_MESSAGES_LOADING: {
          return {
            ...state,
            messageLoading: true
          };
        }
        
        default:
          return { ...state };
      }

    case ChatActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ChatActionTypes.GET_CHATS: {
          return {
            ...state,
            chats: action.payload.data,
            chatsLoading: false,
          };
        }
        case ChatActionTypes.GET_CHATS_LOADING: {
          return {
            ...state,
            chatsLoading: true,
          };
        }
        case ChatActionTypes.GET_MESSAGES:{
          return{
            ...state,
            messages: action.payload.data,
            messageLoading: false
          }
        }
        case ChatActionTypes.GET_MESSAGES_LOADING: {
          return {
            ...state,
            messageLoading: true
          };
        }
        
        default:
          return { ...state };
         
      }
    case ChatActionTypes.RESET:
      return {
        ...state,
        chatsLoading: false,
        chats:[],
        messageLoading:false,
        messages:[],
      };
    default:
      return { ...state };
  }
};

export default ChatReducer;
