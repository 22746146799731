import { APICore } from "./apiCore";

const api = new APICore();



export function listStaffStatus(payload: any){
    const baseUrl = "staffstatus/list"; 
    const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
    if(payload.name){
        return api.get(`${baseUrl}?${params}&search_query=${payload.name}`)
    }
    else{
        return api.get(`${baseUrl}?${params}&search_query`)
    }

}


export function listStaffStatusByOutlet(payload: any){
    const baseUrl = `staffstatus/list/${payload.id}`; 
    const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
        
    return api.get(`${baseUrl}?${params}&search_query`)
}