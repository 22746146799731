import { APICore } from "./apiCore";
import axios from "axios";
import { toast } from "react-toastify";


const api = new APICore();

// const dispatch = useDispatch();


export function listAttendances(payload: any){
    const baseUrl = "attendance/list"; 
    const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
    return api.get(`${baseUrl}?${params}`)
}

export function getAttendanceById(id: any){
    const baseUrl = "attendance/getAttendance"; 
    return api.get(`${baseUrl}/${id}`)
}
export function updateAttendanceById(details: any){
    const baseUrl = "attendance/update"; 
    return api.update(`${baseUrl}/${details.id}/${details.status}`,{})
}

export function deletAttendanceById(id: any){
    const baseUrl = "attendance/delete"; 
    return api.updatePatch(`${baseUrl}/${id}`,{})
}

export function lockAttendanceById(id: any){
    const baseUrl = "attendance/lock"; 
    return api.updatePatch(`${baseUrl}/${id}`,{})
}
export function unlockAttendanceById(id: any){
    const baseUrl = "attendance/unlock"; 
    return api.updatePatch(`${baseUrl}/${id}`,{})
}

export function sendAttendanceById(id: any){
    const baseUrl = "attendance/send"; 
    return api.get(`${baseUrl}/${id}`,{})
}

export async function generateAttendanceById(id: any){
    console.log("the id in fun", id)
    const baseUrl = "attendance/download"; 
    // axios.get(`${url}?${queryString}`, { responseType: "blob" })
//   return  api.getFile(`${baseUrl}/668ecd8443fefc38373f3754`,{})

return axios.get(`${baseUrl}/${id}`, {
    responseType: 'blob'
 })
 .then((response) => {
    
    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });
    //Build a URL from the file 
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
     const pdfWindow =  window.open(fileURL, '_blank','noopener');
    
    //  if(pdfWindow){
    //     pdfWindow.location.href = fileURL;  
    //  }
    //  window.reload();
     toast.success("Downloaded Successfully");
         
  })
  .catch((error) => {
    console.log(error);

    toast.error(error.response.statusText);
  });
}



