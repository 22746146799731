import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Dropdown, Modal, Button, Form } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { listingSlotsById } from "../../redux/slots/actions";
import { RootState } from "../../redux/store";
import { editAttendance, listingAttendanceById } from "../../redux/attendance/actions";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FormInput } from "../../components";
import { navaigate } from "../../redux/users/saga";
import { Typeahead } from "react-bootstrap-typeahead";

const EditAttendanceDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {selectedAttendanceDetails} = useSelector((state:RootState) => state.AttendanceReducer)
  const { positions } = useSelector(
    (state: RootState) => state.SlotsReducer
  );
  useEffect(() => {
    dispatch(listingAttendanceById(id))
  },[])

  console.log('the ame', selectedAttendanceDetails)
  const methods = useForm({
    defaultValues: { 
      slots: [
        {
          id:"",
          shiftName: "",
          startTime: "",
          endTime: "",
          hourlyPay: "",
          vacancy: "",
          release: "",
          allocatedUsers: [],
        },
      ],
          date: "",
          hotelName: "",
          outletName: "",
          attendancestatus: "",
    },
  });
  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "slots",
  });

  useEffect(() => {
    let slotsDetailsArray:any = []
    let users:any = []
    selectedAttendanceDetails?.slots?.map((slotDetails:any) => 
      slotsDetailsArray.push({
        id: slotDetails._id,
        shiftName:slotDetails.shiftName ,
        startTime: slotDetails.startTime,
        endTime: slotDetails.endTime,
        hourlyPay: slotDetails.hourlyPay,
        vacancy: slotDetails.vacancy,
        release: slotDetails.release,
        allocatedUsers: slotDetails?.users.map((user:any) => 
        users.push({
          id:user?.id
        })
        ),
      }))
      console.log('the slots dertails', slotsDetailsArray)
    if(selectedAttendanceDetails){
      reset({
        date: selectedAttendanceDetails?.date,
        hotelName: selectedAttendanceDetails?.hotelName,
        outletName: selectedAttendanceDetails?.outletName,
        attendancestatus: selectedAttendanceDetails?.status,
        slots:slotsDetailsArray
      })
    }
  },[selectedAttendanceDetails])

  console.log("thhe vale", getValues())
  const addNewSlotfromAttendance = () => {
    localStorage.setItem('hotel', selectedAttendanceDetails?.hotelName)
    localStorage.setItem('outlet', selectedAttendanceDetails?.outletName)
    navigate(`/slots/list/add`)
  }

  

  const editAttendanceSubmit = () => {
    console.log("the val", getValues())
    dispatch(editAttendance({id: id, status: getValues().attendancestatus}))
  }

  return (
    <>
    
      <div style={{display:'flex', justifyContent:'space-between', paddingTop:'5px'}}>
      <h2>
      
        Edit Attendance</h2>
      <Button
        style={{ float: "right" }}
        variant="success"
        className="waves-effect waves-light"
        onClick={() => addNewSlotfromAttendance()}
      >
        <i className="mdi mdi-plus-circle me-1"></i> Add New Slot
      </Button>
      </div>
      <h3>{selectedAttendanceDetails?.attendanceName}</h3>
      <Card>
        <Card.Body>
        <div style={{display:'flex', justifyContent:'end', fontSize:'x-large'}} onClick={() => navigate(-1)}><i className="bi bi-x"></i></div> 
          <form onSubmit={handleSubmit(editAttendanceSubmit)}>
          <Row>
            <Col md={4}>
            <FormInput
      label="Date"
      type="text"
      name="date"
      register={register}
      disabled
      containerClass={"mb-3"}
      />
            </Col>
            <Col md={4}>
            <FormInput
      label="Hotel Name"
      type="text"
      name="hotelName"
      register={register}
      disabled
      containerClass={"mb-3"}
      />
            </Col>
            <Col md={4}>
            <FormInput
      label="Outlet Name"
      type="text"
      name="outletName"
      register={register}
      disabled
      containerClass={"mb-3"}
      />
            </Col>
           <Row>
           <Col md={4}>
            <FormInput
        label="Select Status"
        type="select"
        name="attendancestatus"
        key="selectMulti"
        register={register}
        placeholder="Select a roles"
        containerClass={"mb-3"}
            >
        <option value="PENDING STAFF">PENDING STAFF</option>
        <option value="READY TO SEND">READY TO SEND</option>
        <option value="SENT">SENT</option>
        <option value="RECEIVED BACK">RECEIVED BACK</option>
        <option value="COMPLETED">COMPLETED</option>
    </FormInput>
            </Col>
           </Row>
          </Row>
          {fields.map((field, index) => (
          <>
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <h5>{index + 1}:Slot details</h5>
          <Button onClick={() => navigate(`/slots/list/edit/${field.id}`)} >Edit Slot</Button>
          </div>
            <Row>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.startTime`}
                  
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Start Time
                      </label>
                      <Form.Control
                       disabled
                    type="time"
                    placeholder="Start Time"
                  {...register(`slots.${index}.startTime`)}
                  // onChange={(e) => setStartTimeState(e.target.value)}
                 />
                  {/* </Form.Control> */}
                    </div>
                  )}
                /> 
              </Col>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.endTime`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        End Time
                      </label>
                      <Form.Control
                    type="time"
                    placeholder="End Time"
                    disabled
                  {...register(`slots.${index}.endTime`)}
                  // onChange={(e) => setEndTimeState(e.target.value)}
                 />
                    </div>
                  )}
                />
              </Col>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.hourlyPay`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Hourly Pay
                      </label>
                      <Form.Control
                    type="number"
                    disabled
                    placeholder="Hourly Pay"
                  {...register(`slots.${index}.hourlyPay`)}
                  // onChange={(e) => setHourlypay(e.target.value)}
                 />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.vacancy`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                       Vacany
                      </label>
                      <Form.Control
                      disabled
                    type="number"
                    placeholder="Vacany"
                  {...register(`slots.${index}.vacancy`)}
                 />
                    </div>
                  )}
                />
              </Col>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.release`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                       Vacancy
                      </label>
                      <Form.Control
                    type="number"
                    disabled
                    placeholder="Release"
                  {...register(`slots.${index}.release`)}
                 />
                    </div>
                  )}
                />
              </Col>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.shiftName`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                       Shift Name
                      </label>
                      <Form.Control
                    type="text"
                    disabled
                    placeholder="Shift Name"
                  {...register(`slots.${index}.shiftName`)}
                 />
                    </div>
                  )}
                />
              </Col>
             
            </Row>
          
            <Row>
             
            </Row>
          </>
        ))}
        <Button
        style={{ float: "right" }}
        variant="success"
        className="waves-effect waves-light"
        type="submit"
      >
        <i className="mdi mdi-plus-circle me-1"></i> Save Slot Details
      </Button>
          </form>
        </Card.Body>
      </Card>
      
    </>
  );
};

export default EditAttendanceDetails;
