// constants
import { ChatActionTypes } from "./constants";

export interface ChatActionType {
  type:
    | ChatActionTypes.API_RESPONSE_SUCCESS
    | ChatActionTypes.API_RESPONSE_ERROR
    | ChatActionTypes.GET_CHATS
    | ChatActionTypes.GET_CHATS_LOADING
    | ChatActionTypes.GET_MESSAGES
    | ChatActionTypes.GET_MESSAGES_LOADING
    | ChatActionTypes.RESET;
  payload: {} | string;
}


// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: any | {}
): ChatActionType => ({
  type: ChatActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): ChatActionType => ({
  type: ChatActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getChats = (
): ChatActionType => ({
  
  type: ChatActionTypes.GET_CHATS,
  payload: {},
});

export const getChatsLoading = (
): ChatActionType => ({
  type: ChatActionTypes.GET_CHATS_LOADING,
  payload: {},
});

export const getMessages = (id:any): ChatActionType => ({
  type: ChatActionTypes.GET_MESSAGES,
  payload: id,
});

export const getMessagesLoading = (): ChatActionType => ({
    type: ChatActionTypes.GET_MESSAGES_LOADING,
    payload: {},
  });


