export enum SOAActionTypes {
  API_SOA_RESPONSE_SUCCESS = "@@soa/API_SOA_RESPONSE_SUCCESS",
  API_SOA_RESPONSE_ERROR = "@@soa/API_SOA_RESPONSE_ERROR",

  // loading
  LIST_SOA_LOADING = "@@soa/LIST_SOA_LOADING",
  DOWNLOAD_SOA_LOADING = "@@soa/DOWNLOAD_SOA_LOADING",
  SEND_SOA_LOADING = "@@soa/SEND_SOA_LOADING",
  REMOVE_SOA_BY_ID_LOADING = "@@soa/REMOVE_SOA_BY_ID_LOADING",
  
// ACTIONS
  LIST_SOA = "@@soa/LIST_SOA",
  DOWNLOAD_SOA = "@@soa/DOWNLOAD_SOA",
  SEND_SOA = "@@soa/SEND_SOA",
  REMOVE_SOA_BY_ID = "@@soa/REMOVE_SOA_BY_ID",
  
  
  RESET_SOA = "@@soa/RESET_SOA",
}
