// constants
import { GroupSupportActionTypes } from "./constants";

export interface GroupSupportActionType {
  type:
    | GroupSupportActionTypes.SUPPORT_API_RESPONSE_SUCCESS
    | GroupSupportActionTypes.SUPPORT_API_RESPONSE_ERROR
    | GroupSupportActionTypes.LIST_SUPPORTS
    | GroupSupportActionTypes.CREATE_SUPPORTS
    | GroupSupportActionTypes.EDIT_SUPPORTS
    | GroupSupportActionTypes.DELETE_SUPPORTS
    | GroupSupportActionTypes.RESET_SUPPORTS
  payload: {} | string;
}

interface SupportData {
    id?:String,
    name?:String,
    email?: String,
    contactNo?: String,
    altContactNo?:String,
    password?:String,
    roles?:String,
    isAvailable?: Boolean,
    isDeleted?: Boolean,
    createdAt?: Date,
    updatedAt?:Date,
}

// common success
export const SupportApiResponseSuccess = (
  actionType: string,
  data?: SupportData | {}
): any => ({
  type: GroupSupportActionTypes.SUPPORT_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const SupportApiResponseError = (
  actionType: string,
  error: string
): any => ({
  type: GroupSupportActionTypes.SUPPORT_API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingSupports = (
  searchParams: Object
): any => ({
  type: GroupSupportActionTypes.LIST_SUPPORTS,
  payload: searchParams,
});


export const addingSupports = (
  payload: SupportData
): any => ({
  type: GroupSupportActionTypes.CREATE_SUPPORTS,
  payload: payload,
});

export const editSupports = (
  payload: SupportData,
  id: any
): any => ({
  type:GroupSupportActionTypes.EDIT_SUPPORTS,
  payload: payload,
  id: id
})

export const updateAdminStatus = (
  payload: any,
): any => ({
  type:GroupSupportActionTypes.UPDATE_ADMIN_STATUS,
  payload: payload,
})

export const editSelectedSupports = (
  payload: any,
): any => ({
  type:GroupSupportActionTypes.EDIT_SUPPORTS_SELECTED,
  payload: payload,
})

export const deleteSupports =(
  id: string
): any => ({
  type:GroupSupportActionTypes.DELETE_SUPPORTS,
  id:id
})

