import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Modal,
  Button,
  TabContainer,
  Tab,
  Nav,
} from "react-bootstrap";
import Table from "../../components/Table";

//dummy data
// import { records as data, expandableRecords } from "../tables/data";
// import FontAwesomeIcons from "../icons/FontAwesomeIcons";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  listingUsers,
  listingUsersLoading,
} from "../../redux/users/actions";
import { RootState } from "../../redux/store";
import { UsersTableColumns } from "./data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommonSearchBar from "../../layouts/SearchBar";
import Pagination from "../../components/customPaginationComponent/customPagination";
import Loader from "../../components/Loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import moment from "moment";

const UsersList = () => {
  const { name, userStatus, limit, page } = useParams();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const deleteParam = query.get("delete");
  const [tab, setTab] = useState<any>("REGISTERED");
  const [responsiveModal, setResponsiveModal] = useState<boolean>(
    deleteParam ? true : false
  );
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip-upcoming" {...props}>
      Upcoming
    </Tooltip>
  );
  const renderTooltipForCompletedSlots = (props: any) => (
    <Tooltip id="button-tooltip-completed" {...props}>
      Completed
    </Tooltip>
  );
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sort: false,
    },
    {
      Header: "Phone Number",
      accessor: "contactNumber",
      sort: false,
    },
    {
      Header: "LOCAL/FOREGINER",
      accessor: "IsForeigner",
      sort: false,
    },
    {
      Header: "Registered Date",
      accessor: "registeredDate",
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      Cell: ({ cell }: any) => (
        <>
          <span onClick={() => handleEditGroup(cell.row.original)}>
            <i
              style={{ cursor: "pointer" }}
              className="bi bi-pencil-square"
            ></i>
          </span>{" "}
          &nbsp;
          <span onClick={() => handleDeleteGroup(cell.row.original)}>
            <i style={{ cursor: "pointer" }} className="bi bi-trash3"></i>
          </span>
        </>
      ),
    },
    {
      Header: "Slots",
      accessor: "slots",
      Cell: ({ cell }: any) => (
        <>
          <span
            onClick={() =>
              navigate(
                `/slots/list/0/50/all/all/all?endDate=${moment().format(
                  "YYYY-MM-DDThh:mm:ss"
                )}&startDate=${moment()
                  .add(365, "days")
                  .format("YYYY-MM-DDThh:mm:ss")}&userId=${cell.row.original.id}&type=upComingSlots`
              )
            }
          >
            <OverlayTrigger
              placement="right"
              // delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <i style={{ cursor: "pointer" }} className="bi bi-calendar"></i>
            </OverlayTrigger>
          </span>
          &nbsp;
          <span
          onClick={() =>
            navigate(
              `/slots/list/0/50/all/all/all?endDate=${moment().format(
                "YYYY-MM-DDThh:mm:ss"
              )}&startDate=${moment()
                .add(365, "days")
                .format("YYYY-MM-DDThh:mm:ss")}&userId=${cell.row.original.id}&type=completedSlots`
            )
          }
          >
            <OverlayTrigger
              placement="right"
              // delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipForCompletedSlots}
            >
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-patch-check"
              ></i>
            </OverlayTrigger>
          </span>
        </>
      ),
    },
    {
      Header: "Wallets History",
      accessor: "wallet",
      sort: false,
      Cell: ({ cell }: any) => (
        <>
          <span>
            <a href={`/users/payment/history/${cell.row.original.id}`}>
              Payment history
            </a>
          </span>{" "}
          &nbsp;
          <span>
            <a href={`/users/payment/redeem/${cell.row.original.id}`}>
              Redeem hisory
            </a>
          </span>
        </>
      ),
    },
    {
      Header: " ",
      accessor: "chat",
      Cell: ({ cell }: any) => (
        <>
          <span
            style={{ fontSize: "large", color: "green", cursor: "pointer" }}
          >
            <i
              onClick={() =>
                window.open(
                  `https://wa.me/${cell.row.original.contactNumber}`,
                  "_blank"
                )
              }
              className={classNames("fab", "fa-whatsapp", "mdi-account")}
            ></i>
          </span>
          <span
            style={{
              fontSize: "large",
              color: "green",
              cursor: "pointer",
              paddingLeft: "5px",
            }}
          >
            <i onClick={() => navigate("/chat")} className="bi bi-chat"></i>
          </span>
        </>
      ),
    },
  ];
  const { usersList, userListingLoading } = useSelector(
    (state: RootState) => state.UsersReducer
  );

  const handleEditGroup = (cell: any) => {
    console.log("the cell in edit", cell);
    navigate(`/users/list/edit/${cell.id}`);
  };

  const handleDeleteGroup = (cell: any) => {
    console.log("tp delete", cell);
    navigate(`?delete=${cell.id}`);
    setResponsiveModal(!responsiveModal);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listingUsersLoading());
    if (name === "all") {
      dispatch(
        listingUsers({
          userStatus: userStatus === "all" ? "REGISTERED" : userStatus,
          page: currentPage,
          limit: limit,
        })
      );
    } else {
      dispatch(
        listingUsers({
          userStatus: userStatus === "all" ? "REGISTERED" : userStatus,
          page: currentPage,
          limit: limit,
          name: name,
        })
      );
    }
  }, [userStatus, deleteParam]);

  useEffect(() => {
    dispatch(listingUsersLoading());
    dispatch(listingUsers({ userStatus: tab }));
  }, [tab]);

  const searchUsers = (e: any) => {
    if (!e.target.value) {
      navigate(
        `/users/list/${page}/${limit}/all/${
          userStatus === "all" ? "REGISTERED" : userStatus
        }`
      );
    } else {
      navigate(
        `/users/list/${page}/${limit}/${e.target.value}/${
          userStatus === "all" ? "REGISTERED" : userStatus
        }`
      );
    }

    dispatch(
      listingUsers({
        userStatus: userStatus === "all" ? "REGISTERED" : userStatus,
        name: e.target.value,
        page: currentPage,
        limit: 10,
      })
    );
  };
  const filterValueChangeByUsersStatus = (name: any, e: any) => {
    console.log("thhe status", e[0]?.value);
    dispatch(listingUsers({ userStatus: e[0]?.value }));
  };

  const searchByName = (keyName: any, e: any) => {
    console.log("thje e", e);
    if (e === "all") {
      navigate(`/users/list/${page}/${limit}/all/all`);
      // dispatch(listingUsers({userStatus: 'all', page: currentPage,limit: 10}))
    }
    if (e !== " ") {
      navigate(
        `/users/list/${page}/${limit}/all/${e === "all" ? "REGISTERED" : e}`
      );
      dispatch(
        listingUsers({
          userStatus: e === "all" ? "REGISTERED" : e,
          page: currentPage,
          limit: 10,
        })
      );
    }
  };
  const toggleResponsiveModal = (values: any) => {
    query.delete("delete");
    query.delete("edit");
    dispatch(
      listingUsers({
        userStatus: userStatus === "all" ? "REGISTERED" : userStatus,
        page: currentPage,
        limit: 10,
      })
    );
    // navigate(`/support`);
    navigate(-1);
    setResponsiveModal(!responsiveModal);
  };
  const deleteEvent = () => {
    dispatch(deleteUser(deleteParam ? deleteParam : undefined));
    query.delete("delete");
    setResponsiveModal(!responsiveModal);
    navigate(-1);
  };
  const [currentPage, setCurrentPage] = useState(page);

  return (
    <div>
      <h1>Users</h1>

      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={tab}
        onSelect={(e: any) => {
          setTab(e);
          // filterValueChangeByUsersStatus("",e[0]?.value)
          dispatch(listingUsers({ userStatus: e }));
          navigate(`/users/list/${page}/${limit}/${name}/${e}`);
        }}
      >
        <Nav as="ul" variant="tabs" className="nav nav-tabs nav-bordered">
          <Nav.Item as="li">
            <Nav.Link eventKey="REGISTERED" className="cursor-pointer">
              REGISTERED
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="PENDING" className="cursor-pointer">
              PENDING
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="VERIFIED" className="cursor-pointer">
              VERIFIED
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="SUSPENDED" className="cursor-pointer">
              SUSPENDED
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey={tab}>
            <CommonSearchBar
              name={name}
              searchTitle={"Search Users..."}
              search={(e: any) => searchUsers(e)}
              searchByName={(filetr: any, e: any) => searchByName(filetr, e)}
              filterValueChanged={filterValueChangeByUsersStatus}
              defaultoptionValue={userStatus}
            />
            <Row>
              <Col>
                <Modal
                  show={responsiveModal}
                  onHide={() => toggleResponsiveModal("test")}
                >
                  <Modal.Body className="p-2">
                    <div>
                      <h3>
                        Are you sure you want to delete the selected user?
                      </h3>
                      <div className="text-end">
                        <Button
                          variant="success"
                          type="submit"
                          className="waves-effect waves-light me-1"
                          onClick={() => deleteEvent()}
                        >
                          Save
                        </Button>
                        <Button
                          variant="danger"
                          className="waves-effect waves-light"
                          onClick={toggleResponsiveModal}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <Card>
                  <Card.Body>
                    {userListingLoading ? (
                      <Loader />
                    ) : (
                      <>
                        <Table
                          columns={columns}
                          data={UsersTableColumns(usersList)}
                          isSortable={true}
                        />
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={usersList?.totalCount}
                          pageSize={limit}
                          onPageChange={(page: any) => {
                            console.log("the page ", page);
                            setCurrentPage(page);
                            navigate(
                              `/users/list/${page}/${limit}/${name}/${
                                userStatus === "all" ? "REGISTERED" : userStatus
                              }`
                            );
                            dispatch(
                              listingUsers({
                                userStatus:
                                  userStatus === "all"
                                    ? "REGISTERED"
                                    : userStatus,
                                page: page,
                                limit: limit,
                              })
                            );
                          }}
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default UsersList;
