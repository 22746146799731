import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller, useForm } from 'react-hook-form';

const AddSlotPageModal = ({filterValueChangeByHotelsOrOutlets,setResponsiveModalForAddSlots,responsiveModalForAddSlots,toggleResponsiveModalForAddSlots,addHotelUsersForAddSlots,hotelsList,outletsList}: any) => {

    const methodsForUser = useForm({
        defaultValues: {
          hotel: " ",
          outlet: "",
          date:""
        },
      });
      const {
        handleSubmit: handleSubmitForUsers,
        register: register2,
        control: controlUsers,
        getValues: getUsersValues,
        reset: resetUsersValues,
        formState: { errors: errors2 },
      } = methodsForUser;
    
    return(
        <>
         <Modal
        show={responsiveModalForAddSlots}
        onHide={toggleResponsiveModalForAddSlots}
      >
        <Modal.Body className="p-2">
          <form
            onSubmit={handleSubmitForUsers(() =>
                addHotelUsersForAddSlots(getUsersValues())
              )}
          >
            <Controller
              control={controlUsers}
              name="hotel"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                  <label htmlFor="typeahead" className="form-label">
                    Hotel
                  </label>
                  <Typeahead
                    {...field}
                    register={register2}
                    id="basic-typeahead-single"
                    multiple={false}
                    onChange={(e: any) => {
                      filterValueChangeByHotelsOrOutlets("hotels", e);
                      localStorage.setItem("hotel", JSON.stringify(e));
                    }}
                    options={hotelsList}
                    placeholder="Hotel"
                  />
                </div>
              )}
            />
            <Controller
              control={controlUsers}
              name="outlet"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                  <label htmlFor="typeahead" className="form-label">
                    Outlets
                  </label>
                  <Typeahead
                    {...field}
                    register={register2}
                    id="basic-typeahead-single"
                    multiple={false}
                    options={(outletsList)}
                    placeholder="Outlet"
                  />
                </div>
              )}
            />
              <Controller
                  control={controlUsers}
                  name={`date`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Date
                      </label>
                      <Form.Control
                    type="date"
                    placeholder="Slot Date"
                  {...register2(`date`)}
                  // onChange={(e) => setEndTimeState(e.target.value)}
                 />
                    </div>
                  )}
                />
            <div className="text-end">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
              >
                Save
              </Button>
              <Button
                variant="danger"
                className="waves-effect waves-light"
                onClick={() => {
                  setResponsiveModalForAddSlots(false);
                }}
              >
                Close
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default AddSlotPageModal;