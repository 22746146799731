
// constants
import { InvoiceActionTypes } from "./constants";

export interface HotelActionType {
  type:
  | InvoiceActionTypes.API_INVOICES_RESPONSE_ERROR
  | InvoiceActionTypes.API_INVOICES_RESPONSE_SUCCESS
  | InvoiceActionTypes.CREATE_DEBIT_CREDIT_NOTE
  | InvoiceActionTypes.CREATE_DEBIT_CREDIT_NOTE_LOADING
  | InvoiceActionTypes.CREATE_NEW_INVOICES
  | InvoiceActionTypes.CREATE_NEW_INVOICES_LOADING
  | InvoiceActionTypes.DOWNLOAD_INOVICE
  | InvoiceActionTypes.DOWNLOAD_INOVICE_LOADING
  | InvoiceActionTypes.GET_INVOICE_BY_ID
  | InvoiceActionTypes.GET_INVOICE_BY_ID_LOADING
  | InvoiceActionTypes.LIST_INVOICE
  | InvoiceActionTypes.LIST_INVOICE_LOADING
  | InvoiceActionTypes.MERGE_INVOICE
  | InvoiceActionTypes.MERGE_INVOICE_LOADING
  | InvoiceActionTypes.RESET_INVOICES
  | InvoiceActionTypes.SEND_INVOICE
  | InvoiceActionTypes.SEND_INVOICE_LOADING
  | InvoiceActionTypes.SPLIT_INVOICE
  | InvoiceActionTypes.SPLIT_INVOICE_LOADING
  | InvoiceActionTypes.UPDATE_INVOICE
  | InvoiceActionTypes.UPDATE_INVOICE_LOADING
  | InvoiceActionTypes.INVOICE_LIST_NAMES
  payload: {} | string;
}

// common success
export const apiHotelsResponseSuccess = (
  actionType: string,
  data: any | {}
): HotelActionType => ({
  type: InvoiceActionTypes.API_INVOICES_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiHotelsResponseError = (
  actionType: string,
  error: string
): HotelActionType => ({
  type: InvoiceActionTypes.API_INVOICES_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingInvoicesLoading = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.LIST_INVOICE_LOADING
})

export const listingInvoices = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.LIST_INVOICE,
  payload: payload,
})

export const listingInvoicesNames = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.INVOICE_LIST_NAMES,
  payload: payload,
})

export const downloadInvoicesLoading = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.DOWNLOAD_INOVICE_LOADING
})

export const DownloadInvoice = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.DOWNLOAD_INOVICE,
  payload: payload,
})

export const sendInvoicesLoading = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.SEND_INVOICE_LOADING
})

export const SendInvoice = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.SEND_INVOICE,
  payload: payload,
})

export const InvoicesByIdLoading = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.GET_INVOICE_BY_ID_LOADING
})

export const InvoiceById = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.GET_INVOICE_BY_ID,
  payload: payload,
})

export const createNewInvoiceLoading = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.CREATE_NEW_INVOICES_LOADING
})

export const createNewInvoice = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.CREATE_NEW_INVOICES,
  payload: payload,
})


export const updateInvoiceLoading = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.UPDATE_INVOICE_LOADING
})

export const updateInvoice = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.UPDATE_INVOICE,
  payload: payload,
})

export const mergeInvoiceLoadingAction = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.MERGE_INVOICE_LOADING
})

export const mergeInvoice = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.MERGE_INVOICE,
  payload: payload,
})

export const splitInvoiceLoading = (
  payload: any,
): any => ({
  type:InvoiceActionTypes.SPLIT_INVOICE_LOADING
})

export const splitInvoice = (
  {payload,
  id}: any
): any => ({
  type:InvoiceActionTypes.SPLIT_INVOICE,
  payload: {payload,id}
})



export const resetSelectedInvoices = (): HotelActionType => ({
  type: InvoiceActionTypes.RESET_INVOICES,
  payload: {},
});
