import { Modal, Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";

const CreateFinance = ({
  responsiveModalForAddWalletRequest,
  toggleResponsiveModalForAddWalletRequest,
  addFinance,
  setResponsiveModalForAddWalletRequest,
  subCategoryList
}: any) => {
  const methodsForUser = useForm({
    defaultValues: {
      subCategory: "",
      transactionDate:"",
      amount:0,
      remarks:""
    
    },
  });
  const {
    handleSubmit: handleSubmitForUsers,
    register: register,
    control: controlUsers,
    getValues: getWalletValues,
    reset: resetWalletValues,
    formState: { errors: errors2 },
  } = methodsForUser;

  return (
    <>
      <Modal
        show={responsiveModalForAddWalletRequest}
        onHide={toggleResponsiveModalForAddWalletRequest}
      >
        <Modal.Body className="p-2">
          <form
            onSubmit={handleSubmitForUsers(() =>
              addFinance(getWalletValues())
            )}
          >
             <Controller
              control={controlUsers}
              name="subCategory"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                  <label htmlFor="typeahead" className="form-label">
                  Sub Category
                  </label>
                  <Typeahead
                    {...field}
                    register={register}
                    id="basic-typeahead-single"
                    multiple={false}
                    // onInputChange={(e: any) => {
                    //   searchByName("user", e)
                    // }}
                    options={subCategoryList}
                    placeholder="Sub Category"
                  />
                </div>
              )}
            />
             <Controller
              control={controlUsers}
              name="transactionDate"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  
                </label>
                  <Form.Control
                      type="date"
                      placeholder="Date"
                      {...register(
                        `transactionDate`
                      )}
                       />
                </div>
              )}
            />
             <Controller
              control={controlUsers}
              name="amount"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  
                </label>
                  <Form.Control
                      type="number"
                      placeholder="Amount"
                      {...register(
                        `amount`
                      )}
                       />
                </div>
              )}
            />
             <Controller
              control={controlUsers}
              name="remarks"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  
                </label>
                  <Form.Control
                      type="text"
                      placeholder="Remarks"
                      {...register(
                        `remarks`
                      )}
                       />
                </div>
              )}
            />
          
            <div className="text-end">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
              >
                Save
              </Button>
              <Button
                variant="danger"
                className="waves-effect waves-light"
                onClick={() => {
                  setResponsiveModalForAddWalletRequest(false);
                }}
              >
                Close
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateFinance;
