export enum HotelActionTypes {
  API_HOTELS_RESPONSE_SUCCESS = "@@hotels/API_HOTELS_RESPONSE_SUCCESS",
  API_HOTELS_RESPONSE_ERROR = "@@hotels/API_HOTELS_RESPONSE_ERROR",

  LIST_HOTELS = "@@hotels/LIST_USER",
  CREATE_HOTELS = "@@hotels/CREATE_HOTELS",
  EDIT_HOTELS = "@@hotels/EDIT_HOTELS",
  DELET_HOTELS = "@@hotels/DELETE_HOTELS",
  EDIT_HOTELSS_SELECTED = "@@supports/EDIT_HOTELSS_SELECTED",



  RESET_HOTELS = "@@hotels/RESET_HOTELS",
}
