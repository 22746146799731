// constants
import { StaffAttendanceActionTypes } from "./constants";

export interface OutletsActionType {
  type:
  | StaffAttendanceActionTypes.API_STAFF_ATTENDANCES_RESPONSE_ERROR
  | StaffAttendanceActionTypes.API_STAFF_ATTENDANCES_RESPONSE_SUCCESS
  | StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES
  | StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES_LOADING
  | StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED
  | StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED_LOADING
  | StaffAttendanceActionTypes.COMPLETE_ATTENDANCE
  | StaffAttendanceActionTypes.COMPLETE_ATTENDANCE_LOADING
  | StaffAttendanceActionTypes.RESET_STAFF_ATTENDANCES
  payload: {} | string;
}



// common succes
export const apiOutletsResponseSucces = (
  actionType: string,
  data: any | {}
): OutletsActionType => ({
  type: StaffAttendanceActionTypes.API_STAFF_ATTENDANCES_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiOutletsResponseError = (
  actionType: string,
  error: string
): OutletsActionType => ({
  type: StaffAttendanceActionTypes.API_STAFF_ATTENDANCES_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingStaffAttendance = (
  searchParams: Object
): OutletsActionType => ({
  type: StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES,
  payload: searchParams,
});

export const ediStafftAttendance = (
  payload: any,
): any => ({
  type:StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED,
  payload: payload,
})


export const completeStaffAttendance =(id:any): any => ({
  type:StaffAttendanceActionTypes.COMPLETE_ATTENDANCE,
  id:id
})


export const resetStaffAttendance = (): OutletsActionType => ({
  type: StaffAttendanceActionTypes.RESET_STAFF_ATTENDANCES,
  payload: {},
});
