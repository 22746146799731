import { Form } from "react-bootstrap";


export const searchFilterForGroups = 
[
    {
        key:"page"
    },
    {
        key: "limit"
    },
    {
        key: "name"
    },
    {
        key: "tab"
    }
]

// table columns
export const UsersTableColumns =  (data: any) => {
const arr:any= [];
 data?.data?.map((details:any) => {
   return  arr.push({
        id: details._id,
        name:details?.hotelName,
        SOAmail: details?.SOAmail
      
    }) 
}) 

return arr;
}

export const OutletTableColumns = (data:any) => {
    console.log('the arr in oulet', data)
    const arr:any= [];
 data?.data?.map((details:any) => {
   return  arr.push({
        id: details._id,
        hotelName:details?.hotelName,
        outletName: details?.outletName
      
    }) 
}) 

return arr;
}

export const OutletUserTableColumns = (data:any) => {
    console.log('the arr in users', data)
    const arr:any= [];
 data?.map((details:any) => {
   return  arr.push({
        id: details._id,
        fullname:details?.fullname,
        username: details?.username,
        outletName: details?.outlet?.outletName
      
    }) 
}) 
console.log('the arr in users return', arr)
return arr;
}

