import { APICore } from "./apiCore";
import axios from "axios";

const api = new APICore();

// account
function listhotels(payload: any) {
  const baseUrl = "/hotel/list";
  console.log('theparams',payload)
  const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
  return api.get(`${baseUrl}?${params}`)
  // return axios.get(`${baseUrl}`, params);
  // if(params.name){
  //   return api.get(`${baseUrl}?page=${params?.page}&limit=${params?.limit}&hotelName=${params.name}`, {});
  // }
  // else{
  //   return api.get(`${baseUrl}?page=${params?.page}&limit=${params?.limit}`, {}); 
  // }
  }
function listhotelsById(id: any) {
  const baseUrl = "/hotel/list";
  return api.get(`${baseUrl}/${id}`,{} );
}
function addHotels(params: any) {
 const baseUrl = "/hotel/add"
  const AUTH_SESSION_KEY = "logged_user";
  // return axios.get(`${baseUrl}`, params);
  const config: any = {
    headers: {
      ...axios.defaults.headers,
      'token': `${localStorage.getItem(AUTH_SESSION_KEY)}`,
      "content-type": "multipart/form-data",
    },
    params,
  };
  // return api.create(`${baseUrl}`, params);
  return axios.post(baseUrl,params,config );
}
function updateHotelsById(details:any){
  const baseUrl = "/hotel/edit";
  return api.updatePatch(`${baseUrl}`,details );
}
function deletehotels(id: string) {
  const baseUrl = `/hotel/delete/${id}`;
  return api.updatePatch(`${baseUrl}`,{});
}



export { listhotels,listhotelsById, deletehotels, updateHotelsById, addHotels};
