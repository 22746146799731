
// constants
import { FinanceActionTypes } from "./constants";

export interface HotelActionType {
  type:
  | FinanceActionTypes.API_FINANCE_RESPONSE_ERROR
  | FinanceActionTypes.API_FINANCE_RESPONSE_SUCCESS
  | FinanceActionTypes.LIST_FINANCE_LOADING
  | FinanceActionTypes.LIST_FINANCE
  | FinanceActionTypes.SAVE_FINANCE
  | FinanceActionTypes.SAVE_FINANCE_LOADING
  | FinanceActionTypes.DOWNLOAD_FINANCE
  | FinanceActionTypes.DOWNLOAD_FINANCE_LOADING
  | FinanceActionTypes.APPROVE_FINANCE_LOADING
  | FinanceActionTypes.APPROVE_FINANCE
  | FinanceActionTypes.RESET_FINANCE;
  payload: {} | string;
}

// common success
export const apiFINANCEResponseSuccess = (
  actionType: string,
  data: any | {}
): HotelActionType => ({
  type: FinanceActionTypes.API_FINANCE_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiFINANCEResponseError = (
  actionType: string,
  error: string
): HotelActionType => ({
  type: FinanceActionTypes.API_FINANCE_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingFINANCELoading = (
): any => ({
  type:FinanceActionTypes.LIST_FINANCE_LOADING
})

export const listingFINANCE = (
  payload: any,
): any => ({
  type:FinanceActionTypes.LIST_FINANCE,
  payload: payload,
})
export const listingsubcategory = (
  payload: any,
): any => ({
  type:FinanceActionTypes.LISTING_SUBCATEGORY,
  payload: payload,
})
export const createSubCategoryLoading = (
): any => ({
  type:FinanceActionTypes.CREATE_SUBCATEGORY_LOADING
})
export const createSubCategory = (payload:any
): any => ({
  type:FinanceActionTypes.CREATE_SUCATEGORY,
  payload: payload,
})

export const listingPendingFINANCELoading = (
): any => ({
  type:FinanceActionTypes.LIST_FINANACE_PENDINNG_LOADING
})

export const listingPendingFINANCE = (
  payload: any,
): any => (
  {
  type:FinanceActionTypes.PENDING_FINANCE,
  payload: payload,
})


export const downloadFINANCELoading = (
  payload: any,
): any => ({
  type:FinanceActionTypes.DOWNLOAD_FINANCE_LOADING
})

export const DownloadFinance = (
  payload: any,
): any => ({
  type:FinanceActionTypes.DOWNLOAD_FINANCE,
  payload: payload,
})

export const SAVEFINANCELoading = (
  payload: any,
): any => ({
  type:FinanceActionTypes.SAVE_FINANCE_LOADING
})

export const SAVEFinance = (
  payload: any,
): any => ({
  type:FinanceActionTypes.SAVE_FINANCE,
  payload: payload,
})

export const ApproveFinance = (
  payload: any,
): any => ({
  type:FinanceActionTypes.APPROVE_FINANCE,
  payload: payload,
})

export const RemoveFINANCEByIdLoading = (
  payload: any,
): any => ({
  type:FinanceActionTypes.DELETE_FINANCE_BY_ID_LOADING
})

export const RemoveFINANCEById = (
  payload: any,
): any => ({
  type:FinanceActionTypes.DELETE_FINANCE_BY_ID,
  payload: payload,
})



export const resetSelectedFINANCE = (): HotelActionType => ({
  type: FinanceActionTypes.RESET_FINANCE,
  payload: {},
});
