import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const CountDashboard = ({title,imageUrl, count, color, textColor}:any) => {
    return(
        <>
        <div style={{background:`${color}`, borderRadius:'10px', height:'140px', width:'200px', color:`${textColor}`, padding:'33px'}}>
        <div style={{fontWeight:'900',paddingBottom:'10px'}}>{title}</div>
        <div style={{display:'flex',justifyContent:'start',alignItems:'center'}}>
            <div>
            <img src={imageUrl} alt='' width="50px" height={"50px"}/>
            </div>
            <div style={{fontWeight:'900'}}>
            {count}
            </div>
        </div>
        </div>
        </>
    )
}

export default CountDashboard;