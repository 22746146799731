import React from 'react';
import { Route, Routes } from 'react-router-dom';
import StaffStatus from './StaffStatusList';
import { searchFilterForGroups } from './data';

import { useSelector, useDispatch } from "react-redux";
// store
import { RootState, AppDispatch } from "../../redux/store";
const Groups = () => {
  
    return(
        <>
        <Routes>
          <Route path="/" element={<StaffStatus />} />
          <Route 
          path={`/:${searchFilterForGroups[0].key}/:${searchFilterForGroups[1].key}/:${searchFilterForGroups[2].key}/:${searchFilterForGroups[3].key}`}
          element={<StaffStatus />} />
        </Routes>
        </>
    )
}

export default Groups;