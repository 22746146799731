import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  editSelectedUsers,
  editSelectedUsersStatus,
  EditUsersLoading,
  EditUserStatusLoading,
  listingCollege,
  listingCountries,
} from "../../redux/users/actions";
// import BasicForms from "../forms/Basic";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { RootState } from "../../redux/store";
import { FormInput } from "../../components";
import { useForm } from "react-hook-form";
import Loader from "../../components/Loader";

const UsersEditForm = () => {
  const { selectedUsers, colleges, editUserLoading, editUserStatusLoading,countriesList } =
    useSelector((state: RootState) => state.UsersReducer);
  const user: any = localStorage.getItem("userLoggedIn");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("the id", selectedUsers, JSON.parse(user));
  useEffect(() => {
    dispatch(EditUsersLoading());
    dispatch(listingCollege({}));
    dispatch(editSelectedUsers(id));
    dispatch(listingCountries())
  }, []);

  const BasicInputElements = () => {
    /*
     * form methods
     */
    const methods = useForm({
      defaultValues: {
        name: selectedUsers.name,
        fullName: selectedUsers.fullName,
        sex: selectedUsers.Sex,
        dob: selectedUsers.DOB,
        contactNumber: selectedUsers.contactNumber,
        email: selectedUsers.email,
        residentStatus: selectedUsers.residentStatus,
        city: selectedUsers.city,
        street: selectedUsers.street,
        zipCode: selectedUsers.zipCode,
        unitNumber: selectedUsers.unitNumber,
        emergencyContact: selectedUsers.emergencyContact,
        emergencyContactRelation: selectedUsers.emergencyContactRelation,
        NRIC: selectedUsers.NRIC,
        PayNow: selectedUsers.PayNow,
        bankName: selectedUsers.bankName,
        bankAccNo: selectedUsers.bankAccNo,
        userStatus: selectedUsers.userStatus,
        college: selectedUsers.FSInstitute?._id,
        unauthorizedReason: selectedUsers?.unauthorizedReason,
        confirmationRequired: selectedUsers?.confirmationRequired,
        otwRequired: selectedUsers?.otwRequired,
        reachedRequired: selectedUsers?.reachedRequired,
        expiryDate: selectedUsers?.expiryDate,
        passExpiry: selectedUsers?.passExpiry,
        race: selectedUsers?.race,
        countries: selectedUsers?.country 
      },
    });
    const {
      handleSubmit,
      register,
      control,
      getValues,
      formState: { errors },
    } = methods;
    const goBack = () => navigate(`/users/list/1/50/all/REGISTERED`);
    const saveUsersDetails = (values: any) => {
      dispatch(EditUserStatusLoading());
      dispatch(
        editSelectedUsersStatus({
          _id: id,
          userStatus: values.userStatus,
          unauthorizedReason: values.unauthorizedReason,
          email: values.email,
          confirmationRequired: values?.confirmationRequired,
          otwRequired: values?.otwRequired,
          reachedRequired: values?.reachedRequired,
        })
      );
      goBack();
    };
    return (
      <>
        <Card>
          <Card.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                fontSize: "x-large",
                cursor: "pointer",
              }}
              onClick={() => goBack()}
            >
              X
            </div>
            <form onSubmit={handleSubmit(() => saveUsersDetails(getValues()))}>
              <h4>User Information</h4>
              <Row>
                <Col md={3}>
                  <FormInput
                    readOnly
                    label="Name"
                    type="text"
                    name="name"
                    containerClass={"mb-3"}
                    register={register}
                    key="name"
                    errors={errors}
                    control={control}
                  />
                </Col>

                <Col md={3}>
                  <FormInput
                    label="Full Name"
                    readOnly
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    containerClass={"mb-3"}
                    register={register}
                    key="fullName"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Date of Birth"
                    type="text"
                    name="dob"
                    readOnly
                    placeholder="Date of Birth"
                    containerClass={"mb-3"}
                    register={register}
                    key="dob"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Email"
                    type="email"
                    name="email"
                    // readOnly= {user.roles === "SUPER ADMIN" ?  false : true}
                    placeholder="Email"
                    containerClass={"mb-3"}
                    register={register}
                    key="email"
                    errors={errors}
                    control={control}
                  />
                </Col>
                </Row>
                <Row>
                <Col md={3}>
                  <FormInput
                    readOnly
                    label="Phone"
                    type="text"
                    name="contactNumber"
                    placeholder="Phone"
                    containerClass={"mb-3"}
                    register={register}
                    key="contactNumber"
                    errors={errors}
                    control={control}
                  />
                    
                </Col>
                <Col md={3}>
                  <FormInput
                    name="sex"
                    label="Sex"
                    readOnly
                    type="select"
                    containerClass="mb-3"
                    className="form-select"
                    register={register}
                    key="sex"
                    errors={errors}
                    control={control}
                  >
                    <option value={"FEMALE"}>Female</option>
                    <option value={"MALE"}>Male</option>
                  </FormInput>
                </Col>
                <Col md={3}>
                  <FormInput
                    label="College"
                    type="select"
                    readOnly
                    name="college"
                    placeholder="College"
                    containerClass={"mb-3"}
                    register={register}
                    key="college"
                    errors={errors}
                    control={control}
                  >
                    {colleges?.map((college: any) => {
                      return (
                        <option value={college?.value}>{college.label}</option>
                      );
                    })}
                  </FormInput>
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Race"
                    type="text"
                    name="race"
                    readOnly
                    placeholder="Race"
                    containerClass={"mb-3"}
                    register={register}
                    key="race"
                    errors={errors}
                    control={control}
                  />
                </Col>
                </Row>
                <Row>
                <Col md={3}>
                  <FormInput
                    label="Residence Status"
                    type="text"
                    readOnly
                    name="residentStatus"
                    placeholder="Residence Status"
                    containerClass={"mb-3"}
                    register={register}
                    key="residentStatus"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="NRIC"
                    readOnly
                    type="text"
                    name="NRIC"
                    placeholder="NRIC"
                    containerClass={"mb-3"}
                    register={register}
                    key="NRIC"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    readOnly
                    label="Pass Expiry"
                    type="text"
                    name="passExpiry"
                    placeholder="Pass Expiry"
                    containerClass={"mb-3"}
                    register={register}
                    key="passExpiry"
                    errors={errors}
                    control={control}
                  />
                </Col>
                </Row>
             
                
                
             
             
            

              <h4>Address Information</h4>
              <Row>
                <Col md={3}>
                <FormInput
                    label="Countries"
                    type="select"
                    readOnly
                    name="countries"
                    placeholder="College"
                    containerClass={"mb-3"}
                    register={register}
                    key="countries"
                    errors={errors}
                    control={control}
                  >
                    {countriesList?.map((college: any) => {
                      return (
                        <option value={college?.value}>{college.label}</option>
                      );
                    })}
                  </FormInput>
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Zip Code"
                    type="text"
                    name="zipCode"
                    readOnly
                    placeholder="Zip Code"
                    containerClass={"mb-3"}
                    register={register}
                    key="zipCode"
                    errors={errors}
                    control={control}
                  />
                  
                </Col>
             
                <Col md={3}>
                  <FormInput
                    label="Street"
                    type="text"
                    name="street"
                    readOnly
                    placeholder="Street"
                    containerClass={"mb-3"}
                    register={register}
                    key="street"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="City"
                    type="text"
                    name="city"
                    readOnly
                    placeholder="City"
                    containerClass={"mb-3"}
                    register={register}
                    key="city"
                    errors={errors}
                    control={control}
                  />
                </Col>
               
              </Row>
              <Row>
              
              
              
                <Col md={3}>
                  <FormInput
                    label="Unit Number"
                    type="text"
                    name="unitNumber"
                    readOnly
                    placeholder="Unit Number"
                    containerClass={"mb-3"}
                    register={register}
                    key="unitNumber"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>

              <h4>Bank Information</h4>
              <Row>
               
                <Col md={3}>
                  <FormInput
                    label="Pay Now"
                    readOnly
                    type="text"
                    name="PayNow"
                    placeholder="Pay Now"
                    containerClass={"mb-3"}
                    register={register}
                    key="PayNow"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    readOnly
                    label="Bank Name"
                    type="text"
                    name="bankName"
                    placeholder="Bank Name"
                    containerClass={"mb-3"}
                    register={register}
                    key="bankName"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    readOnly
                    label="Bank Account"
                    type="text"
                    name="bankAccNo"
                    placeholder="Bank Account"
                    containerClass={"mb-3"}
                    register={register}
                    key="bankAccNo"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
             
              <h4>Emergency Contact</h4>
              <Row>
                <Col md={3}>
                  <FormInput
                    label="Emergency Contact"
                    type="text"
                    readOnly
                    name="emergencyContact"
                    placeholder="Emergency Contact"
                    containerClass={"mb-3"}
                    register={register}
                    key="emergencyContact"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Emergency Contact Name"
                    type="text"
                    readOnly
                    name="emergencyContactName"
                    placeholder="Emergency Contact Name"
                    containerClass={"mb-3"}
                    register={register}
                    key="emergencyContactName"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Emergency Contact Relation"
                    type="text"
                    readOnly
                    name="emergencyContactRelation"
                    placeholder="Emergency Contact Relation"
                    containerClass={"mb-3"}
                    register={register}
                    key="emergencyContactRelation"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className={"mb-3"} style={{ display: "grid" }}>
                    <Form.Label>Profile Image</Form.Label>{" "}
                    <img
                      height="100px"
                      src={`https://jobs.doingbydo.com/api/user/images/${selectedUsers.profilePicture}`}
                      alt=""
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className={"mb-3"} style={{ display: "grid" }}>
                    <Form.Label>Attire Image</Form.Label>{" "}
                    {selectedUsers?.attirePictures?.map((id: any) => (
                      <img
                        height="100px"
                        src={`https://jobs.doingbydo.com/api/user/images/${id}`}
                        alt=""
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className={"mb-3"} style={{ display: "grid" }}>
                    <Form.Label>NRIC Images</Form.Label>{" "}
                    {selectedUsers?.NRICPictures?.map((id: any) => (
                      <img
                        height="100px"
                        src={`https://jobs.doingbydo.com/api/user/images/${id}`}
                        alt=""
                      />
                    ))}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className={"mb-3"} style={{ display: "grid" }}>
                    <Form.Label>Student ID Images</Form.Label>{" "}
                    {selectedUsers?.studentIDPictures?.map((id: any) => (
                      <img
                        height="100px"
                        src={`https://jobs.doingbydo.com/api/user/images/${id}`}
                        alt=""
                      />
                    ))}
                  </Form.Group>
                </Col>
              </Row>
              <h4>Do Jobs</h4>
              <Row>
                <Col md={4}>
                  <FormInput
                    name="userStatus"
                    label="User Status"
                    type="select"
                    containerClass="mb-3"
                    className="form-select"
                    key="userStatus"
                    errors={errors}
                    control={control}
                    register={register}
                  >
                    <option value={"REGISTERED"}>USER REGISTERED</option>
                    <option value={"PENDING"}>
                      DETAILS SUBMITTED/ PENDING VERIFICATION
                    </option>
                    <option value={"VERIFIED"}>VERIFIED ACCOUNT</option>
                    <option value={"SUSPENDED"}>
                      ACCOUNT SUSPEND - NO LOGIN
                    </option>
                  </FormInput>
                </Col>
                <Col md={4}>
                  <FormInput
                    label="Reason for Status Change"
                    type="text"
                    name="unauthorizedReason"
                    placeholder="Reason for Status Change"
                    containerClass={"mb-3"}
                    register={register}
                    key="unauthorizedReason"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={4}>
                  <FormInput
                    label="Verified Date"
                    type="text"
                    name="verifiedDate"
                    placeholder="Verified Date"
                    containerClass={"mb-3"}
                    register={register}
                    disabled
                    key="verifiedDate"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormInput
                    label="Confirmation"
                    type="checkbox"
                    name="confirmationRequired"
                    // placeholder="Reason for Status Change"
                    containerClass={"mb-3"}
                    register={register}
                    key="confirmationRequired"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="On the way"
                    type="checkbox"
                    name="otwRequired"
                    // placeholder="Reason for Status Change"
                    containerClass={"mb-3"}
                    register={register}
                    key="otwRequired"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Reached"
                    type="checkbox"
                    name="reachedRequired"
                    // placeholder="Reason for Status Change"
                    containerClass={"mb-3"}
                    register={register}
                    key="reachedRequired"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", justifyContent: "end" }}>
                  {editUserStatusLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <Button type="submit">Save User Details</Button>
                    </>
                  )}
                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
      </>
    );
  };
  return (
    <>
      <h1>Edit Users</h1>
      <>
        <Card>
          <Card.Body>
            {editUserLoading ? <Loader /> : <BasicInputElements />}
          </Card.Body>
        </Card>
      </>
    </>
  );
};

export default UsersEditForm;
