import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { toast, ToastContainer } from "react-toastify";
 
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={configureStore({})}>
  <React.Fragment>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
    <ToastContainer/>
      <App />
    </BrowserRouter>
  </React.Fragment>
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
