export enum GroupActionTypes {
  API_GROUPS_RESPONSE_SUCCESS = "@@groups/API_GROUPS_RESPONSE_SUCCESS",
  API_GROUPS_RESPONSE_ERROR = "@@groups/API_GROUPS_RESPONSE_ERROR",

  LIST_GROUPS = "@@groups/LIST_USER",
  CREATE_GROUPS = "@@groups/CREATE_GROUPS",
  EDIT_GROUPS = "@@groups/EDIT_GROUPS",
  DELET_GROUPS = "@@groups/DELETE_GROUPS",
  EDIT_GROUPSS_SELECTED = "@@supports/EDIT_GROUPSS_SELECTED",

  LIST_GROUPS_LOADING ="groups/LIST_GROUPS_LOADING",
  CREATE_GROUPS_LOADING ="@@groups/CREATE_GROUPS_LOADING",
  DELETE_GROUP_LOADING="@@groups/DELETE_GROUP_LOADING",
  EDIT_SELECTED_GROUP_LOADING = "@@groups/EDIT_SELECTED_GROUP_LOADING",

  RESET_GROUPS = "@@groups/RESET_GROUPS",
}
