export enum InvoiceActionTypes {
  API_INVOICES_RESPONSE_SUCCESS = "@@invoices/API_INVOICES_RESPONSE_SUCCESS",
  API_INVOICES_RESPONSE_ERROR = "@@invoices/API_INVOICES_RESPONSE_ERROR",

  // loading
  LIST_INVOICE_LOADING = "@@invoices/LIST_INVOICE_LOADING",
  DOWNLOAD_INOVICE_LOADING = "@@invoices/DOWNLOAD_INVOICE_LOADING",
  SEND_INVOICE_LOADING = "@@invoices/SEND_INVOICE_LOADING",
  GET_INVOICE_BY_ID_LOADING = "@@invoices/GET_INVOICES_BY_ID_LOADING",
  CREATE_NEW_INVOICES_LOADING = "@@invoices/CREATE_NEW_INVOICES_LOADING",
  CREATE_DEBIT_CREDIT_NOTE_LOADING = "@@invoces/CREATE_DEBIT_CREDIT_NOTE_LOADING",
  UPDATE_INVOICE_LOADING = "@@invoices/UPDATE_INVOICE_LOADING",
  MERGE_INVOICE_LOADING = "@@innvoices/MERGE_INVOICE_LOADING",
  SPLIT_INVOICE_LOADING ="@@invoices/SPLIT_INNVOICE_LOADING",
// ACTIONS
  LIST_INVOICE = "@@invoices/LIST_INVOICE",
  DOWNLOAD_INOVICE = "@@invoices/DOWNLOAD_INVOICE",
  SEND_INVOICE = "@@invoices/SEND_INVOICE",
  GET_INVOICE_BY_ID = "@@invoices/GET_INVOICES_BY_ID",
  CREATE_NEW_INVOICES = "@@invoices/CREATE_NEW_INVOICES",
  CREATE_DEBIT_CREDIT_NOTE = "@@invoces/CREATE_DEBIT_CREDIT_NOTE",
  UPDATE_INVOICE = "@@invoices/UPDATE_INVOICE",
  MERGE_INVOICE = "@@innvoices/MERGE_INVOICE",
  SPLIT_INVOICE ="@@invoices/SPLIT_INNVOICE",
  INVOICE_LIST_NAMES = "@@invoices/INVOICE_LIST_NAME",
  
  RESET_INVOICES = "@@invoices/RESET_INVOICES",
}
