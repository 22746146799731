// constants
import { listSubcategory } from "../../helpers/api/finance";
import { FinanceActionTypes } from "./constants";

const INIT_STATE = {
  financelist: [],
  financelistLoading: false,
  downloadFinanceLoading: false,
  financeApprovalLoading: false,
  saveFinanceLoading: false,
  removeFinanceLaoding: false,
  financependingLoading: false,
  createFinanceSubCategory:false,
  pendingfinanceList:[],
  listSubcategory:[],
  page: 0,
  limit: 10,
};

interface AuthActionType {
  type:
  | FinanceActionTypes.API_FINANCE_RESPONSE_ERROR
  | FinanceActionTypes.API_FINANCE_RESPONSE_SUCCESS
  | FinanceActionTypes.LIST_FINANCE_LOADING
  | FinanceActionTypes.LIST_FINANCE
  | FinanceActionTypes.LIST_FINANACE_PENDINNG_LOADING
  | FinanceActionTypes.PENDING_FINANCE
  | FinanceActionTypes.SAVE_FINANCE
  | FinanceActionTypes.SAVE_FINANCE_LOADING
  | FinanceActionTypes.DOWNLOAD_FINANCE
  | FinanceActionTypes.DOWNLOAD_FINANCE_LOADING
  | FinanceActionTypes.APPROVE_FINANCE_LOADING
  | FinanceActionTypes.APPROVE_FINANCE
  | FinanceActionTypes.CREATE_SUBCATEGORY_LOADING
  | FinanceActionTypes.CREATE_SUCATEGORY
  | FinanceActionTypes.LISTING_SUBCATEGORY
  | FinanceActionTypes.RESET_FINANCE;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  financelist: any,
  financelistLoading:boolean ,
  financependingLoading: boolean,
  pendingfinanceList:any;
  downloadFinanceLoading: boolean,
  financeApprovalLoading: boolean,
  saveFinanceLoading: boolean,
  createFinanceSubCategory:boolean,
  removeFinanceLaoding: boolean,
  listSubcategory: any,
  page: number,
  limit: number,
}

const FinanceReducer = (
  state: State = INIT_STATE,
  action: AuthActionType
): any => {
  switch (action.type) {
    case FinanceActionTypes.API_FINANCE_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case FinanceActionTypes.CREATE_SUBCATEGORY_LOADING: {
          return {
            ...state,
            financependingLoading: true
          };
        }
        case FinanceActionTypes.CREATE_SUCATEGORY: {
          return {
            ...state,
            financependingLoading: false
          };
        }
        case FinanceActionTypes.LIST_FINANACE_PENDINNG_LOADING: {
          return {
            ...state,
            financependingLoading: true
          };
        }
        case FinanceActionTypes.LISTING_SUBCATEGORY: {
          return {
            ...state,
            listSubcategory: action.payload.data
          };
        }
        case FinanceActionTypes.PENDING_FINANCE: {
          return {
            ...state,
            financependingLoading: false,
            pendingfinanceList: action.payload.data
          };
        }
        case FinanceActionTypes.LIST_FINANCE: {
          return {
            ...state,
            financelistLoading: false,
            financelist: action.payload.data
          };
        }
        case FinanceActionTypes.LIST_FINANCE_LOADING: {
          return {
            ...state,
            financelistLoading: true,
          };
        }
        case FinanceActionTypes.DOWNLOAD_FINANCE_LOADING: {
          return {
            ...state,
            downloadFinanceLoading: true
          };
        }
        case FinanceActionTypes.DOWNLOAD_FINANCE: {
          return {
            ...state,
            downloadFinanceLoading: false
          };
        }
        case FinanceActionTypes.SAVE_FINANCE_LOADING: {
          return {
            ...state,
            saveFinanceLoading: true
          };
        }
        case FinanceActionTypes.SAVE_FINANCE: {
          return {
            ...state,
            saveFinanceLoading: false
          };
        }
        case FinanceActionTypes.DELETE_FINANCE_BY_ID_LOADING: {
          return {
            ...state,
            removeFinanceLaoding: true
          };
        }
        case FinanceActionTypes.DELETE_FINANCE_BY_ID: {
          return {
            ...state,
            removeFinanceLaoding: false,
          };
        }
      
        default:
          return { ...state };
      }

    case FinanceActionTypes.API_FINANCE_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case FinanceActionTypes.CREATE_SUBCATEGORY_LOADING: {
          return {
            ...state,
            financependingLoading: true
          };
        }
        case FinanceActionTypes.CREATE_SUCATEGORY: {
          return {
            ...state,
            financependingLoading: false
          };
        }
        case FinanceActionTypes.LIST_FINANACE_PENDINNG_LOADING: {
          return {
            ...state,
            financependingLoading: true
          };
        }
        case FinanceActionTypes.PENDING_FINANCE: {
          return {
            ...state,
            financependingLoading: false,
            pendingfinanceList: action.payload.error
          };
        }
        case FinanceActionTypes.LIST_FINANCE: {
          return {
            ...state,
            financelistLoading: false,
            financelist: action.payload.error
          };
        }
        case FinanceActionTypes.LIST_FINANCE_LOADING: {
          return {
            ...state,
            financelistLoading: true,
          };
        }
        case FinanceActionTypes.DOWNLOAD_FINANCE_LOADING: {
          return {
            ...state,
            downloadFinanceLoading: true
          };
        }
        case FinanceActionTypes.DOWNLOAD_FINANCE: {
          return {
            ...state,
            downloadFinanceLoading: false
          };
        }
        case FinanceActionTypes.SAVE_FINANCE_LOADING: {
          return {
            ...state,
            saveFinanceLoading: true
          };
        }
        case FinanceActionTypes.SAVE_FINANCE: {
          return {
            ...state,
            saveFinanceLoading: false
          };
        }
        case FinanceActionTypes.DELETE_FINANCE_BY_ID_LOADING: {
          return {
            ...state,
            removeFinanceLaoding: true
          };
        }
        case FinanceActionTypes.DELETE_FINANCE_BY_ID: {
          return {
            ...state,
            removeFinanceLaoding: false,
          };
        }
     
        default:
          return { ...state };
      }

    case FinanceActionTypes.RESET_FINANCE:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default FinanceReducer;
