export enum StaffAttendanceActionTypes {
  API_STAFF_ATTENDANCES_RESPONSE_SUCCESS = "@@staffattendance/API_STAFF_ATTENDANCES_RESPONSE_SUCCESS",
  API_STAFF_ATTENDANCES_RESPONSE_ERROR = "@@staffattendance/API_STAFF_ATTENDANCES_RESPONSE_ERROR",

  LIST_STAFF_ATTENDANCES = "@@staffattendance/LIST_STAFF_ATTENDANCES",
  EDIT_STAFF_ATTENDANCES_SELECTED = "@@staffattendance/EDIT_STAFF_ATTENDANCES_SELECTED",
  COMPLETE_ATTENDANCE = "@@staffattendance/COMPLETE_ATTENDANCE",

  LIST_STAFF_ATTENDANCES_LOADING = "@@staffattendance/LIST_STAFF_ATTENDANCES_LOADING",
  EDIT_STAFF_ATTENDANCES_SELECTED_LOADING = "@@staffattendance/EDIT_STAFF_ATTENDANCES_SELECTED_LOADING",
  COMPLETE_ATTENDANCE_LOADING = "@@staffattendance/COMPLETE_ATTENDANCE_LOADING",

  RESET_STAFF_ATTENDANCES = "@@staffattendance/RESET_STAFF_ATTENDANCES",
}
