import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Form, Row } from "react-bootstrap";
import { RootState } from "../../redux/store";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import { ListPositions } from "../../redux/slots/actions";
import { listingUsers } from "../../redux/users/actions";
import { listingGroups } from "../../redux/actions";
import Select from "react-select";

const PushNotifications = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(listingUsers({ userStatus: "VERIFIED", page: "1", limit: "0" }));
        dispatch(listingGroups({ page: 0, limit: 10000 }));
      }, []);
      const { usersList } = useSelector((state: RootState) => state.UsersReducer);
      const { groupsList, grouploading } = useSelector(
        (state: RootState) => state.GroupsReducer
      );
  const methods = useForm({
    defaultValues: {
        allUsers: false,
        userGroups: [],
      heading: "",
      content: "",
    },
  });


  const GroupsListForSearch = (data: any) => {
    let groups: any = [];
    data?.data?.map((list: any, index: any) => {
    //   if (list.groupType === type) {
        // console.log("the ye0", type);
        return groups.push({
          // id: index,
          value: list._id,
          label: list.groupTitle ? list.groupTitle : " ",
        });
    //   }
    });

    return groups;
  };

  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = methods;
  const sendPushNotification = () => {
    console.log("created a request for manual notifications");
  };
  return (
    <>
      <Card style={{ marginTop: "2%", padding: "5%" }}>
        <h4>Create Manual Push Notifications</h4>
        <form onSubmit={handleSubmit(sendPushNotification)}>
          <Row>
            <Col>
              <Controller
                control={control}
                name={`heading`}
                render={({ field, fieldState }: any) => (
                  <div className="mb-3">
                    <label className="form-label">Heading</label>
                    <Form.Control
                      type="text"
                      placeholder="Heading"
                      {...register(`heading`)}
                    />
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                control={control}
                name={`content`}
                render={({ field, fieldState }: any) => (
                  <div className="mb-3">
                    <label className="form-label">Content</label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter the notification Content here"
                      {...register(`content`)}
                    />
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`allUsers`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3" style={{display:'flex'}}>
                      <label htmlFor="typeahead" className="form-label" style={{paddingRight:'10px'}}>
                      All Users
                      </label>
                      <Form.Check
                    type="checkbox"
                    placeholder="All Users"
                  {...register(`allUsers`)}
                  
                 />
                    </div>
                  )}
                />
              </Col>
              <Col md={6}>
              <Controller
                     control={control}
                     name={`userGroups`}
                     render={({ field, fieldState }: any) => (
                       <div className="mb-3">
                         <label htmlFor="typeahead" className="form-label">
                           Users Group
                         </label>
                         <Select
                           {...field}
                           isMulti
                           options={GroupsListForSearch(groupsList)}
                           value={field.value}
                         />
                       </div>
                     )}
                   />
              </Col>
            </Row>
        </form>
      </Card>
    </>
  );
};

export default PushNotifications;
