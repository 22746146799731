// constants
import { WalletActionTypes } from "./constants";

export interface UserActionType {
  type:
    | WalletActionTypes.API_WALLET_RESPONSE_ERROR
    | WalletActionTypes.API_WALLET_RESPONSE_SUCCESS
    | WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST
    | WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING
    | WalletActionTypes.LIST_REDEEM_LIST
    | WalletActionTypes.LIST_REDEEM_LIST_LOADING
    | WalletActionTypes.LIST_PAID_LIST
    | WalletActionTypes.LIST_PAID_LIST_LOADING
    | WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST
    | WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST_LOADING
    | WalletActionTypes.ACCEPT_PAID_LIST
    | WalletActionTypes.WALLET_APPROVAL
    | WalletActionTypes.RESET_WALLET;
  payload: {} | string;
}

// common success
export const apiUsersResponseSuccess = (
  actionType: string,
  data: any | {}
): UserActionType => ({
  type: WalletActionTypes.API_WALLET_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiUsersResponseError = (
  actionType: string,
  error: string
): UserActionType => ({
  type: WalletActionTypes.API_WALLET_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const navigateRoute = (navigation: any): any => ({
  type: WalletActionTypes.NAVIGATION,
  payload: navigation,
});
// export const listingSupports = (
//   searchParams: Object
// ): any => ({
//   type: GroupSupportActionTypes.LIST_SUPPORTS,
//   payload: searchParams,
// });

export const lisingWalletConfirmationListLoading = (params: any): any => {
 return ({
    type: WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING,
    payload: params,
  })
}

export const lisingWalletConfirmation = (payload: any): any => ({
  type: WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST,
  payload: payload,
});

export const listRedeemLoading = (): any => ({
  type: WalletActionTypes.LIST_REDEEM_LIST_LOADING,
});

export const listRedeemList = (payload: any): any => ({
  type: WalletActionTypes.LIST_REDEEM_LIST,
  payload: payload,
});

export const listPadiLoading = (): any => ({
  type: WalletActionTypes.LIST_PAID_LIST_LOADING,
});

export const listPaid = (payload: any): any => ({
  type: WalletActionTypes.LIST_PAID_LIST,
  payload: payload,
});

export const createManualWalletRequestLoading = (): any => ({
  type: WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST_LOADING,
});

export const createManualRequest = (payload: any): any => ({
  type: WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST,
  payload: payload,
});
export const AcceptPaidList = (id: any, details: any): any => ({
  type: WalletActionTypes.ACCEPT_PAID_LIST,
  payload: {id, details},
});

export const WalletApproval = (id: any, details: any): any => ({
  type: WalletActionTypes.WALLET_APPROVAL,
  payload: {id, details},
});
