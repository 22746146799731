import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Row,
  Col,

  DropdownButton,
  ButtonGroup,

} from "react-bootstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";

// dummy data
import { ChatUser } from "./data";

// import profilePic from "../../../assets/images/users/user-1.jpg";
// import { getChats, getUsers } from "./ChatApi";
import { useDispatch } from "react-redux";

import { listingUsers } from "../../redux/users/actions";
import { getChats, getChatsLoading } from "../../redux/chat/action";

interface ChatUsersProps {
  onUserSelect: (value: ChatUser) => void;
  socket: any;
  selectedUser: any;
  chats: any;
  allUsers: any;
}

// ChatUsers
const ChatUsers = ({
  onUserSelect,
  allUsers,
  socket,
  selectedUser,
  chats,
}: ChatUsersProps) => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch(getChats());
    dispatch(listingUsers({ userStatus: "VERIFIED", page: 1, limit: 1000 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUsers(chats);
     setGroups(allUsers);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chats])
  // console.log("the users", users);
  /**
   * Search the user
   * @param {*} text
   */
  const search = (text: string) => {
    setUsers(
      text
        ? [...users]?.filter(
            (u: any) =>
              u.displayName!?.toLowerCase()?.indexOf(text?.toLowerCase()) >= 0
          )
        : chats
    );
  };
  const searchUsers = (text: string) => {
    setGroups(
      text
        ? [...groups]?.filter(
            (u: any) =>
              u.name!?.toLowerCase()?.indexOf(text?.toLowerCase()) >= 0
          )
        : allUsers
    );
  };

  /**
   * Activates the user
   * @param {*} user
   */
  const activateUser = (user: ChatUser) => {
    // console.log("here");
    socket.emit("initiate_chat", { userId: user._id });
 
    dispatch(getChats())
 
  };
  const activateUserGroup = (user: any) => {
  
    socket.emit("initiate_chat", { userId: user.roomName });
   
    onUserSelect(user);

  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex align-items-start mb-3">
            {/* <img src={""} className="me-2 rounded-circle" height="42" alt="" /> */}
            <div className="w-100">
              <h5 className="d-flex mt-0 mb-0 font-15">
              <div className="circle" style={{height:"30px",borderRadius:"8%"}}>{JSON.parse(localStorage.getItem("userLoggedIn") || '{}').name[0]}</div>
                <Link to="#" className="text-reset">
                {JSON.parse(localStorage.getItem("userLoggedIn") || '{}').name}
                </Link>
              </h5>
              {/* <p className="mt-1 mb-0 text-muted font-14">
                <small className="mdi mdi-circle text-success"></small> Online
              </p> */}
            </div>
            <Link to="#" className="text-reset font-20">
              {/* <i className="mdi mdi mdi-plus" onClick={AddNewGroup}></i> */}
              <DropdownButton
                as={ButtonGroup}
                key={"end"}
                id={`dropdown-button-drop-end`}
                drop={"end"}
                variant="success"
                title={<i className="mdi mdi mdi-plus"></i>}
              >
                <form
                  className="search-bar mb-3"
                  style={{ width: "350px", paddingTop: "2px" }}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control form-control-light"
                      placeholder="People, groups & messages..."
                      onKeyUp={(e: any) => searchUsers(e.target.value)}
                    />
                    <span className="mdi mdi-magnify"></span>
                  </div>
                </form>
                <Row>
                  <Col>
                    <SimpleBar style={{ height: "375px", width: "100%" }}>
                      {(groups || []).map((user: ChatUser, index: any) => {
                        return (
                          <Link
                            to="#"
                            key={index}
                            className="text-body"
                            onClick={(e: any) => {
                              activateUser(user);
                            }}
                          >
                            <div
                              className={classnames(
                                "d-flex",
                                "align-items-start",
                                "p-2",
                                {
                                  "bg-light": user?.id === selectedUser?.id,
                                }
                              )}
                            >
                              <div className="circle">{user?.name?.[0]}</div>

                              <div>
                                <h5 className="mt-0 mb-0 font-14">
                                  <span className="float-end text-muted fw-normal font-12">
                                    {user.lastMessageOn}
                                  </span>
                                  {user.name}
                                </h5>
                                <p className="mt-1 mb-0 text-muted font-14">
                                  <span className="w-25 float-end text-end">
                                    {user.totalUnread !== 0 && (
                                      <span className="badge badge-soft-danger">
                                        {user.totalUnread}
                                      </span>
                                    )}
                                  </span>
                                  <span className="w-75 text-dark ">
                                    {user.lastMessage}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </SimpleBar>
                  </Col>
                </Row>
              </DropdownButton>
            </Link>
          </div>

          <form className="search-bar mb-3">
            <div className="position-relative">
              <input
                type="text"
                className="form-control form-control-light"
                placeholder="People, groups & messages..."
                onKeyUp={(e: any) => search(e.target.value)}
              />
              <span className="mdi mdi-magnify"></span>
            </div>
          </form>

          <h6 className="font-13 text-muted text-uppercase">Group Chats</h6>
          <Row>
            <Col>
              <SimpleBar style={{ height: "500px", width: "100%" }}>
                {users?.map((user: any, index: any) => {
                  return (
                    <Link
                      to="#"
                      key={index}
                      className="text-body"
                      onClick={(e: any) => {
                        activateUserGroup(user);
                      }}
                      style={{width:"100%"}}
                    >
                      <div
                        className={classnames(
                          "d-flex",
                          "align-items-start",
                          "p-2",
                          // {
                          //   "bg-light": user?._id === selectedUser?._id,
                          // }
                        )}
                        style={{backgroundColor:(user?._id === selectedUser?._id ? "#b6b8e1" :"#fff")}}
                      >
                        <div className="circle" style={{width:"40px"}}>{user?.displayName[0]}</div>

                        <div 
                        className="w-100" 
                        style={{width: "calc(100% - 40px )"}}>
                          <h5 className="mt-0 mb-0 font-14">
                            <span className="float-end text-muted fw-normal font-12">
                              {user.lastMessageOn}
                            </span>
                            {user.displayName}
                          </h5>
                          <p className="mt-1 mb-0 text-muted font-14">
                            <span className="w-25 float-end text-end">
                              {user.totalUnread !== 0 && (
                                <span className="badge badge-soft-danger">
                                  {user.totalUnread}
                                </span>
                              )}
                            </span>
                            <span className={user.read ? "w-75 font-weight-bold" : "w-75 text-danger font-weight-bold"}
                    style={{
                      display: "inline-block",
                      width: "5ch", // Limits width to 5 characters
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontWeight:(user.read ? "normal" : "bold")
                    }}>{user.lastMessage}</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </SimpleBar>
            </Col>
          </Row>

        </Card.Body>
      </Card>
    </>
  );
};

export default ChatUsers;
