import { APICore } from "./apiCore";


const api = new APICore();

// account
function listsupports(params: any) {
  const baseUrl = "support/listAllAdmin";
  return api.get(`${baseUrl}`, params);
}
function listsupportsById(id: any) {
  const baseUrl = "support/getAdminById";
  return api.get(`${baseUrl}/${id}`,{} );
}
function updateAdminStatus(data: any){
  const baseUrl = "support/adminstatus";
  return api.create(`${baseUrl}`,data );
}

function creatSupports(payload: any) {
  const baseUrl = "support/add/";
  return api.create(`${baseUrl}`, payload);
}

function editSupports(params: any,id: string) {
  const baseUrl = `support/editAdmin/${id}`;
  return api.updatePatch(`${baseUrl}`, params);
}

function deleteSupports(id: string) {
  const baseUrl = `support/deleteAdmin/${id}`;
  return api.updatePatch(`${baseUrl}`,{});
}

export { listsupports,updateAdminStatus, creatSupports, editSupports, deleteSupports , listsupportsById};
