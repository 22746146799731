import { APICore } from "./apiCore";

const api = new APICore();



export function getChats(id: any){
    const baseUrl = "chat/chatRooms"; 
    return api.get(`${baseUrl}`)
}

export function getMessages(id: any){
    const baseUrl = "chat/messages"; 
    return api.get(`${baseUrl}/${id}`)
}