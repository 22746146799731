// constants
import { OutletsActionTypes } from "./constants";

export interface OutletsActionType {
  type:
    | OutletsActionTypes.API_USERS_OUTLETS_RESPONSE_SUCCESS
    | OutletsActionTypes.API_USERS_OUTLETS_RESPONSE_ERROR
    | OutletsActionTypes.LIST_USERS_OUTLETS
    | OutletsActionTypes.CREATE_USERS_OUTLETS
    | OutletsActionTypes.EDIT_USERS_OUTLETS
    | OutletsActionTypes.DELET_USERS_OUTLETS
    | OutletsActionTypes.RESET_USERS_OUTLETS
    | OutletsActionTypes.SEARCH_USERS_OUTLETS_BY_HOTELS
  payload: {} | string;
}

interface OutletsData {
  type: string;
  group_name: string;
  users: Array<[]>;
  hotel?: string;
  outlet?: string;
}

// common succes
export const apiOutletsUsersResponseSucces = (
  actionType: string,
  data: OutletsData | {}
): OutletsActionType => ({
  type: OutletsActionTypes.API_USERS_OUTLETS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiOutletsUsersResponseError = (
  actionType: string,
  error: string
): any => ({
  type: OutletsActionTypes.API_USERS_OUTLETS_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingOutletsUsers = (
  searchParams: Object
): any => ({
  type: OutletsActionTypes.LIST_USERS_OUTLETS,
  payload: searchParams,
});

export const addOutletsUsers = (
  searchParams: any
): any => ({
  type: OutletsActionTypes.CREATE_USERS_OUTLETS,
  payload: searchParams,
});

export const listingUsersByOutel = (
  searchParams: Object
): any => ({
  type: OutletsActionTypes.SEARCH_USERS_OUTLETS_BY_HOTELS,
  payload: searchParams,
});
export const editSelectedOutletsUsers = (
  payload: any,
): any => ({
  type:OutletsActionTypes.EDIT_USERS_OUTLETS_SELECTED,
  payload: payload,
})
export const deleteOutletsUsers =(
  id: any
): any => ({
  type:OutletsActionTypes.DELET_USERS_OUTLETS,
  id:id
})

export const resetSelectedOutletsUsers = (): any => ({
  type: OutletsActionTypes.RESET_USERS_OUTLETS,
  payload: {},
});
