// constants
import { StaffStatusActionTypes } from "./constants";

export interface OutletsActionType {
  type:
    | StaffStatusActionTypes.API_STAFF_STATUS_RESPONSE_ERROR
    | StaffStatusActionTypes.API_STAFF_STATUS_RESPONSE_SUCCESS
    | StaffStatusActionTypes.LIST_STAFF_STATUS
    | StaffStatusActionTypes.LIST_STAFF_STATUS_BY_OUTLET
    | StaffStatusActionTypes.RESET_STAFF_STATUS
  payload: {} | string;
}



// common succes
export const apiOutletsResponseSucces = (
  actionType: string,
  data: any | {}
): any => ({
  type: StaffStatusActionTypes.API_STAFF_STATUS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiOutletsResponseError = (
  actionType: string,
  error: string
): any => ({
  type: StaffStatusActionTypes.API_STAFF_STATUS_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingStaffStatusList = (
  searchParams: Object
): any => ({
  type: StaffStatusActionTypes.LIST_STAFF_STATUS,
  payload: searchParams,
});


export const listingStaffStatusListById = (
  payload: any
): any => ({
  type: StaffStatusActionTypes.LIST_STAFF_STATUS_BY_OUTLET,
  payload: payload,
});


export const resetStaffStatusList = (): OutletsActionType => ({
  type: StaffStatusActionTypes.RESET_STAFF_STATUS,
  payload: {},
});
