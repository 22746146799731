export enum GroupSupportActionTypes {
    // list suports
    SUPPORT_API_RESPONSE_SUCCESS = "@@/API_RESPONSE_SUCCESS",
    SUPPORT_API_RESPONSE_ERROR = "@@supports/API_RESPONSE_ERROR",
  
    LIST_SUPPORTS = "@@supports/LIST_USER",
    CREATE_SUPPORTS = "@@supports/CREATE_SUPPORTS",
    EDIT_SUPPORTS = "@@supports/EDIT_SUPPORTS",
    DELETE_SUPPORTS = "@@supports/DELETE_SUPPORTS",
    EDIT_SUPPORTS_SELECTED = "@@supports/EDIT_SUPPORTS_SELECTED",
    UPDATE_ADMIN_STATUS = "@@supports/UPDATE_ADMIN_STATUS",
  
    RESET_SUPPORTS = "@@supports/RESET_SUPPORTS",
  }
  