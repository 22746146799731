import { all, fork, put, takeEvery, call, take } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// constants
import { InvoiceActionTypes } from "./constants";
// actions
import { apiHotelsResponseError, apiHotelsResponseSuccess } from "./actions";
import {
  createNewInvoice,
  downloadInvoices,
  listInvoices,
  lookupofInvoicesNames,
  mergeInvoice,
  sentInvoices,
  splitInvoice,
  updateInvoice,
  getInvoiceById as getInvoiceByIdApi
} from "../../helpers/api/invoice";
import { toast } from "react-toastify";
import { navaigate } from "../users/saga";

function* listinInvoiceLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiHotelsResponseSuccess(InvoiceActionTypes.LIST_INVOICE_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.LIST_INVOICE_LOADING, err)
    );
  }
}
function* ListingInvoices({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(listInvoices, payload);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.LIST_INVOICE,
        response.data.result
      )
    );
  } catch (err: any) {
    yield put(apiHotelsResponseError(InvoiceActionTypes.LIST_INVOICE, err));
  }
}

function* ListingInvoicesName({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(lookupofInvoicesNames, payload);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.INVOICE_LIST_NAMES,
        response.data.result
      )
    );
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.INVOICE_LIST_NAMES, err)
    );
  }
}

function* downloadInvoiceLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiHotelsResponseSuccess(InvoiceActionTypes.DOWNLOAD_INOVICE_LOADING, {})
    );
  } catch (err: any) {
    yield put(apiHotelsResponseError(InvoiceActionTypes.DOWNLOAD_INOVICE, err));
  }
}

function* downloadInvoice({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(downloadInvoices, payload);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.DOWNLOAD_INOVICE,
        response.data.result
      )
    );
    toast.success(response.data.info.msg);
  } catch (err: any) {
    console.log("the error", err)
    toast.error(err.response.data.error);
    yield put(apiHotelsResponseError(InvoiceActionTypes.DOWNLOAD_INOVICE, err));
  }
}

function* sendingInvoiceLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiHotelsResponseSuccess(InvoiceActionTypes.SEND_INVOICE_LOADING, {})
    );
    
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.SEND_INVOICE_LOADING, err)
    );
  }
}

function* sendInvoice({ payload, type }: any): SagaIterator {
  try {
    console.log("the paylaod in sending", payload);
    const response = yield call(sentInvoices, payload);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.SEND_INVOICE,
        response.data.result
      )
    );
    toast.success(response.data.info.msg);
 
  } catch (err: any) {
    toast.error(err.response.data.error);
   
    yield put(apiHotelsResponseError(InvoiceActionTypes.SEND_INVOICE, err));
  }
}

function* getInvoiceByIdLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiHotelsResponseSuccess(InvoiceActionTypes.GET_INVOICE_BY_ID_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.GET_INVOICE_BY_ID_LOADING, err)
    );
  }
}

function* getInvoiceById({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(getInvoiceByIdApi, payload);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.GET_INVOICE_BY_ID,
        response.data.result
      )
    );
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.GET_INVOICE_BY_ID, err)
    );
  }
}

function* createNewInoviceLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.CREATE_NEW_INVOICES_LOADING,
        {}
      )
    );
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(
        InvoiceActionTypes.CREATE_NEW_INVOICES_LOADING,
        err
      )
    );
  }
}

function* createNewInvoices({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(createNewInvoice, payload);
    toast.success(response.data.info.msg);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.CREATE_NEW_INVOICES,
        response.data.result
      )
    );
  } catch (err: any) {
    toast.error(err.response.data.error);
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.CREATE_NEW_INVOICES, err)
    );
  }
}

function* updateInoviceLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiHotelsResponseSuccess(InvoiceActionTypes.UPDATE_INVOICE_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.UPDATE_INVOICE_LOADING, err)
    );
  }
}

function* updateInvoiceFun({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(updateInvoice, payload);
    toast.success(response.data.info.msg);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.UPDATE_INVOICE,
        response.data.result
      )
    );
  } catch (err: any) {
    toast.error(err.response.data.error);
    yield put(apiHotelsResponseError(InvoiceActionTypes.UPDATE_INVOICE, err));
  }
}

function* mergeInoviceLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiHotelsResponseSuccess(InvoiceActionTypes.MERGE_INVOICE_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.MERGE_INVOICE_LOADING, err)
    );
  }
}
function* mergeInvoicesaga({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(mergeInvoice, payload);
    toast.success(response.data.info.msg);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.MERGE_INVOICE,
        response.data.result
      )
    );
  } catch (err: any) {
    console.log("thhe err", err)
    toast.error(err.response.data.error);
    yield put(apiHotelsResponseError(InvoiceActionTypes.MERGE_INVOICE, err));
  }
}
function* splitInoviceLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiHotelsResponseSuccess(InvoiceActionTypes.SPLIT_INVOICE_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiHotelsResponseError(InvoiceActionTypes.SPLIT_INVOICE_LOADING, err)
    );
  }
}

function* splitInvoicesaga({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(splitInvoice,payload.payload,payload.id);
    toast.success(response.data.info.msg);
    yield put(
      apiHotelsResponseSuccess(
        InvoiceActionTypes.SPLIT_INVOICE,
        response.data.result
      )
    );
  } catch (err: any) {
    toast.error(err.response.data.error);
    yield put(apiHotelsResponseError(InvoiceActionTypes.SPLIT_INVOICE, err));
  }
}

// watch
export function* watchListingInvoiceLoading() {
  yield takeEvery(
    InvoiceActionTypes.LIST_INVOICE_LOADING,
    listinInvoiceLoading
  );
}
export function* watchListingInvoices() {
  yield takeEvery(InvoiceActionTypes.LIST_INVOICE, ListingInvoices);
}
export function* watchListingInvoicesName() {
  yield takeEvery(InvoiceActionTypes.INVOICE_LIST_NAMES, ListingInvoicesName);
}
export function* watchDownloadingInvoices() {
  yield takeEvery(
    InvoiceActionTypes.DOWNLOAD_INOVICE_LOADING,
    downloadInvoiceLoading
  );
}
export function* watchDownloadInvoices() {
  yield takeEvery(InvoiceActionTypes.DOWNLOAD_INOVICE, downloadInvoice);
}
export function* watchSendingInvoices() {
  yield takeEvery(
    InvoiceActionTypes.SEND_INVOICE_LOADING,
    sendingInvoiceLoading
  );
}
export function* watchSendInvoices() {
  yield takeEvery(InvoiceActionTypes.SEND_INVOICE, sendInvoice);
}

export function* watchGetInvoicesByIdLoading() {
  yield takeEvery(
    InvoiceActionTypes.GET_INVOICE_BY_ID_LOADING,
    getInvoiceByIdLoading
  );
}

export function* watchInvoicesById() {
  yield takeEvery(InvoiceActionTypes.GET_INVOICE_BY_ID, getInvoiceById);
}

export function* watchCreateNNewInvoiceLoading() {
  yield takeEvery(
    InvoiceActionTypes.CREATE_NEW_INVOICES_LOADING,
    createNewInoviceLoading
  );
}

export function* watchCreateNewInovice() {
  yield takeEvery(InvoiceActionTypes.CREATE_NEW_INVOICES, createNewInvoices);
}

export function* watchUpdateInoviceLoading() {
  yield takeEvery(
    InvoiceActionTypes.UPDATE_INVOICE_LOADING,
    updateInoviceLoading
  );
}
export function* watchUpdateInovice() {
  yield takeEvery(InvoiceActionTypes.UPDATE_INVOICE, updateInvoiceFun);
}
export function* watchMergeInoviceLoading() {
  yield takeEvery(
    InvoiceActionTypes.MERGE_INVOICE_LOADING,
    mergeInoviceLoading
  );
}
export function* watchMergeInovice() {
  yield takeEvery(InvoiceActionTypes.MERGE_INVOICE, mergeInvoicesaga);
}
export function* watchSplitInoviceLoading() {
  yield takeEvery(
    InvoiceActionTypes.SPLIT_INVOICE_LOADING,
    splitInoviceLoading
  );
}
export function* watchSplitInovice() {
  yield takeEvery(InvoiceActionTypes.SPLIT_INVOICE, splitInvoicesaga);
}

function* invoiceSaga() {
  yield all([
    fork(watchListingInvoices),
    fork(watchListingInvoiceLoading),
    fork(watchListingInvoicesName),
    fork(watchDownloadingInvoices),
    fork(watchDownloadInvoices),
    fork(watchSendingInvoices),
    fork(watchSendInvoices),
    fork(watchGetInvoicesByIdLoading),
    fork(watchInvoicesById),
    fork(watchCreateNNewInvoiceLoading),
    fork(watchCreateNewInovice),
    fork(watchUpdateInoviceLoading),
    fork(watchUpdateInovice),
    fork(watchMergeInoviceLoading),
    fork(watchMergeInovice),
    fork(watchSplitInoviceLoading),
    fork(watchSplitInovice),
  ]);
}

export default invoiceSaga;
