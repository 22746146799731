import { APICore } from "./apiCore";
import { toast } from "react-toastify";
import axios from "axios";

const api = new APICore();

// account
export function listSOA(payload:any) {
  const baseUrl = "invoice/soa/list";
  // const params =  Object.keys(payload)
  //           .map((key) => key + "=" + payload[key])
  //           .join("&")
if(payload?.hotel)
    return api.get(`${baseUrl}/${payload.hotel}?page=${payload.page}&limit=${payload.limit}`)
}


export function downloadSOA(payload: any){
  const baseUrl = "invoice/soa/downloadInvoice";
  axios.get(`${baseUrl}/${payload}`, {
    responseType: 'blob'
 })
 .then((response) => {
    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    //  const pdfWindow = window.open();
    //  if(pdfWindow){
    //     pdfWindow.location.href = fileURL;  
    //  }
    const pdfWindow =  window.open(fileURL, '_blank','noopener');
    
     toast.success("Downloaded Successfully"); 
  })
  .catch((error) => {
    console.log(error);
  
    toast.error(error.response.statusText);
  }); 

  // return api.get(`${baseUrl}/${payload}`)
}

export function sentSOA(payload: any){
  const baseUrl = "invoice/soa/sendInvoice"; 
  return api.get(`${baseUrl}/${payload}`)
}

export function removeSOAById(payload: any){
  const baseUrl = "invoice/soa/removeInvoice"; 
  return api.updatePatch(`${baseUrl}/${payload}`,{})
}

