import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";


// helpers
import {
  deleteoutletsUser as deleteoutletsUserApi,
  listUsersForOutlets as listUsersForOutletsApi,
  listoutletUserByOutleId as listoutletUserByOutleIdApi,
  editUserForOutlet as editUserForOutletApi,
  addUsers as addUsersApi
  // logout as logoutApi,
  // signup as signupApi,
  // forgotPassword as forgotPasswordApi,
} from "../../helpers/api/outletUsers";


// constants
import { OutletsActionTypes } from "./constants";
// actions
import { apiOutletsUsersResponseError, apiOutletsUsersResponseSucces } from "./actions";


function* listOutletsUsers({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(listUsersForOutletsApi, searchParams);
 
    yield put(apiOutletsUsersResponseSucces(OutletsActionTypes.LIST_USERS_OUTLETS, response.data.result.data))
  }
  catch(err: any){
    yield put(apiOutletsUsersResponseError(OutletsActionTypes.LIST_USERS_OUTLETS, err))
  }
}

function* createOutletUsers({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(addUsersApi, searchParams);
 
    yield put(apiOutletsUsersResponseSucces(OutletsActionTypes.CREATE_USERS_OUTLETS, response.data.result.data))
  }
  catch(err: any){
    yield put(apiOutletsUsersResponseError(OutletsActionTypes.CREATE_USERS_OUTLETS, err))
  }
}
function* getOutletUsersByOutletId({
  payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response', searchParams)
    const response = yield call(listoutletUserByOutleIdApi, searchParams);
 
    yield put(apiOutletsUsersResponseSucces(OutletsActionTypes.SEARCH_USERS_OUTLETS_BY_HOTELS, response.data.result.data))
  }
  catch(err: any){
    yield put(apiOutletsUsersResponseError(OutletsActionTypes.SEARCH_USERS_OUTLETS_BY_HOTELS, err))
  }
}

function* editOutletUsers({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    console.log('theresp, response', details.id)
    const repsonse = yield call(editUserForOutletApi, details.id);
    
    yield put(apiOutletsUsersResponseSucces(OutletsActionTypes.EDIT_USERS_OUTLETS_SELECTED, repsonse.data.result[0]))
  }
  catch(err:any){
    yield put(apiOutletsUsersResponseError(OutletsActionTypes.EDIT_USERS_OUTLETS_SELECTED, err));
 
  }
}
function* deleteOutletUsers({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log("the payel", id)
    const repsonse = yield call(deleteoutletsUserApi, id);
    console.log('theresp, response', repsonse)
    if(repsonse) yield call(listOutletsUsers,{page:0,limit:5})
    yield put(apiOutletsUsersResponseSucces(OutletsActionTypes.DELET_USERS_OUTLETS,{}))
  }
  catch(err:any){
    yield put(apiOutletsUsersResponseError(OutletsActionTypes.DELET_USERS_OUTLETS, err));
 
  }
}
function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    
    yield put(apiOutletsUsersResponseSucces(OutletsActionTypes.RESET_USERS_OUTLETS,{}))
  }
  catch(err:any){
    yield put(apiOutletsUsersResponseError(OutletsActionTypes.RESET_USERS_OUTLETS, err));
 
  }
}


export function* watchListingOutletsUsers() {
  yield takeEvery(OutletsActionTypes.LIST_USERS_OUTLETS, listOutletsUsers);
}
export function* watchListEditOutletUsers(): any {
  yield takeEvery(OutletsActionTypes.EDIT_USERS_OUTLETS_SELECTED, editOutletUsers);
}
export function* watchDeleteOutletUsers(): any {
  yield takeEvery(OutletsActionTypes.DELET_USERS_OUTLETS, deleteOutletUsers);
}

export function* watchResetSelectedOutletUsers(): any {
  yield takeEvery(OutletsActionTypes.RESET_USERS_OUTLETS, resetValues);
}

export function* watchOutletUsersByOutlet(): any{
  yield takeEvery(OutletsActionTypes.SEARCH_USERS_OUTLETS_BY_HOTELS, getOutletUsersByOutletId);
}

export function* watchCreateOutletsUsers(): any{
  yield takeEvery(OutletsActionTypes.CREATE_USERS_OUTLETS, createOutletUsers);
}


function* outletUsersSaga() {
  yield all([
    fork(watchListingOutletsUsers),
    fork(watchListEditOutletUsers),
    fork(watchDeleteOutletUsers),
    fork(watchResetSelectedOutletUsers),
    fork(watchOutletUsersByOutlet),
    fork(watchCreateOutletsUsers)
  ]);
}

export default outletUsersSaga;
