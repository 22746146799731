import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WalletPaidandRedeemListPage from './WalletPaidList';


const WalletListPages = () => {

    return(
        <>
        <Routes>
          <Route path="/" element={<WalletPaidandRedeemListPage />} />
          <Route 
          path={`/:page/:limit/:tab`}
          element={<WalletPaidandRedeemListPage />} />
        </Routes>
        </>
    )
}

export default WalletListPages;