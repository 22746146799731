import moment from "moment";
import Table from "../../components/Table";
import Pagination from "../../components/customPaginationComponent/customPagination";
import { Card } from "react-bootstrap";

const PaidListPage = ({
  paidList,
  WalletPadiListTableColumns,
  currentPage,
  setCurrentPage,
  navigate,
  limit,
  state,
  tab,
  dispatch,
  listPaid
}: any) => {

// paid list columsn
  const columns = [
    {
      Header: "User",
      accessor: "userName",
      sort:false,
    },
    {
      Header: "Bank Name",
      accessor: "bankName",
      sort:false,
    },
    {
      Header: "Bank Account No",
      accessor: "bankAccNo",
      sort: false,
    },
    {
      Header: "Contact Number",
      accessor: "contactNumber",
      sort:false,
    },
    {
      Header: "Wallet Balance",
      accessor: "walletBalance",
      sort: false,
    },
    {
      Header: "Amount",
      accessor: "amount",
      sort: false,
    },
    {
      Header: "Approved",
      accessor: "isApproved",
      sort: false,
    },
  ];
  return (
    <>
    <Card>
      <Card.Body>
      <Table
        columns={columns}
        data={WalletPadiListTableColumns(paidList)}
        isSortable={true}
        pagination={false}
      />
      </Card.Body>
    </Card>
      
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={paidList?.totalCount}
        pageSize={10}
        onPageChange={(page: any) => {
          setCurrentPage(page);
          navigate(`/wallet-request/${page}/${limit}/${tab}`);
          dispatch(
            listPaid({
              page: currentPage,
              limit: limit,
              startDate:moment(state.startDate).format("YYYY-MM-DDThh:mm:ss"),
              endDate:moment(state.endDate).format("YYYY-MM-DDThh:mm:ss"),
            })
          );
        }}
      />
    </>
  );
};

export default PaidListPage;
