
// constants
import { SOAActionTypes } from "./constants";

export interface HotelActionType {
  type:
  | SOAActionTypes.API_SOA_RESPONSE_ERROR
  | SOAActionTypes.API_SOA_RESPONSE_SUCCESS
  | SOAActionTypes.LIST_SOA_LOADING
  | SOAActionTypes.LIST_SOA
  | SOAActionTypes.SEND_SOA
  | SOAActionTypes.SEND_SOA_LOADING
  | SOAActionTypes.DOWNLOAD_SOA
  | SOAActionTypes.DOWNLOAD_SOA_LOADING
  | SOAActionTypes.REMOVE_SOA_BY_ID
  | SOAActionTypes.REMOVE_SOA_BY_ID_LOADING
  | SOAActionTypes.RESET_SOA;
  payload: {} | string;
}

// common success
export const apiSOAResponseSuccess = (
  actionType: string,
  data: any | {}
): HotelActionType => ({
  type: SOAActionTypes.API_SOA_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiSOAResponseError = (
  actionType: string,
  error: string
): HotelActionType => ({
  type: SOAActionTypes.API_SOA_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingSOALoading = (
  payload: any,
): any => ({
  type:SOAActionTypes.LIST_SOA_LOADING
})

export const listingSOA = (
  payload: any,
): any => ({
  type:SOAActionTypes.LIST_SOA,
  payload: payload,
})


export const downloadSOALoading = (
  payload: any,
): any => ({
  type:SOAActionTypes.DOWNLOAD_SOA_LOADING
})

export const DownloadInvoice = (
  payload: any,
): any => ({
  type:SOAActionTypes.DOWNLOAD_SOA,
  payload: payload,
})

export const sendSOALoading = (
  payload: any,
): any => ({
  type:SOAActionTypes.SEND_SOA_LOADING
})

export const SendInvoice = (
  payload: any,
): any => ({
  type:SOAActionTypes.SEND_SOA,
  payload: payload,
})

export const RemoveSOAByIdLoading = (
  payload: any,
): any => ({
  type:SOAActionTypes.REMOVE_SOA_BY_ID
})

export const RemoveSOAById = (
  payload: any,
): any => ({
  type:SOAActionTypes.REMOVE_SOA_BY_ID_LOADING,
  payload: payload,
})



export const resetSelectedSOA = (): HotelActionType => ({
  type: SOAActionTypes.RESET_SOA,
  payload: {},
});
