import moment from "moment";
import { Form } from "react-bootstrap";
export const SOAListTableColumns = (
  data: any,
) => {
  const arr: any = [];
  data?.map((details: any) => {
    arr.push({
      id: details._id,
      invoiceUID: details.invoiceUID,
      amount: details.creditedAmount,
      charges: details.charges,
      invoiceDate: moment(details.invoiceDate).format('YYYY/MM/DD'),
      unsetteledBalance: details.unsetteledBalance,
      receivedDate: details.unsetteledBalance,
    });
  });

  return arr;
};