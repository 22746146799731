import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { completeStaffAttendance, ediStafftAttendance, listingStaffAttendance } from "../../redux/staffAttendance/actions";
import { RootState } from "../../redux/store";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useFieldArray, useForm, Controller } from "react-hook-form";
// import FormInput from "../../components/FormInput";
import check from '../../assets/images/check.png';
import CommonSearchBar from "../../layouts/SearchBar";
import { listingHotels } from "../../redux/hotels/actions";
import { listingOutlets } from "../../redux/outlets/actions";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import { listingSlots } from "../../redux/slots/actions";
import { DateRangePicker } from "react-date-range";

import Pagination from '../../components/customPaginationComponent/customPagination';

const StaffAttendanceList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {name , hotel, outlet} = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  let currentDate = new Date();

  const [state, setState] = useState<any>([
    {
      startDate: new Date(currentDate).setFullYear(currentDate.getFullYear() - 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const {hotelsList}  = useSelector((state:RootState) => state.HotelsReducer)
const {outletsList}  = useSelector((state:RootState) => state.OutletsReducer)

useEffect(() => {
  dispatch(listingHotels({ page:0,limit:10}))
},[])
useEffect(() => {
  navigate(`/staffAttendance/${page}/${limit}/all/all?startDate=${moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss")}&endDate=${moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss")}`);
  dispatch(listingStaffAttendance({page:currentPage,limit: 10, startDate: moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss"), endDate: moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss")}))
},[state])


  const { staffAttendanceList, page, limit } = useSelector(
    (state: RootState) => state.StaffAttendanceReducer
  );

  const methods = useForm({
    defaultValues: {
      staffAttendance: [
        {
          _id: "",
          startTime: "",
          endTime: "",
          breakTime: "",
          hourlyPay: "",
          Notes: "",
          deduction: "",
          status: "",
          extraPay: "",
        },
      ],
    },
  });
  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "staffAttendance",
  });

  useEffect(() => {
    const valueArr: any = [];
    staffAttendanceList?.data?.map((detils: any) => {
      return valueArr.push({
        _id: detils?._id,
        startTime: detils?.startTime,
        endTime: detils?.endTime,
        breakTime: detils?.breakTime,
        hourlyPay: detils?.hourlyPay,
        extraPay: detils?.extraPay,
        Notes: detils?.Notes,
        deduction: detils?.deduction,
        status: detils?.status,
      });
    });
    reset({
      staffAttendance: valueArr,
    });
  }, [staffAttendanceList]);
 
  const editAttendanceSubmit = () => {
    console.log("the val", getValues());
    dispatch(ediStafftAttendance(getValues().staffAttendance))
  };
  const completeStaffAttendanceById = (id: any) => {
    dispatch(completeStaffAttendance(id))
    dispatch(
      listingStaffAttendance({
        page: 0,
        limit: 10,
        startDate: "2024-07-05T18:30:00.000Z",
        endDate: "2024-08-25T18:30:00.000Z",
      })
    );
  }
  const searchStaffAttendance = (e: any) => {
    if(!e.target.value){
      navigate(`/staffAttendance/${page}/${limit}/all/all/all?startDate=${moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss")}&endDate=${moment(state[0].endDate).add(7, 'days').format("YYYY-MM-DDThh:mm:ss")}`);
    }
    else{
      navigate(`/staffAttendance/${page}/${limit}/all/${e.target.value}/all?startDate=${moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss")}&endDate=${moment(state[0].endDate).add(7, 'days').format("YYYY-MM-DDThh:mm:ss")}`);
    }
    dispatch(
      listingStaffAttendance({
        page: 0,
        limit: 10,
        startDate: "2024-07-05T18:30:00.000Z",
        endDate: "2024-08-25T18:30:00.000Z",
        name: e.target.value
      })
    );
  }
  function CollapsibleRow({ data, defaultIsOpen = false, children }: any) {
    const [isOpen, setIsOpen] = useState(defaultIsOpen);
  

   
    return (
      <>
        <tr>
          <td
            style={{ width: "12.5%" }}
            className="header"
            onClick={() => navigate(`/users/list/edit/${data.userDetails._id}`)}
          >
            {data.userDetails.fullName}
          </td>
          <td
            style={{ width: "12.5%" }}
            className="header"
            onClick={() => setIsOpen(!isOpen)}
          >
            {data.hotelDetails.hotelName}
          </td>
          <td
            style={{ width: "12.5%" }}
            className="header"
            onClick={() => setIsOpen(!isOpen)}
          >
            {data.outletDetails.outletName}
          </td>
          <td
            style={{ width: "12.5%" }}
            className="header"
            onClick={() => setIsOpen(!isOpen)}
          >
            {moment(data.dateObj).format("YYYY/MM/DD")}
          </td>
          <td
            style={{ width: "12.5%" }}
            className="header"
            onClick={() => setIsOpen(!isOpen)}
          >
            {data.status}
          </td>
          <td
            style={{ width: "12.5%" }}
            className="header"
            onClick={() => setIsOpen(!isOpen)}
          >
            {data.totalPay}
          </td>
          <td
            style={{ width: "12.5%" }}
            className="header"
            onClick={() => setIsOpen(!isOpen)}
          >
            {data.finalPay}
          </td>
          <td
            style={{ width: "12.5%" }}
            className="header"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span>
              {" "}
              <i className="bi bi-pencil-square"></i>
            </span>
            {!data.isClosed && <span style={{marginLeft:'10px', cursor: 'pointer'}}>
              <img src={check} className="rounded-circle"
                                    alt=""
                                    width={"20px"}
                                    height={"20px"} 
                                    
                  onClick={() => completeStaffAttendanceById(data._id)}/>
            </span>}
          </td>
        </tr>
        {isOpen && (
          <tr>
            <td colSpan={12}>
              <>
                <Card>
                  {fields.map((field, index) => {
                    if (field._id === data?._id) {
                      return (
                        <>
                          <h4>Staff Attendance Details</h4>
                          <form onSubmit={handleSubmit(editAttendanceSubmit)}>
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name={`staffAttendance.${index}.startTime`}
                                  render={({ field, fieldState }: any) => (
                                    <div className="mb-3">
                                      <label
                                        htmlFor="typeahead"
                                        className="form-label"
                                      >
                                        Start Time
                                      </label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Start Time"
                                        {...register(
                                          `staffAttendance.${index}.startTime`
                                        )}
                                        // onChange={(e) => setStartTimeState(e.target.value)}
                                      />
                                      {/* </Form.Control> */}
                                    </div>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name={`staffAttendance.${index}.endTime`}
                                  render={({ field, fieldState }: any) => (
                                    <div className="mb-3">
                                      <label
                                        htmlFor="typeahead"
                                        className="form-label"
                                      >
                                        End Time
                                      </label>
                                      <Form.Control
                                        type="text"
                                        placeholder="End Time"
                                        {...register(
                                          `staffAttendance.${index}.endTime`
                                        )}
                                        // onChange={(e) => setStartTimeState(e.target.value)}
                                      />
                                      {/* </Form.Control> */}
                                    </div>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name={`staffAttendance.${index}.breakTime`}
                                  render={({ field, fieldState }: any) => (
                                    <div className="mb-3">
                                      <label
                                        htmlFor="typeahead"
                                        className="form-label"
                                      >
                                        Break Time
                                      </label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Break Time"
                                        {...register(
                                          `staffAttendance.${index}.breakTime`
                                        )}
                                        // onChange={(e) => setStartTimeState(e.target.value)}
                                      />
                                      {/* </Form.Control> */}
                                    </div>
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name={`staffAttendance.${index}.hourlyPay`}
                                  render={({ field, fieldState }: any) => (
                                    <div className="mb-3">
                                      <label
                                        htmlFor="typeahead"
                                        className="form-label"
                                      >
                                        Hourly Pay
                                      </label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Hourly Pay"
                                        {...register(
                                          `staffAttendance.${index}.hourlyPay`
                                        )}
                                        // onChange={(e) => setStartTimeState(e.target.value)}
                                      />
                                      {/* </Form.Control> */}
                                    </div>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name={`staffAttendance.${index}.extraPay`}
                                  render={({ field, fieldState }: any) => (
                                    <div className="mb-3">
                                      <label
                                        htmlFor="typeahead"
                                        className="form-label"
                                      >
                                        Extra Pay
                                      </label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Extra Pay"
                                        {...register(
                                          `staffAttendance.${index}.extraPay`
                                        )}
                                        // onChange={(e) => setStartTimeState(e.target.value)}
                                      />
                                      {/* </Form.Control> */}
                                    </div>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name={`staffAttendance.${index}.deduction`}
                                  render={({ field, fieldState }: any) => (
                                    <div className="mb-3">
                                      <label
                                        htmlFor="typeahead"
                                        className="form-label"
                                      >
                                        Deduction
                                      </label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Deduction"
                                        {...register(
                                          `staffAttendance.${index}.deduction`
                                        )}
                                        // onChange={(e) => setStartTimeState(e.target.value)}
                                      />
                                      {/* </Form.Control> */}
                                    </div>
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name={`staffAttendance.${index}.Notes`}
                                  render={({ field, fieldState }: any) => (
                                    <div className="mb-3">
                                      <label
                                        htmlFor="typeahead"
                                        className="form-label"
                                      >
                                        Notes
                                      </label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Notes"
                                        {...register(
                                          `staffAttendance.${index}.Notes`
                                        )}
                                        // onChange={(e) => setStartTimeState(e.target.value)}
                                      />
                                      {/* </Form.Control> */}
                                    </div>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name={`staffAttendance.${index}.status`}
                                  render={({ field, fieldState }: any) => (
                                    <div className="mb-3">
                                      <label
                                        htmlFor="typeahead"
                                        className="form-label"
                                      >
                                        Status
                                      </label>
                                      <Form.Control
                                        as="select"
                                        {...register(
                                          `staffAttendance.${index}.status`
                                        )}
                                      >
                                        <option value="PENDING">
                                          PENDING{" "}
                                        </option>
                                        <option value="INFORMED LATE">
                                          INFORMED LATE
                                        </option>
                                        <option value="NO SHOW">NO SHOW</option>
                                        <option value="MEDICAL CERTIFICATE">
                                          MEDICAL CERTIFICATE
                                        </option>
                                        <option value="REPORTED">
                                          REPORTED
                                        </option>
                                      </Form.Control>
                                      {/* <Form.Control
                    type="select"
                    placeholder="Status"
                  {...register(`staffAttendance.${index}.status`)}
                  // onChange={(e) => setStartTimeState(e.target.value)}
                 >
                  
                 </Form.Control> */}
                                      {/* </Form.Control> */}
                                    </div>
                                  )}
                                />
                              </Col>
                            </Row>
                          </form>
                        </>
                      );
                    }
                  })}
                </Card>
              </>
            </td>
          </tr>
        )}
      </>
    );
  }
  const searchByName = (name: any, value: any) => {
    if(name=== "hotels") {
      dispatch(listingHotels({name: value}))
    }
    else{
      dispatch(listingOutlets(OutletsActionTypes.LIST_OUTLETS,{name: name}))
    }
  }
  const filterValueChangeByHotelsOrOutlets = (fieldname: any, e: any) => {
    console.log("the name , e", fieldname, e)
    if(fieldname === "hotels"){
      dispatch(listingOutlets(OutletsActionTypes.LIST_OUTLETS,{hotel: e[0]?.value, page:page, limit: limit}))
      dispatch(listingStaffAttendance({hotel: e[0]?.value, page: currentPage, limit: 10, startDate: state[0].startDate, endDate: state[0].endDate}))
    }
    else if(fieldname === "outlets")
      {
        dispatch(listingStaffAttendance({outlet: e[0]?.value , page:currentPage, limit: 10,startDate: state[0].startDate, endDate: state[0].endDate}))
      }
   }
   const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) => hotel.push({id: index, value: list._id, label: list.hotelName?  list.hotelName : " "}))
    return hotel;
   }
   const outletOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) => hotel.push({id: index, value: list._id, label: list.outletName? list.outletName : " "}))
    return hotel;
   }

   const [responsiveModalDate,setResponsiveModalDate] = useState(false);
   const toggleResponsiveModalDate = () => setResponsiveModalDate(!responsiveModalDate)
   const tabContents = () => {
    let i;
    let j;
    const arr = []
    for(i=7; i>= 0; i--){
        arr.push({
          index:i,
          date: moment(new Date()).subtract(i,'days'),
          dateShow: moment(new Date()).subtract(i,'days').format('MMM DD')
        })
    }
    for(j=1; j <= 7; j++){
      arr.push({
        index:j,
        date: moment(new Date()).add(j,'days'),
        dateShow: moment(new Date()).add(j,'days').format('MMM DD')
        
      })
  }
    return arr;
  }
  return (
    <>
      <h1>Staff Attendance</h1>
      <Modal show={responsiveModalDate} onHide={toggleResponsiveModalDate}>
    <DateRangePicker
        onChange={(item: any) => setState([item.selection])}
        // onChange={item => setState([item.selection])}
        // showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        />
    </Modal>
      <CommonSearchBar

          search={(e:any) => searchStaffAttendance(e)}
          searchByName={searchByName}
          filterValueChanged={filterValueChangeByHotelsOrOutlets}
          filters={
            [
              {label: "Hotels",
              name: "hotels",
              placeholder:"Please select a hotel",
              options:hotelOptionsListForSearch(hotelsList),
              isSelect: true
              },

              {label: "Outlets",
                name: "outlets",
                placeholder:"Please select a outlet",
                options:outletOptionsListForSearch(outletsList),
                isSelect: true
              }
            ]
          }
          />
          <div  style={{justifyContent:"center",display:'flex', marginBottom:'10px'}}>
            {state && (
              <span style={{
                background: "lightgrey",
                width: "20%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "10px",
                padding: "6px",
                marginTop:"10px"
              }} onClick={() => setResponsiveModalDate(true)}>{moment(state[0].startDate).format('MMM DD')} -  {moment(state[0].endDate).format('MMM DD')}</span>
            )}
           </div>
           <div>
           <Col xl={12}>
          <Card>
            <Card.Body>
              <Tab.Container defaultActiveKey="Profile">
              <Nav as="ul" variant="pills" justify className="navtab-bg"
                
                style={{
                  overflowX: "auto",
                  overflowY: "hidden",
                  flexWrap: "nowrap"
                }} >
                  {(tabContents() || []).map((tab, index) => {
                      return (
                        <Nav.Item as="li" key={index}>
                          <Nav.Link
                            className="cursor-pointer"
                            href="#"
                            style={{
                              cursor: "pointer",
                            }}
                            eventKey={tab.dateShow}
                            // eventKey={(moment(startDate).format("MMM DD") === moment(endDate).format("MMM DD")) ? moment(startDate).format("MMM DD") : ''}
                            onClick={() => {
                              console.log(
                                "diff",
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "YYYY-MM-DD", "days")
                                )
                              );
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) < 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    endDate: moment(tab.date).toDate(),
                                    startDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) > 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    startDate: moment(tab.date).toDate(),
                                    endDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                            }}
                          >
                            {tab.dateShow}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                </Nav>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
           </div>
      <Button
        style={{ float: "right" , marginBottom:'15px'}}
        variant="success"
        className="waves-effect waves-light"
        onClick={() => editAttendanceSubmit()}
      >
        <i className="mdi mdi-plus-circle me-1"></i> Save Staff Attendance Details
      </Button>
      
      <Table style={{ backgroundColor: "white" }}>
        <thead>
          <tr>
            <th style={{ width: "12.5%", fontWeight: "900" }} align="right">
              Name
            </th>
            <th style={{ width: "12.5%", fontWeight: "900" }} align="right">
              Hotel
            </th>
            <th style={{ width: "12.5%", fontWeight: "900" }} align="right">
              Outlet
            </th>
            <th style={{ width: "12.5%", fontWeight: "900" }} align="right">
              Date
            </th>
            <th style={{ width: "12.5%", fontWeight: "900" }} align="right">
              Status
            </th>
            <th style={{ width: "12.5%", fontWeight: "900" }} align="right">
              Total Pay
            </th>
            <th style={{ width: "12.5%", fontWeight: "900" }} align="right">
              Final Pay
            </th>
            <th style={{ width: "12.5%", fontWeight: "900" }} align="right">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {staffAttendanceList?.data?.map((details: any) => {
            console.log("the time", details.startTime, details.endTime);
            return <CollapsibleRow data={details}></CollapsibleRow>;
          })}
        </tbody>
      </Table>

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={staffAttendanceList?.totalCount}
        pageSize={10}
        onPageChange={(page: any) => setCurrentPage(page)}
      />
    </>
  );
};

export default StaffAttendanceList;
