import { StaffStatusActionTypes } from "./constants";


const INIT_STATE = {
  staffStatusLoading: false,
  staffStatusList: [],
  page: 0,
  limit:50,
};


interface AuthActionType {
  type:
  | StaffStatusActionTypes.API_STAFF_STATUS_RESPONSE_SUCCESS
  | StaffStatusActionTypes.API_STAFF_STATUS_RESPONSE_ERROR
  | StaffStatusActionTypes.LIST_STAFF_STATUS
  | StaffStatusActionTypes.LIST_STAFF_STATUS_BY_OUTLET
  | StaffStatusActionTypes.LIST_STAFF_STATUS_LOADING
  | StaffStatusActionTypes.RESET_STAFF_STATUS
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  staffStatusLoading: boolean,
  staffStatusList: any[],
  page: any,
  limit:any,
}

const StaffStatusReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case StaffStatusActionTypes.API_STAFF_STATUS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case StaffStatusActionTypes.LIST_STAFF_STATUS: {
          return {
            ...state,
            staffStatusLoading: false,
            staffStatusList: action.payload.data
          };
        }
        case StaffStatusActionTypes.LIST_STAFF_STATUS_BY_OUTLET: {
          return {
            ...state,
            staffStatusLoading: false,
            staffStatusList: action.payload.data
          };
        }
        case StaffStatusActionTypes.LIST_STAFF_STATUS_LOADING: {
          return {
            ...state,
            staffStatusLoading: true,
          };
        }
        default:
          return { ...state };
      }

    case StaffStatusActionTypes.API_STAFF_STATUS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case StaffStatusActionTypes.LIST_STAFF_STATUS: {
          return {
            ...state,
            staffStatusLoading: false,
            staffStatusList: action.payload.data
          };
        }
        case StaffStatusActionTypes.LIST_STAFF_STATUS_BY_OUTLET: {
          return {
            ...state,
            staffStatusLoading: false,
            staffStatusList: action.payload.data
          };
        }
        case StaffStatusActionTypes.LIST_STAFF_STATUS_LOADING: {
          return {
            ...state,
            staffStatusLoading: true,
          };
        }
      
        default:
          return { ...state };
      }

    case StaffStatusActionTypes.RESET_STAFF_STATUS:
      return {
        ...state,
        staffStatusLoading: false,
        staffStatusList: [],
        page: 0,
        limit:50,
      };
    default:
      return { ...state };
  }
};

export default StaffStatusReducer;
