export const searchFilterForGroups = 
[
    {
        key:"page"
    },
    {
        key: "limit"
    },
    {
        key: "name"
    },
    {
        key: "hotel"
    },
    {
        key: "outlet"
    }
]

// table columns
export const SlotsTableColumns =  (data: any) => {
    // console.log('the support tal', data)
const arr:any= [];
 data?.data?.map((details:any) => {
     arr.push({
        id: details._id,
        position: details.position,
        date: details.date,
        hotel: details.hotel,
        outlet: details.outlet,
        shiftName: details.shiftName,
        status: details.status,
        vacancy: details.vacancy,
        confirmedWaitingNo:  details.confirmedRequests.length + details.waitingRequests.length,
        hourlyPay: details.hourlyPay,
        totalPay: details.totalPay * details.vacancy,
    }) 
}) 

return arr;
}