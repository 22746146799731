export enum SlotsActionTypes {
  API_SLOTS_RESPONSE_SUCCESS = "@@slots/API_SLOTS_RESPONSE_SUCCESS",
  API_SLOTS_RESPONSE_ERROR = "@@slots/API_SLOTS_RESPONSE_ERROR",

  LIST_SLOTS = "@@slots/LIST_SLOTS",
  CREATE_SLOTS = "@@slots/CREATE_SLOTS",
  EDIT_SLOTS = "@@slots/EDIT_SLOTS",
  DELET_SLOTS = "@@slots/DELETE_SLOTS",
  EDIT_SLOTS_SELECTED = "@@slots/EDIT_SLOTS_SELECTED",
  LIST_POSITIONS = "@@slots/LIST_POSITION",
  ADD_POSITION = "@@slots/ADD_POSITION",
  LIST_SLOT_USERS = "@@slots/LIST_SLOT_USERS",
  GET_SLOTS_BY_USER_ID = "@@slots/GET_SLOTS_BY_USER_ID",

  LIST_SLOTS_LOADING = "@@slots/LIST_SLOTS_LOADING",
  CREATE_SLOTS_LOADING = "@@slots/CREATE_SLOTS_LOADING",
  EDIT_SLOTS_SELECTED_LOADING = "@@slots/EDIT_SLOTS_SELECTED_LOADING",
  LIST_POSITIONS_LOADING = "@@slots/LIST_POSITION_LOADING",
  LIST_SLOT_USERS_LOADING = "@@slots/LIST_SLOT_USERS_LOADING",
  DELET_SLOTS_LOADING = "@@slots/DELETE_SLOTS_LOADING",

  

  RESET_SLOTS = "@@slots/RESET_SLOTS",
}
