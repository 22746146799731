import { Form } from "react-bootstrap";
export const InvoiceListTableColumns = (
  data: any,
  saveSelectedInvoices: any,
  count: any,
  selectedtomerge: any
) => {
  console.log("the merge",selectedtomerge,selectedtomerge.length)
  const arr: any = [];
  data?.data?.invoice_list?.map((details: any) => {
    arr.push({
      id: details._id,
      invoiceUID: details.invoiceUID,
      amount: details.amount,
      invoiceDate: details.invoiceDate,
      receivedDate: details.receivedDate,
      status: details.status,
      mergeID: (
        <Form>
          <Form.Check
            // defaultChecked={(selectedtomerge.findIndex((invoice:any) => invoice === details._id) !== -1) ?  false : true}
            type="checkbox"
            id="custom-switch"
            // disabled={count === 2 ? true : false}
            onChange={(e) => saveSelectedInvoices(e, details)}
          />
        </Form>
      ),
    });
  });

  return arr;
};


export const InvoiceDetailsListTableColumns = (
  data: any,
  saveSelectedInvoices: any,
) => {
  const arr: any = [];
  console.log("the data", data)
  data[0]?.dayinvoiceList?.map((details: any) => {
    arr.push({
      id: details._id,
      description: details.description,
      totalAmount: details.totalAmount,
      hourlyPay: details.hourlyPay,
      totalHours: details.totalHours,
      splitID: (
        <Form>
          <Form.Check
            // defaultChecked={(selectedtomerge.findIndex((invoice:any) => invoice === details._id) !== -1) ?  false : true}
            type="checkbox"
            id="custom-switch"
            // disabled={count === 2 ? true : false}
            onChange={(e) => saveSelectedInvoices(e, details)}
          />
        </Form>
      ),
    });
  });

  return arr;
};

