const config = {
  // API_URL: process.env.REACT_APP_API_URL,
  //  API_URL:"http://localhost:5001/api/",
 API_URL:"https://jobs.doingbydo.com/api/",
// "https://my.um.edu.sa/backend/auth/admin/login"
// API_SOCKET_URL : "http://localhost:5001"
API_SOCKET_URL : "https://jobs.doingbydo.com"
};

export default config;
