import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  getChats as getChatsApi,
  getMessages as getMessagesApi
} from "../../helpers/api/chat";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./action";

// constants
import { ChatActionTypes } from "./constants";


const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* getChats(): SagaIterator {
  try {
    const response = yield call(getChatsApi,{});
    const user = response.data;
    yield put(authApiResponseSuccess(ChatActionTypes.GET_CHATS, user));
  } catch (error: any) {
    yield put(authApiResponseError(ChatActionTypes.GET_CHATS, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* getMessage({
    payload: id,
  }: any): SagaIterator {
    try {
      const response = yield call(getMessagesApi,id);
      const user = response.data;
      yield put(authApiResponseSuccess(ChatActionTypes.GET_MESSAGES, user));
    } catch (error: any) {
      yield put(authApiResponseError(ChatActionTypes.GET_MESSAGES, error));
      api.setLoggedInUser(null);
      setAuthorization(null);
    }
  }

function* getChatsLoading(): SagaIterator {
  try {
    yield put(
      authApiResponseSuccess(ChatActionTypes.GET_CHATS_LOADING, {})
    );
  } catch (error: any) {
    yield put(authApiResponseError(ChatActionTypes.GET_CHATS_LOADING, error));
  }
}

function* getMessageLoading(): SagaIterator {
    try {
      yield put(
        authApiResponseSuccess(ChatActionTypes.GET_MESSAGES_LOADING, {})
      );
    } catch (error: any) {
      yield put(authApiResponseError(ChatActionTypes.GET_MESSAGES_LOADING, error));
    }
  }




export function* watchGetChatsLoading() {
  yield takeEvery(ChatActionTypes.GET_CHATS_LOADING, getChatsLoading);
}

export function* watchGetChats() {
  yield takeEvery(ChatActionTypes.GET_CHATS, getChats);
}

export function* watchGetMessagaLoading(): any {
  yield takeEvery(ChatActionTypes.GET_MESSAGES_LOADING, getMessageLoading);
}

export function* watchGetMessage(): any {
  yield takeEvery(ChatActionTypes.GET_MESSAGES, getMessage);
}

function* chatSaga() {
  yield all([
    fork(watchGetChatsLoading),
    fork(watchGetChats),
    fork(watchGetMessagaLoading),
    fork(watchGetMessage),
  ]);
}

export default chatSaga;
