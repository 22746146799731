export enum FinanceActionTypes {
  API_FINANCE_RESPONSE_SUCCESS = "@@FINANCE/API_FINANCE_RESPONSE_SUCCESS",
  API_FINANCE_RESPONSE_ERROR = "@@FINANCE/API_FINANCE_RESPONSE_ERROR",

  // loading
  LIST_FINANCE_LOADING = "@@FINANCE/LIST_FINANCE_LOADING",
  LIST_FINANACE_PENDINNG_LOADING= "@@FINANCE/LIST_FINANCE_PENDING_LOADING",
  DOWNLOAD_FINANCE_LOADING = "@@FINANCE/DOWNLOAD_FINANCE_LOADING",
  SAVE_FINANCE_LOADING = "@@FINANCE/SEND_FINANCE_LOADING",
  DELETE_FINANCE_BY_ID_LOADING = "@@FINANCE/REMOVE_FINANCE_BY_ID_LOADING",
  APPROVE_FINANCE_LOADING = "@@FINANCE/APPROVE_FINANCE_LOADING",
  CREATE_SUBCATEGORY_LOADING= "@@FINANCE/CREATE_SUBCATEGORY_LOADING",
  
// ACTIONS
  CREATE_SUCATEGORY = "@@FINANCE/CREATE_SUBCATEGORY",
  LIST_FINANCE = "@@FINANCE/LIST_FINANCE",
  DOWNLOAD_FINANCE = "@@FINANCE/DOWNLOAD_FINANCE",
  PENDING_FINANCE = "@FINANCE/PENDING_FINANCE",
  SAVE_FINANCE = "@@FINANCE/SEND_FINANCE",
  DELETE_FINANCE_BY_ID = "@@FINANCE/REMOVE_FINANCE_BY_ID",
  APPROVE_FINANCE = "@@FINANCE/APPROVE_FINANCE",
  LISTING_SUBCATEGORY = "@@FIANCEN/LISTING_SUBCATEGORY",
  
  RESET_FINANCE = "@@FINANCE/RESET_FINANCE",
}
