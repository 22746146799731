import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import classnames from "classnames";

// components
import FormInput from "../../components/FormInput";


import {  useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Loader from "../../components/Loader";

// Custom hook for always scrolling to bottom
// const AlwaysScrollToBottom = () => {
//   const elementRef = useRef<HTMLDivElement>(null);
//   useEffect(() => {
//     if (elementRef.current) {
//       elementRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, []);
//   return <div ref={elementRef} />;
// };
const AlwaysScrollToBottom = ({ dependencies }: { dependencies: any[] }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [...dependencies]); // Scroll when dependencies (messages) change

  return <div ref={elementRef} />;
};
// Schema for form validation
const schemaResolver = yupResolver(
  yup.object().shape({
    newMessage: yup.string().required("Please enter your message"),
  })
);

interface ChatAreaProps {
  selectedUser: any;
  socket: any;
  room: any;
  sendMessage:any;
  setUserMessages:any;
  userMessages:any;
}

const ChatArea = ({ selectedUser, socket, room, sendMessage ,setUserMessages,userMessages }: ChatAreaProps) => {
  
  const {messages,messageLoading}  = useSelector((state:RootState) => state.ChatReducer)
  // const [selectedgroup,setSelectedGroup] = useState({selectedUser});


  
  useEffect(() =>{
    messages?.result && setUserMessages(messages?.result?.data);
    console.log("meassage vannu")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[messages?.result])

  useEffect(() => {
    setUserMessages([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[room])


  const methods = useForm({ resolver: schemaResolver });
  const { handleSubmit, register, reset } = methods;
  const ID = JSON.parse(localStorage.getItem('userLoggedIn')|| '{}')

  // Function to handle message sending
  const sendChatMessage = async (values: any) => {
  //  console.log("the user id",ID._id,values)
    const newMessage: any = {
      // id: userMessages.length + 1,
      // from: toUser,
      to: selectedUser,
      message: values.newMessage,
      senderId: ID._id,
      sender: ID.name,
      sendOn: new Date().getHours() + ":" + new Date().getMinutes(),
      origin: "Web",
    };
    // Emit message to server
    socket.emit("send_message", { roomId: room, message: newMessage });

    // Update the UI with the new message
    setUserMessages([...userMessages, newMessage]);

    // Reset the input field
    reset();

  };


  return (
    <>
      <Card >
        <Card.Body className="py-2 px-3 border-bottom border-light" style={ {
    backgroundColor: "#b6b8e1"
}}>
          <Row className="justify-content-between py-1">
            <Col sm={7}>
              <div className="d-flex align-items-start">
                {/* <img
                  src={"f"}
                  className="me-2 rounded-circle"
                  height="36"
                  alt="User"
                /> */}
                <div className="circle">
                  {selectedUser?.displayName?.[0] || selectedUser?.name}
                </div>
                <div>
                  <h5 className="mt-0 mb-0 font-15">
                    <Link to="/apps/contacts/profile" className="text-reset">
                      {selectedUser?.displayName}
                    </Link>
                  </h5>
                  {/* <p className="mt-1 mb-0 text-muted font-12">
                    <small className="mdi mdi-circle text-success"></small>{" "}
                    Online
                  </p> */}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
        { messageLoading ? <Loader/>:<Card.Body>
          <SimpleBar style={{ height: "475px", width: "100%" }}>
            <ul className="conversation-list">
              {userMessages?.length > 0 &&
                userMessages?.map((message:any, index:any) => (
                  <li
                    className={classnames("clearfix", {
                      odd: message?.senderId === ID._id,
                    })} 
                    key={index}
                  >
                    <div className="chat-avatar">
                      <div className="circle">{message?.sender[0]}</div>
                      {/* <i>{message?.createdAt}</i> */}
                    </div>
                    <div className="conversation-text" >
                      <div className="ctext-wrap" 
                      //style={{backgroundColor:(message?.senderId === ID._id)?"#b6b8e1":"#95e2ac"} }
                      >
                        <i>{message?.sender}</i>
                        <p style={{
                           width: "100%", // Takes up 60% of the available width
                          wordBreak: "break-word", // Breaks long words
                          wordWrap: "break-word", // Wraps the text if needed
                          whiteSpace: "normal", // Ensures multiline display
                            }}
                          >{message?.message}</p>
                      </div>
                    </div>
                  </li>
                ))}
              <AlwaysScrollToBottom dependencies={[userMessages]} />
            </ul>
          </SimpleBar>

          <Row>
            <Col>
              <div className="mt-3 bg-light p-3 rounded">
                <form
                  noValidate
                  name="chat-form"
                  id="chat-form"
                  onSubmit={handleSubmit(sendChatMessage)}
                >
                  <div className="row">
                    <div className="col mb-2 mb-sm-0">
                      <FormInput
                        type="text"
                        name="newMessage"
                        className="border-0"
                        placeholder="Enter your text"
                        register={register}
                      />
                    </div>
                    <div className="col-sm-auto">
                      <button
                        type="submit"
                        className="btn btn-success chat-send w-100"
                      >
                        <i className="fe-send"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Card.Body>}
      </Card>
    </>
  );
};

export default ChatArea;
