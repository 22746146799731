import { Routes, Route } from "react-router-dom"
import SOALisitingPage from "./soaListingPage";
// import InvoiceListingPage from "./invoiceListingPage"
// import EditInvoice from "./editInvoiceDetails"


const SOA = () => {
  
        return(
            <>
            <Routes>
              <Route path="/" element={<SOALisitingPage />} />
              <Route 
              path={`/:page/:limit/:hotel`}
              element={<SOALisitingPage />} />
            </Routes>
            </>
        )

}

export default SOA;