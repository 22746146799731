import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import {
  editSelectedUsers,
  editSelectedUsersStatus,
  editUsers,
  listingCollege,
  listingUsers,
} from "../../redux/users/actions";
import BasicForms from "../forms/Basic";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { RootState } from "../../redux/store";
import { FormInput } from "../../components";
import { Controller, useForm, useFieldArray, useWatch, Control } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { listingHotels } from "../../redux/hotels/actions";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';


// If using Bootstrap 5 include the following:
// import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import {
  addOutlets,
  createOutletUsers,
  editSelectedOutlets,
  listingOutlets,
  listingOutletsByhotels,
} from "../../redux/outlets/actions";
import FileUpload from "../forms/FileUpload";
import axios from "axios";
import _ from "lodash";
import { createSlots, ListPositions } from "../../redux/slots/actions";
import moment from "moment";
import "moment-timezone";
import { listingGroups } from "../../redux/groups/actions";

type FormValues = {
  slots: { shiftName: string,
          startTime: string,
          endTime: string,
          hourlyPay: string,
          vacancy: string,
          release: string,
          dedicatedFilter: boolean,
          selectedPrivateGroup: never[],
          selectedPublicGroup: never[],
          selectedExclusiveUsers:never[],
          subscribersView: boolean,
          priority: string,
          slotStart: string,
          allocatedUsers: never[],
          position: string }[];
};

const ConditionField = ({
  control,
  index,
  register,
  watch
}: any) => {
  

  console.log("the outlpu", watch(`slots.${index}.dedicatedFilter`) )
  return(watch(`slots.${index}.dedicatedFilter`));

}

const AddSlotsPage = () => {
  const navigate = useNavigate();
  const { hotelsList, page, limit, selectedHotels } = useSelector(
    (state: RootState) => state.HotelsReducer
  );
  const { positions, slotsList } = useSelector(
    (state: RootState) => state.SlotsReducer
  );
  const { usersList } = useSelector((state: RootState) => state.UsersReducer);
  const { groupsList, grouploading } = useSelector(
    (state: RootState) => state.GroupsReducer
  );
  const dispatch = useDispatch();
  const [startTimeState, setStartTimeState] = useState<any>(0);
  const [endTimeState, setEndTimeState] = useState<any>(0);
  const [hourlypay, setHourlypay] = useState<any>(0);
  const [totalPay, setTotalPay] = useState(0);
  const [dedicatedFilterValue, setDedicatedFilterValue] = useState(false);
  const [slotsPayload, setSlotsPayload] = useState<any[]>([]);
  const [responsiveModal, setResponsiveModal] = useState(false);
  useEffect(() => {
    dispatch(ListPositions({}));
    dispatch(listingUsers({ userStatus: "VERIFIED", page: "1", limit: "0" }));
    dispatch(listingGroups({ page: page, limit: limit }));
  }, []);
  const methods = useForm({
    defaultValues: {
      slots: [
        {
          shiftName: "",
          startTime: "",
          endTime: "",
          hourlyPay: "",
          vacancy: "",
          release: "",
          dedicatedFilter: false,
          selectedPrivateGroup: [],
          selectedPublicGroup: [],
          selectedExclusiveUsers:[],
          subscribersView: false,
          priority: "",
          slotStart: "",
          allocatedUsers: [],
          position: "",
        },
      ],
    },
  });
 
  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "slots",
  });
 

 
  const saveSlotDetails = (slot: any) => {
    console.log("the dslot", slot.slot);
    const slotList = slot.slot;
    slot?.slots?.map((slots:any) => {

    let allocatedUsersarr: any[] = [];
    let selectedExclusiveUsersarr: any[]=[];
    let selectedPrivateGrouparr: any[] = [];
    let selectedPublicGrouparr: any[] = [];
    slots.priority = slots.priority[0]?.value
    slots.position = slots.position[0]?.value;
    slots.selectedExclusiveUsers.map((user: any) => selectedExclusiveUsersarr.push(user.value));
    slots.selectedExclusiveUsers = selectedExclusiveUsersarr;
    slots.allocatedUsers.map((user: any) => allocatedUsersarr.push(user.value));
    slots.allocatedUsers = allocatedUsersarr;
    slots.selectedPrivateGroup.map((privategr: any) =>
      selectedPrivateGrouparr.push(privategr.value)
    );
    slots.selectedPrivateGroup = selectedPrivateGrouparr;
    slots.selectedPublicGroup.map((publicgr: any) =>
      selectedPublicGrouparr.push(publicgr.value)
    );
    slots.selectedPublicGroup = selectedPublicGrouparr;
    slots.shiftName = `${slots.startTime}to${slots.endTime}`
    return slots
    })
    const payload = {
      
      slots:slot.slots,
      date: JSON.parse(localStorage.getItem('date') || '{}'),
      hotel: JSON.parse(localStorage.getItem('hotel') || '{}')[0].value,
    "outlet": JSON.parse(localStorage.getItem('outlet') || '{}')[0].value,
    }
    dispatch(createSlots(payload))
  };



  const hotelOptionsListForSearch = (data: any) => {
    console.log("thhdada",data)
    let position: any = [];
    data?.map((list: any, index: any) =>
      position.push({
        id: index,
        value: list._id,
        label: list.positionName ? list.positionName : " ",
      })
    );
    return position;
  };
  const UsersListForSearch = (data: any) => {
    let users: any = [];
    data?.data?.map((list: any, index: any) =>
      users.push({
        id: index,
        value: list._id,
        label: list.name ? list.name : " ",
      })
    );
    return users;
  };

  const GroupsListForSearch = (data: any, type: any) => {
    let groups: any = [];
    data?.data?.map((list: any, index: any) => {
      if (list.groupType === type) {
        console.log("the ye0", type);
        return groups.push({
          id: index,
          value: list._id,
          label: list.groupTitle ? list.groupTitle : " ",
        });
      }
    });

    return groups;
  };

  const convertToMinutes = (timeString:any) => {
    var hms = (timeString).toString()?.split(':');
    return (parseInt(hms[1]) / 60) + parseInt(hms[0])
  }
  const calculateBreakTime = (total:number) => {
    let breakTime = 0;
    if(total >= 8 && (total < 12)){
      console.log('the value greater than 8')
      breakTime = 0.5;
    }
    if(total >= 12){
      console.log('the value greater than 12')
      breakTime = 1;
    }
    return (total - breakTime);
  }
  const calculateTotalTime = () => {
    if (convertToMinutes((endTimeState)) > convertToMinutes((startTimeState))) {
      const total = convertToMinutes(endTimeState) - convertToMinutes(startTimeState);
      return ((calculateBreakTime(total)) * hourlypay);
    } else {
      const total = ((convertToMinutes('24:00') - convertToMinutes(startTimeState)) + (convertToMinutes(endTimeState) - convertToMinutes('00:00')));
      return ((calculateBreakTime(total)) * hourlypay);
    }
  }

  // const calculateTotalTime = () => {
  //   if (startTimeState && endTimeState) {
  //     const startDate = moment(startTimeState, "HH:mm");
  //     const timeEnd = moment(endTimeState, "HH:mm");
  //     let diff;
  //     // if (timeEnd > startDate) {
  //     //   diff = timeEnd.diff(startDate);
  //     // } else {
  //     //   diff = startDate.diff(timeEnd);
  //     // }
  //     diff = startDate.diff(timeEnd);
  //     console.log("the moment di", diff)
  //     const diffDuration = moment.duration(diff);

  //     // console.log("Hours in fun:", Math.abs(diffDuration.hours()));
  //     // console.log("Minutes in fu:", diffDuration.minutes());
  //     let breakTime = 0;
      
  //       if(diffDuration.hours() >= 8 && (diffDuration.hours() < 12)){
  //         console.log("the greatee thean 8")
  //         breakTime = 30;
  //       }
  //       if((diffDuration.hours() >= 12)){
  //         console.log("the greatee thean 12")
  //         breakTime = 60;
  //       }
  //       const total =
  //       diffDuration.hours() * Number(hourlypay) +
  //       (Number(hourlypay) / 60) * diffDuration.minutes()+ breakTime
  //     return  Math.abs(total).toFixed(2);
  //   } else {
  //     return 0;
  //   }
  // };

  useEffect(() => {
    const startDate = moment(startTimeState, "HH:mm");
    const timeEnd = moment(endTimeState, "HH:mm");
    let diff;
    if (timeEnd > startDate) {
      diff = timeEnd.diff(startDate);
    } else {
      diff = startDate.diff(timeEnd);
    }
    const diffDuration = moment.duration(diff);
    setTotalPay(
      diffDuration.hours() * Number(hourlypay) +
        (Number(hourlypay) / 60) * diffDuration.minutes()
    );
  }, [startTimeState, endTimeState, hourlypay]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {<h1>Add Slots</h1>}
        <div style={{cursor:'pointer'}} onClick={()=> navigate('/slots/list/0/50/all/all/all')}>X</div>
      </div>
      <form onSubmit={handleSubmit(saveSlotDetails)}>
        {fields.map((field, index) => (
          <>
            <Row>
              <Col md={3}>
                <Controller
                  control={control}
                  name={`slots.${index}.position`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Positions
                      </label>
                      <Typeahead
                        {...field}
                        id="basic-typeahead-single"
                        multiple={false}
                        onInputChange={(e:any) => dispatch(ListPositions({name:e}))}
                        options={hotelOptionsListForSearch(positions)}
                        placeholder="Positions"
                      />
                    </div>
                  )}
                />
              </Col>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.startTime`}
                 
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Start Time
                      </label>
                      <Form.Control
                       
                    type="time"
                    placeholder="Start Time"
                  {...register(`slots.${index}.startTime`)}
                  onChange={(e) => {
                    setStartTimeState(e.target.value)}}
                 />
                  {/* </Form.Control> */}
                    </div>
                  )}
                /> 
              </Col>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.endTime`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        End Time
                      </label>
                      <Form.Control
                    type="time"
                    placeholder="End Time"
                  {...register(`slots.${index}.endTime`)}
                  onChange={(e) => setEndTimeState(e.target.value)}
                 />
                    </div>
                  )}
                />
              </Col>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.hourlyPay`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Hourly Pay
                      </label>
                      <Form.Control
                    type="number"
                    min="0"
                    placeholder="Hourly Pay"
                  {...register(`slots.${index}.hourlyPay`)}
                  onChange={(e) => setHourlypay(e.target.value)}
                 />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.vacancy`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                       Vacancy
                      </label>
                      <Form.Control
                    type="number"
                    min="0"
                    placeholder="Vacancy"
                  {...register(`slots.${index}.vacancy`)}
                 />
                    </div>
                  )}
                />
              </Col>
              <Col md={3}>
              <Controller
                  control={control}
                  name={`slots.${index}.release`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                       Release
                      </label>
                      <Form.Control
                    type="number"
                    min="0"
                    placeholder="Release"
                  {...register(`slots.${index}.release`)}
                 />
                    </div>
                  )}
                />
              </Col>
              <Col md={3}>
                <Controller
                  control={control}
                  name={`slots.${index}.priority`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Priority
                      </label>
                      <Typeahead
                        {...field}
                        id="basic-typeahead-single"
                        multiple={false}
                        // onInputChange={(e) => searchByName('name', e)}
                        options={[
                          { id: 1, value: "HIGH", label: "HIGH" },
                          { id: 2, value: "LOW", label: "LOW" },
                        ]}
                        placeholder="Priority"
                      />
                    </div>
                  )}
                />
              </Col>
              
             
            </Row>
            <Col
                md={3}
                style={{
                  marginBottom:'3%'
                }}
              >
               {(!Number.isNaN(calculateTotalTime())) && ( <h5>Total:  ${calculateTotalTime()}</h5>)}
              </Col>
            <Row>
              <Col md={3} >
              <Controller
                  control={control}
                  name={`slots.${index}.dedicatedFilter`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3" style={{display:'flex'}}>
                      <label htmlFor="typeahead" className="form-label" style={{paddingRight:'10px'}}>
                      Dedicated Filter
                      </label>
                      <Form.Check
                    type="checkbox"
                    placeholder="Dedicated Filter"
                  {...register(`slots.${index}.dedicatedFilter`)}
                  
                 />
                    </div>
                  )}
                />
              </Col>
            </Row>
           {ConditionField({control,index,register,watch}) && (<><Row>
              <Col md={12}>
                <Controller
                  control={control}
                  name={`slots.${index}.allocatedUsers`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Dedicated Users
                      </label>
                      <Typeahead
                        {...field}
                        id="basic-typeahead-single"
                        multiple={true}
                        // onInputChange={(e) => searchByName('name', e)}
                        options={UsersListForSearch(usersList)}
                        placeholder="Dedicated Users" />
                    </div>
                  )} />
              </Col>
            </Row><Row>
                <Col md={12}>
                  <Controller
                    control={control}
                    name={`slots.${index}.selectedPrivateGroup`}
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Private Group
                        </label>
                        <Typeahead
                          {...field}
                          id="basic-typeahead-single"
                          multiple={true}
                          // onInputChange={(e) => searchByName('name', e)}
                          options={GroupsListForSearch(groupsList, "Private")}
                          placeholder="Private Group" />
                      </div>
                    )} />
                </Col>
              </Row><Row>
                <Col md={12}>
                  <Controller
                    control={control}
                    name={`slots.${index}.selectedPublicGroup`}
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Public Group
                        </label>
                        <Typeahead
                          {...field}
                          id="basic-typeahead-single"
                          multiple={true}
                          // onInputChange={(e) => searchByName('name', e)}
                          options={GroupsListForSearch(groupsList, "Public")}
                          placeholder="Public Groups" />
                      </div>
                    )} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Controller
                    control={control}
                    name={`slots.${index}.selectedExclusiveUsers`}
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Exclusive Users
                        </label>
                        <Typeahead
                          {...field}
                          id="basic-typeahead-single"
                          multiple={true}
                          // onInputChange={(e) => searchByName('name', e)}
                          options={UsersListForSearch(usersList)}
                          placeholder="Public Groups" />
                      </div>
                    )} />
                </Col>
              </Row>
              </>)}
            <Row>
              <Col md={12}>
              <Controller
                  control={control}
                  name={`slots.${index}.subscribersView`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3"  style={{display:'flex'}}>
                      <label htmlFor="typeahead" className="form-label"  style={{paddingRight:'10px'}}>
                      Preview for all subscribed users
                      </label>
                      <Form.Check
                    type="checkbox"
                    placeholder="Preview for all subscribed users"
                  {...register(`slots.${index}.subscribersView`)}
                 />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <div className="btn-box" style={{ display: "flex", justifyContent: "space-around" , marginBottom:'5%'}}>
                {fields.length !== 1 && (
                  <Button className="mr10" onClick={() => remove(index)}>
                    Remove
                  </Button>
                )}
                {fields.length - 1 === index && (
                  <Button
                    onClick={() =>
                      append({
                        shiftName: "",
                        startTime: "",
                        endTime: "",
                        hourlyPay: "",
                        vacancy: "",
                        release: "",
                        dedicatedFilter: false,
                        selectedPrivateGroup: [],
                        selectedExclusiveUsers:[],
                        selectedPublicGroup: [],
                        subscribersView: false,
                        priority: "",
                        slotStart: "",
                        allocatedUsers: [],
                        position: "",
                      })
                    }
                  >
                    Add
                  </Button>
                )}
              </div>
            </Row>
          </>
        ))}

        <Row>
          <Col style={{ display: "flex", justifyContent: "space-around" ,  marginTop:'5%'}}>
            <Button type="submit">Save Slot Details</Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AddSlotsPage;
