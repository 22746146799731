import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import { toast } from "react-toastify";
// helpers
import {
  listAttendances as listAttendancesApi,
  getAttendanceById as getAttendanceByIdApi,
  updateAttendanceById as updateAttendanceByIdApi,
  deletAttendanceById as deletAttendanceByIdApi,
  lockAttendanceById as lockAttendanceByIdApi,
  unlockAttendanceById as unlockAttendanceByIdApi,
  sendAttendanceById as sendAttendanceByIdApi,
  generateAttendanceById as generateAttendanceByIdApi
} from "../../helpers/api/attendance";


// constants
import { AttendanceActionTypes } from "./constants";
// actions
import { apiOutletsResponseError, apiOutletsResponseSucces } from "./actions";


function* listAttendance({payload: searchParams,
  type}: any): SagaIterator{
  try{

    console.log('the users in te slosts', searchParams)
    const response = yield call(listAttendancesApi, searchParams);
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.LIST_ATTENDANCES_LOADING, {}))
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.LIST_ATTENDANCES, response.data.result))
  }
  catch(err: any){
    yield put(apiOutletsResponseError(AttendanceActionTypes.LIST_ATTENDANCES, err))
  }
}


function* getAttendanceById({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(getAttendanceByIdApi, searchParams);
    console.log('the details',response.data.result)
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.GET_ATTENDANCE_BY_ID, response.data.result))
  }
  catch(err: any){
    yield put(apiOutletsResponseError(AttendanceActionTypes.GET_ATTENDANCE_BY_ID, err))
  }
}

function* editAttendance({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    const repsonse = yield call(updateAttendanceByIdApi, details);
    toast.success(repsonse.data.info.msg);
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED, repsonse.data.result[0]))
  }
  catch(err:any){
    toast.error(err.response.data.error);
    yield put(apiOutletsResponseError(AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED, err));
 
  }
}
function* deleteAttendance({
  id,
  type,
}: any): SagaIterator{
  try{
    const repsonse = yield call(deletAttendanceByIdApi, id);
    if(repsonse) yield call(listAttendance,{page:0,limit:5})
      toast.success(repsonse.data.info.msg);
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.DELET_ATTENDANCES,{}))
  }
  catch(err:any){
    toast.error(err.response.data.error);
    yield put(apiOutletsResponseError(AttendanceActionTypes.DELET_ATTENDANCES, err));
 
  }
}

function* lockAttendance({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', id)
    const repsonse = yield call(lockAttendanceByIdApi, id.id);
    console.log('theresp, response', repsonse)
    toast.success(repsonse.data.info.msg);
    if(repsonse) yield call(listAttendancesApi,id.details)
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.LOCK_ATTENDANCE_LOADING,{}))
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.LOCK_ATTENDANCE,repsonse.data.result))
  }
  catch(err:any){
    toast.error(err.response.data.error);
    yield put(apiOutletsResponseError(AttendanceActionTypes.LOCK_ATTENDANCE, err));
 
  }
}

function* unlockAttendance({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', id)
    const repsonse = yield call(unlockAttendanceByIdApi, id.id);
    toast.success(repsonse.data.info.msg);
    if(repsonse) yield call(listAttendancesApi,id.details)
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.LOCK_ATTENDANCE_LOADING,{}))
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.UNLOCK_ATTENDANCE,repsonse.data.result))
  }
  catch(err:any){
    toast.error(err.response.data.error);
    yield put(apiOutletsResponseError(AttendanceActionTypes.UNLOCK_ATTENDANCE, err));
 
  }
}
function* sendLoading():SagaIterator{
  yield put(apiOutletsResponseSucces(AttendanceActionTypes.SEND_ATTENDANCE_LOADING,{}))
}

function* sendAttendance({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', id)
 
    const repsonse = yield call(sendAttendanceByIdApi, id);
    toast.success(repsonse.data.info.msg);
 
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.SEND_ATTENDANCE,repsonse.data.result))
  }
  catch(err:any){
    toast.error(err.response.data.error);
    yield put(apiOutletsResponseError(AttendanceActionTypes.SEND_ATTENDANCE, err));
 
  }
}
function* lockLoading():SagaIterator{
  yield put(apiOutletsResponseSucces(AttendanceActionTypes.LOCK_ATTENDANCE_LOADING,{}))
}

function* generateAttendanceLoading():SagaIterator{
  yield put(apiOutletsResponseSucces(AttendanceActionTypes.GENERATE_ATTENDANCE_LOADING,{}))
}

function* generateAttendanceStopLoading():SagaIterator{
 
  yield put(apiOutletsResponseSucces(AttendanceActionTypes.GENETRATE_ATTENDANCE,{}))
}

function* generateAttendance({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log('the payload', id)

    const response = yield call(generateAttendanceByIdApi, id);
    console.log("the resp in generation", response)
    // toast.success("Download Sucessfully");
  
    
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.GENETRATE_ATTENDANCE,{}))
  }
  catch(err:any){
    // toast.error(err.response.data.error);
    // yield put(apiOutletsResponseError(AttendanceActionTypes.GENETRATE_ATTENDANCE, ''));
 
  }
}



function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    yield put(apiOutletsResponseSucces(AttendanceActionTypes.RESET_ATTENDANCES,{}))
  }
  catch(err:any){
    yield put(apiOutletsResponseError(AttendanceActionTypes.RESET_ATTENDANCES, err));
 
  }
}


export function* watchListingAttendance() {
  yield takeEvery(AttendanceActionTypes.LIST_ATTENDANCES, listAttendance);
}

export function* watchgetAttendanceById() {
  yield takeEvery(AttendanceActionTypes.GET_ATTENDANCE_BY_ID, getAttendanceById);
}
export function* watchEditAttendance() {
  yield takeEvery(AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED, editAttendance);
}
export function* watchDeletAttendance() {
  yield takeEvery(AttendanceActionTypes.DELET_ATTENDANCES, deleteAttendance);
}
export function* watchLockLoading(){
  yield takeEvery(AttendanceActionTypes.LIST_ATTENDANCES_LOADING,lockLoading)
}
export function* watchlockAttendance() {
  yield takeEvery(AttendanceActionTypes.LOCK_ATTENDANCE, lockAttendance);
}
export function* watchunlockAttendance() {
  yield takeEvery(AttendanceActionTypes.UNLOCK_ATTENDANCE, unlockAttendance);
}

export function* watchSentAttendanceLoading(){
  yield takeEvery(AttendanceActionTypes.SEND_ATTENDANCE_LOADING, sendLoading);
}

export function* watchsendAttendance() {
  yield takeEvery(AttendanceActionTypes.SEND_ATTENDANCE, sendAttendance);
}
export function* watchGenerateAttendanceLoading(){
  yield takeEvery(AttendanceActionTypes.GENERATE_ATTENDANCE_LOADING,generateAttendanceLoading)
}
export function* watchgenerateAttendance() {
  yield takeEvery(AttendanceActionTypes.GENETRATE_ATTENDANCE, generateAttendance);
}
export function* watchStopGenerateAttendace(){
  yield takeEvery(AttendanceActionTypes.GENERATE_ATTENDANCE_STOP_LOADING,generateAttendanceStopLoading)
}
export function* watchresetValues() {
  yield takeEvery(AttendanceActionTypes.RESET_ATTENDANCES, resetValues);
}

function* attendanceSaga() {
  yield all([
    fork(watchListingAttendance),
    fork(watchgetAttendanceById),
    fork(watchEditAttendance),
    fork(watchDeletAttendance),
    fork(watchlockAttendance),
    fork(watchunlockAttendance),
    fork(watchsendAttendance),
    fork(watchgenerateAttendance),
    fork(watchresetValues),
    fork(watchSentAttendanceLoading),
    fork(watchGenerateAttendanceLoading),
    fork(watchStopGenerateAttendace),
    fork(watchLockLoading)
  ]);
}

export default attendanceSaga;
