
// constants
import { OutletsActionTypes } from "./constants";


const INIT_STATE = {
  outletsUsersloading: false,
  outletsUsersList: [],
  page: 0,
  limit:50,
  selectedOutletsUsers:{}
};


interface AuthActionType {
  type:
    | OutletsActionTypes.API_USERS_OUTLETS_RESPONSE_SUCCESS
    | OutletsActionTypes.API_USERS_OUTLETS_RESPONSE_ERROR
    | OutletsActionTypes.LIST_USERS_OUTLETS
    | OutletsActionTypes.CREATE_USERS_OUTLETS
    | OutletsActionTypes.RESET_USERS_OUTLETS
    | OutletsActionTypes.DELET_USERS_OUTLETS;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  outletsUsersloading: boolean,
  outletsUsersList: any[],
  page: number,
  limit:number,
  selectedOutletsUsers:any
}

const OutletUsersReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case OutletsActionTypes.API_USERS_OUTLETS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case OutletsActionTypes.LIST_USERS_OUTLETS: {
          return {
            ...state,
            outletsUsersloading: false,
            outletsUsersList: action.payload.data
          };
        }
        case OutletsActionTypes.SEARCH_USERS_OUTLETS_BY_HOTELS: {
          return {
            ...state,
            outletsUsersloading: false,
            outletsUsersList: action.payload.data
          };
        }
        
        case OutletsActionTypes.EDIT_USERS_OUTLETS_SELECTED: {
          return {
            ...state,
            outletsUsersloading: false,
            selectedOutletsUsers:action.payload.data
          };
        }
        
        case OutletsActionTypes.DELET_USERS_OUTLETS: {
          return {
            ...state,
            outletsUsersloading: false,
          };
        }
        case OutletsActionTypes.CREATE_USERS_OUTLETS: {
          return {
            ...state,
            loading: false,
            userSignUp: true,
          };
        }
        case OutletsActionTypes.EDIT_USERS_OUTLETS: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
          };
        }
        default:
          return { ...state };
      }

    case OutletsActionTypes.API_USERS_OUTLETS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case OutletsActionTypes.LIST_USERS_OUTLETS: {
          return {
            ...state,
           
          };
        }
        case OutletsActionTypes.SEARCH_USERS_OUTLETS_BY_HOTELS: {
          return {
            ...state,
           
          };
        }
        case OutletsActionTypes.CREATE_USERS_OUTLETS: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case OutletsActionTypes.EDIT_USERS_OUTLETS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        default:
          return { ...state };
      }

    case OutletsActionTypes.RESET_USERS_OUTLETS:
      return {
        ...state,
        selectedOutletss: {}
      };
    default:
      return { ...state };
  }
};

export default OutletUsersReducer;
