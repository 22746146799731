import { Form } from "react-bootstrap";

export const searchFilterForGroups = 
[
    {
        key:"page"
    },
    {
        key: "limit"
    },
    {
        key: "name"
    },
    {
        key: "hotel"
    },
    {
        key: "outlet"
    }
]

// table columns
export const SlotsTableColumns =  (data: any, lockAttendance: any, lockAttendanceLoading:any) => {
    console.log('the support tal', data)
const arr:any= [];
 data?.data?.map((details:any) => {
     arr.push({
        _id: details._id,
        attendanceName: details.attendanceName,
        hotel: details.hotelDetails.hotelName,
        outlet: details.outletDetails.outletName,
        status: details.status,
        // isLocked: details.isLocked
        isLocked:
          (<Form>
        <Form.Check
        style={{cursor:"pointer"}}
          defaultChecked={details.isLocked ? true: false}
          type="switch"
          id="custom-switch"
          onChange={(e) => lockAttendance(e.target.checked, details)}
        />
      </Form>)
    }) 
}) 

return arr;
}