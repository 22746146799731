import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  listsupports as listSupportsApi,
  creatSupports  as createSupportApi,
  editSupports as editSupportsApi,
  deleteSupports as deleteSupportsApi,
  listsupportsById as listsupportsByIdApi,
  updateAdminStatus as updateAdminStatusApi
} from "../../helpers/api/supports";

// actions
import { SupportApiResponseSuccess, SupportApiResponseError } from "./actions";

// constants
import { GroupSupportActionTypes } from "./constants";
import ToastResponse from "../../components/Toaster";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* listSupports({
  payload: searchParams,
  type,
}: any): SagaIterator {
  try {
    const response = yield call(listSupportsApi,searchParams);
    // const user = response.data;
    // NOTE - You can change this according to response format from your api
    // api.setLoggedInUser(user);
    // setAuthorization(user["token"]);
    console.log('the resp', response)
    
    yield put(SupportApiResponseSuccess(GroupSupportActionTypes.LIST_SUPPORTS, response.data.result));
  } catch (error: any) {
    console.log("the resp, response",error)
    yield put(SupportApiResponseError(GroupSupportActionTypes.LIST_SUPPORTS, error));
    // api.setLoggedInUser(null);
    // setAuthorization(null);
  }
}
function* listSupportsById({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    const repsonse = yield call(listsupportsByIdApi, details);
    console.log('theresp, response', repsonse)
    yield put(SupportApiResponseSuccess(GroupSupportActionTypes.EDIT_SUPPORTS_SELECTED, repsonse.data.result[0]))
  }
  catch(err:any){
    yield put(SupportApiResponseError(GroupSupportActionTypes.EDIT_SUPPORTS_SELECTED, err));
 
  }
}
function* createSupport({
  payload,
  type,
}: any): SagaIterator{
  try{
    const response  = yield call(createSupportApi, payload)
    console.log("the resp", response)
    if(response) yield call(listSupports,{})
    yield put(SupportApiResponseSuccess(GroupSupportActionTypes.CREATE_SUPPORTS, {}));
   }
   catch(err: any){
    yield put(SupportApiResponseError(GroupSupportActionTypes.CREATE_SUPPORTS, err))
   }
}
function* editSupports({
  payload,
  id,
  type
}: any): SagaIterator{
  try{
    
    const repsonse = yield call(editSupportsApi, payload, id)
    console.log("the resp", repsonse)
    if(repsonse) yield call(listSupports,{})
    yield put(SupportApiResponseSuccess(GroupSupportActionTypes.EDIT_SUPPORTS, {}));
  }
  catch(error: any){
    yield put(SupportApiResponseError(GroupSupportActionTypes.EDIT_SUPPORTS, error))
  }
}
function* updateAdminStatus({
  payload,
  type
}: any): SagaIterator{
  try{
    const response  = yield call(updateAdminStatusApi,payload)
    console.log("the resp, response in update", response)
      if(response) yield call(listSupports,{})
    // ToastResponse({status: response.data.success,msg: response.data.info.msg})
  }
  catch (error: any){
    console.log("the error", error)
    yield put(SupportApiResponseError(GroupSupportActionTypes.UPDATE_ADMIN_STATUS, error))
  }
}
function* deleteSupports({
  id,
  type
}: any): SagaIterator {
  try{
    const response = yield call(deleteSupportsApi,id)
    if(response) yield call(listSupports,{})
    yield put(SupportApiResponseSuccess(GroupSupportActionTypes.DELETE_SUPPORTS,{}))
  }
  catch(err: any){
    yield put(SupportApiResponseError(GroupSupportActionTypes.DELETE_SUPPORTS, err))
  }
}

// watches
export function* watchListingSupports() {
  yield takeEvery(GroupSupportActionTypes.LIST_SUPPORTS, listSupports);
}

export function* watchListSupportById(): any {
  yield takeEvery(GroupSupportActionTypes.EDIT_SUPPORTS_SELECTED, listSupportsById);
}

export function* watchCreateSupport() {
  yield takeEvery(GroupSupportActionTypes.CREATE_SUPPORTS, createSupport);
}

export function* watchEditSupports(): any {
  yield takeEvery(GroupSupportActionTypes.EDIT_SUPPORTS, editSupports);
}

export function* watchDeleteSupports(): any {
  yield takeEvery(GroupSupportActionTypes.DELETE_SUPPORTS, deleteSupports);
}

export function* watchUpdateAdminStatus(): any{
  yield takeEvery(GroupSupportActionTypes.UPDATE_ADMIN_STATUS, updateAdminStatus)
}

function* supportSaga() {
  yield all([
    fork(watchListingSupports),
    fork(watchCreateSupport),
    fork(watchListSupportById),
    fork(watchEditSupports),
    fork(watchDeleteSupports),
    fork(watchUpdateAdminStatus)
  ]);
}

export default supportSaga;
