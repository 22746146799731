// constants
import { SOAActionTypes } from "./constants";

const INIT_STATE = {
  soalist: [],
  soalistLoading: false,
  downloadSOALoading: false,
  sendSOALoading: false,
  removeSOALaoding: false,
  page: 0,
  limit: 10,
};

interface AuthActionType {
  type:
    | SOAActionTypes.API_SOA_RESPONSE_ERROR
    | SOAActionTypes.API_SOA_RESPONSE_SUCCESS
    | SOAActionTypes.LIST_SOA
    | SOAActionTypes.LIST_SOA_LOADING
    | SOAActionTypes.DOWNLOAD_SOA
    | SOAActionTypes.DOWNLOAD_SOA_LOADING
    | SOAActionTypes.SEND_SOA
    | SOAActionTypes.SEND_SOA_LOADING
    | SOAActionTypes.REMOVE_SOA_BY_ID
    | SOAActionTypes.REMOVE_SOA_BY_ID_LOADING
    | SOAActionTypes.RESET_SOA;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  soalist: any,
  soalistLoading:boolean ,
  downloadSOALoading: boolean,
  sendSOALoading: boolean,
  removeSOALaoding: boolean,
  page: number,
  limit: number,
}

const SOAReducer = (
  state: State = INIT_STATE,
  action: AuthActionType
): any => {
  switch (action.type) {
    case SOAActionTypes.API_SOA_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SOAActionTypes.LIST_SOA: {
          return {
            ...state,
            soalistLoading: false,
            soalist: action.payload.data
          };
        }
        case SOAActionTypes.LIST_SOA_LOADING: {
          return {
            ...state,
            soalistLoading: true,
          };
        }
        case SOAActionTypes.DOWNLOAD_SOA_LOADING: {
          return {
            ...state,
            downloadSOALoading: true
          };
        }
        case SOAActionTypes.DOWNLOAD_SOA: {
          return {
            ...state,
            downloadSOALoading: false
          };
        }
        case SOAActionTypes.SEND_SOA_LOADING: {
          return {
            ...state,
            sendSOALoading: true
          };
        }
        case SOAActionTypes.SEND_SOA: {
          return {
            ...state,
            sendSOALoading: false
          };
        }
        case SOAActionTypes.REMOVE_SOA_BY_ID_LOADING: {
          return {
            ...state,
            removeSOALaoding: true
          };
        }
        case SOAActionTypes.REMOVE_SOA_BY_ID: {
          return {
            ...state,
            removeSOALaoding: false,
          };
        }
      
        default:
          return { ...state };
      }

    case SOAActionTypes.API_SOA_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SOAActionTypes.LIST_SOA: {
          return {
            ...state,
            soalistLoading: false,
            soalist: action.payload.error
          };
        }
        case SOAActionTypes.LIST_SOA_LOADING: {
          return {
            ...state,
            soalistLoading: true,
          };
        }
        case SOAActionTypes.DOWNLOAD_SOA_LOADING: {
          return {
            ...state,
            downloadSOALoading: true
          };
        }
        case SOAActionTypes.DOWNLOAD_SOA: {
          return {
            ...state,
            downloadSOALoading: false
          };
        }
        case SOAActionTypes.SEND_SOA_LOADING: {
          return {
            ...state,
            sendSOALoading: true
          };
        }
        case SOAActionTypes.SEND_SOA: {
          return {
            ...state,
            sendSOALoading: false
          };
        }
        case SOAActionTypes.REMOVE_SOA_BY_ID_LOADING: {
          return {
            ...state,
            removeSOALaoding: true
          };
        }
        case SOAActionTypes.REMOVE_SOA_BY_ID: {
          return {
            ...state,
            removeSOALaoding: false,
          };
        }
     
        default:
          return { ...state };
      }

    case SOAActionTypes.RESET_SOA:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default SOAReducer;
