
// constants
import { WalletActionTypes } from "./constants";

const INIT_STATE = {
  walletLoading: false,
  walletCreationLoading: false,
  walletList: [],
  redeemList:[],
  redeemListLoading: false,
  paidList: [],
  paidListLoading:false,
};


interface AuthActionType {
  type:
    | WalletActionTypes.API_WALLET_RESPONSE_ERROR
    | WalletActionTypes.API_WALLET_RESPONSE_SUCCESS
    | WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST
    | WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING
    | WalletActionTypes.LIST_REDEEM_LIST
    | WalletActionTypes.LIST_REDEEM_LIST_LOADING
    | WalletActionTypes.LIST_PAID_LIST
    | WalletActionTypes.LIST_PAID_LIST_LOADING
    | WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST
    | WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST_LOADING
    | WalletActionTypes.ACCEPT_PAID_LIST
    | WalletActionTypes.WALLET_APPROVAL
    | WalletActionTypes.RESET_WALLET

  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  walletList: any,
  walletLoading: boolean,
  walletCreationLoading: boolean,
  redeemList:any,
  redeemListLoading: boolean,
  paidList: any,
  paidListLoading:boolean,

}

const WalletReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case WalletActionTypes.API_WALLET_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING: {
          return {
            ...state,
            walletLoading: true,
          };
        }
        case WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST: {
          return {
            ...state,
            walletLoading: false,
            walletList: action.payload.data
          };
        }
        case WalletActionTypes.LIST_REDEEM_LIST_LOADING: {
          return {
            ...state,
            redeemListLoading: true,
          };
        }
        case WalletActionTypes.LIST_REDEEM_LIST: {
          return {
            ...state,
            redeemListLoading: false,
            redeemList: action.payload.data
          };
        }
        case WalletActionTypes.LIST_PAID_LIST_LOADING: {
          return {
            ...state,
            redeemListLoading: true,
          };
        }
        case WalletActionTypes.LIST_PAID_LIST: {
          return {
            ...state,
            redeemListLoading: false,
            paidList: action.payload.data
          };
        }
        case WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST_LOADING: {
          return {
            ...state,
            walletCreationLoading: true,
          };
        }
        case WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST: {
          return {
            ...state,
            walletCreationLoading: false,
          };
        }

        case WalletActionTypes.ACCEPT_PAID_LIST: {
          return {
            ...state,
          };
        }
        case WalletActionTypes.WALLET_APPROVAL: {
          return {
            ...state,
          };
        }

        default:
          return { ...state };
      }

    case WalletActionTypes.API_WALLET_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING: {
          return {
            ...state,
            walletLoading: true,
          };
        }
        case WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST: {
          return {
            ...state,
            walletLoading: false,
            walletList: action.payload.data
          };
        }
        case WalletActionTypes.LIST_REDEEM_LIST_LOADING: {
          return {
            ...state,
            redeemListLoading: true,
          };
        }
        case WalletActionTypes.LIST_REDEEM_LIST: {
          return {
            ...state,
            redeemListLoading: false,
            redeemList: action.payload.data
          };
        }
        case WalletActionTypes.LIST_PAID_LIST_LOADING: {
          return {
            ...state,
            redeemListLoading: true,
          };
        }
        case WalletActionTypes.LIST_PAID_LIST: {
          return {
            ...state,
            redeemListLoading: false,
            paidList: action.payload.data
          };
        }
        case WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST_LOADING: {
          return {
            ...state,
            walletCreationLoading: true,
          };
        }
        case WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST: {
          return {
            ...state,
            walletCreationLoading: false,
          };
        }

        case WalletActionTypes.ACCEPT_PAID_LIST: {
          return {
            ...state,
          };
        }
        case WalletActionTypes.WALLET_APPROVAL: {
          return {
            ...state,
          };
        }
        default:
          return { ...state };
      }

    // case WalletActionTypes.DELET_USERS:
    //   return { ...state, loading: true, userLoggedIn: false };
    // case WalletActionTypes.LOGOUT_USER:
    //   return { ...state, loading: true, userLogout: false };
    case WalletActionTypes.RESET_WALLET:
      return {
        ...state,
        selectedUsers: {}
      };
    default:
      return { ...state };
  }
};

export default WalletReducer;
