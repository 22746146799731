import React , { useEffect, useState, memo, useRef }  from "react";
import {
    Card,
    Button,
    Modal,
    Tab,
    Nav,
  } from "react-bootstrap";
import Select from "react-select";
import { useForm , Resolver, Controller, useWatch} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactSelect from '../forms/Advanced'
// components
import { VerticalForm, FormInput } from "../../components/";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { listingUsers } from "../../redux/users/actions";
import { Typeahead } from "react-bootstrap-typeahead";
import { listingOutlets } from "../../redux/outlets/actions";
import { addGroup } from "../../redux/actions";
import Input from "react-select/src/components/Input";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import { useParams } from "react-router-dom";

type FormValues = {
  groupTitle: string ,
  groupMembers:  any[],
  hotel: any,
  outlet: any
}

// const resolver: Resolver<FormValues> = async (values: any) => {
//   return {
//     values: values.groupTitle ? values : {},
//     errors: !values.groupTitle
//       ? {
//         groupTitle: {
//             type: "required",
//             message: "This is required.",
//           },
//         }
//       : {},
   

//   }
// }

const CreateGroup = memo(({responsiveModal,deletGroup,toggleResponsiveModal, editParam, deleteParam, addParam,searchByName}: any) => {
  const { selectedGroups } = useSelector((state: RootState) => 
     state.GroupsReducer,
  );
  const {usersList} = useSelector((state:RootState) => state.UsersReducer)
  const {hotelsList}  = useSelector((state:RootState) => state.HotelsReducer)
  const {outletsList}  = useSelector((state:RootState) => state.OutletsReducer)
  
  const [activekey, setActivekey] = useState("first");
  const dispatch = useDispatch();

  const { page,limit } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({ 
    defaultValues: {outlet:selectedGroups?.outlet?._id, 
      hotel:selectedGroups?.hotel?._id,
       groupMembers:selectedGroups?.groupMembers?._id,
       groupTitle: selectedGroups.groupTitle, }
    })
  useEffect(() => {
    dispatch(listingUsers({userStatus: "VERIFIED"}))
  },[])

  useEffect(() => {
      console.log('the vale hotels', outletsList, hotelsList)
      if(selectedGroups){
        setActivekey(selectedGroups?.groupType === "Private" ? "first" : "second")
      }
      let members= []
      console.log("rhhe selecte" , selectedGroups?.groupMembers?.map((mem: any) => members.push({id: mem?._id, value: mem?.name})),)
      reset({
        outlet: {value: selectedGroups?.outlet?._id, label: selectedGroups?.outlet?.outletName} , 
        hotel:{value: selectedGroups?.hotel?._id, label: selectedGroups?.hotel?.hotelName},
         groupMembers:selectedGroups?.groupMembers?.map((mem: any) => members.push({id: mem?._id, value: mem?.name})),
         groupTitle: selectedGroups.groupTitle,
      })
    },[selectedGroups])

 const optionsList = () => {
  let list: any = [];
  usersList?.data?.map((res: any) => list.push({value: res._id, label: res.name})
  )
  return list;
 }

 const hotelOptionsList = () => {
  let hotel: any = [];
  hotelsList?.data?.map((list: any) => hotel.push({value: list._id, label: list.hotelName}))
  return hotel;
 }

 const OutletOptionsList = () => {
  let outlet: any = [];
  outletsList?.data?.map((list: any) => outlet.push({value: list._id, label: list.outletName}))
  return outlet;
 }

useEffect(() => {
  if(watch('hotel')){
    console.log("the val", getValues())
    // searchByName('hotels', getValues()?.hotel.value)
    dispatch(listingOutlets(OutletsActionTypes.LIST_OUTLETS,{hotel:getValues()?.hotel?.value ? getValues()?.hotel?.value : '' ,page:0,limit:10000}))
  }
//  dispatch(listingOutlets({}))
}, [watch('hotel')])

const onSubmits = (event: any) => {
   event.preventDefault();
  console.log("the events on submit in groups", event.target.values, getValues())
//   {
//     "outlet": "6669c68aa18dc9bc70628eca",
//     "hotel": "6661ef3805b750723236f899",
//     "groupType": activekey === "first" ? "Private" : "Public",
//     "groupTitle": "Private test1",
//     "groupMembers": [
//         "666570484ea192a0620e6628"
//     ]
// }
let members:any = [];
let outlets:any = " ";
let groupTypes;
let hotels:any = " ";
getValues()?.groupMembers?.map((data: any) =>  members.push(data.value))
console.log("the hoe", getValues(), activekey)
groupTypes =  activekey === "first" ? "Private" : "Public"

  if(groupTypes === "Private"){
    dispatch(addGroup(
      {
            outlet: getValues()?.outlet?.value,
            hotel: getValues()?.hotel?.value,
            groupType: activekey === "first" ? "Private" : "Public",
            groupTitle: getValues().groupTitle,
            groupMembers:members
        }
    ))
  }
  else{
    dispatch(addGroup(
      {
            groupType: activekey === "first" ? "Private" : "Public",
            groupTitle: getValues().groupTitle,
            groupMembers:members
        }
    ))
  }
  
  toggleResponsiveModal(false)
}

const CustomModals = () => {
        return (
          <>
  {deleteParam && (
    <Modal show={responsiveModal} onHide={toggleResponsiveModal}>
      <Modal.Body className="p-2">
      <div>
<h3>Are you sure you want to delete the selected group?</h3>
<div className="text-end">
      <Button
      variant="success"
      type="submit"
      className="waves-effect waves-light me-1"
      onClick={() => deletGroup(deleteParam)}
      >
      Save
      </Button>
      <Button
      variant="danger"
      className="waves-effect waves-light"
      onClick={toggleResponsiveModal}
      >
      Close
      </Button>
      </div>
        </div>
                </Modal.Body>
              </Modal>
       
  )}
  {(editParam || addParam )&&  (
    
    <Modal show={responsiveModal} onHide={toggleResponsiveModal}>
    <Modal.Body className="p-2">
      
    <Tab.Container id="left-tabs-example" defaultActiveKey={activekey}>
      <Nav
        as="ul"
        variant="tabs"
        className="nav nav-tabs nav-bordered"
      >
        <Nav.Item as="li" >
          <Nav.Link eventKey="first" className="cursor-pointer">
            Private Group
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link eventKey="second" className="cursor-pointer">
            Public Group
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
      <Tab.Pane eventKey="first">
          <form onSubmit={onSubmits} >
              <div className="mb-3">
              <Controller
              name="groupTitle"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                      <FormInput
              label="Group Name"
              type="text"
              name="groupTitle"
              control={control}
              register={register}
              placeholder="Enter group name"
              containerClass={"mb-3"}
              />
                          )}
                  />
              </div>
              <div className="mb-3">
              <Form.Label>Select Users</Form.Label>
              <Controller
                    name="groupMembers"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select  
                      {...field} isMulti 
                      options={optionsList()} />
                    )}
                  />
              </div>
              <div className="mb-3">
              <Form.Label>Select hotel</Form.Label>
              <Controller
                    name="hotel"
                    control={control}
                    rules={{ required: true }}
                    
                    render={({ field}) => (
                      <Select 
                      {...field}
                      isMulti={false} 
                      register={register}
                      // onChange={(e) => searchByName('hotels', getValues()?.hotel.value)}
                      options={hotelOptionsList()} />
                    )}
                  />  

              </div>
              <div className="mb-3">
              <Form.Label>Select Outlet</Form.Label>
                <Controller
                  name="outlet"
                  control={control}
                  defaultValue={selectedGroups?.outlet?._id}
                  // rules={{ required: true }}
                  render={({ field}) => (
                    <Select 
                    {...field}
                    isMulti={false} 
                    options={OutletOptionsList()} 
                    />
                  )}
                />
              </div>
              <div className="text-end">
              <Button
              variant="success"
              type="submit"
              className="waves-effect waves-light me-1"
              >
              Save
              </Button>
              <Button
              variant="danger"
              className="waves-effect waves-light"
              onClick={toggleResponsiveModal}
              >
              Continue
              </Button>
              </div>
          </form>
      </Tab.Pane>
      <Tab.Pane eventKey="second">
        <form onSubmit={onSubmits} >

        <div className="mb-3">
             
              <Controller
              name="groupTitle"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
              <FormInput
              label="Group Name"
              type="text"
              name="groupTitle"
              placeholder="Enter group name"
              containerClass={"mb-3"}
              register={register}
              />
              )}
              />
              </div>
              {/* <FormInput
              label="Group Name"
              type="text"
              name="groupTitle"
              placeholder="Enter group name"
              containerClass={"mb-3"}
              register={register}
              /> */}

              <div className="mb-3">
              <Form.Label>Select Users</Form.Label>
              <Controller
              name="groupMembers"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
              <Select  
              {...field} isMulti options={optionsList()} />
              )}
              />
              </div>


              <div className="text-end">
              <Button
              variant="success"
              type="submit"
              className="waves-effect waves-light me-1"
              >
              Save
              </Button>
              <Button
              variant="danger"
              className="waves-effect waves-light"
              onClick={toggleResponsiveModal}
              >
              Continue
              </Button>
              </div>
        </form>
      </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
     </Modal.Body>   
         </Modal>
      )}
          </>
        );
      };
      return(
        <CustomModals/>
      )
});

export default CreateGroup;