export enum AttendanceActionTypes {
  API_ATTENDANCES_RESPONSE_SUCCESS = "@@attendance/API_ATTENDANCES_RESPONSE_SUCCESS",
  API_ATTENDANCES_RESPONSE_ERROR = "@@attendance/API_ATTENDANCES_RESPONSE_ERROR",

  LIST_ATTENDANCES = "@@attendance/LIST_ATTENDANCES",
  GET_ATTENDANCES = "@@attendance/GET_ATTENDANCES",
  DELET_ATTENDANCES = "@@attendance/DELETE_ATTENDANCES",
  EDIT_ATTENDANCES_SELECTED = "@@attendance/EDIT_ATTENDANCES_SELECTED",
  LOCK_ATTENDANCE = "@@attendance/LOCK_ATTENDANCE",
  UNLOCK_ATTENDANCE = "@@attendance/UNLOCK_ATTENDANCE",
  SEND_ATTENDANCE = "@@attendance/SEND_ATTENDANCE",
  GENETRATE_ATTENDANCE = "@@attendance/GENETRATE_ATTENDANCE",
  GET_ATTENDANCE_BY_ID="@@attendance/GET_ATTENDANCE_BY_ID",

  LIST_ATTENDANCES_LOADING = "@@attendance/LIST_ATTENDANCES_LOADING",
  EDIT_ATTENDANCES_SELECTED_LOADING = "@@attendance/EDIT_ATTENDANCES_SELECTED_LOADING",
  LOCK_ATTENDANCE_LOADING = "@@attendance/LOCK_ATTENDANCE_LOADING",
  SEND_ATTENDANCE_LOADING = "@@attendance/SEND_ATTENDANCE_LOADING",
  GENERATE_ATTENDANCE_LOADING = "@@attendance/GENERATE_ATTENDANCE_LOADING",
  GENERATE_ATTENDANCE_STOP_LOADING ="@@attendance/GENERATE_ATTENDANCE_STOP_LOADING",

  RESET_ATTENDANCES = "@@attendance/RESET_ATTENDANCES",
}
