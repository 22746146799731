import { APICore } from "./apiCore";
import axios from "axios";

const api = new APICore();

// account
function listoutlets(payload: any) {
  const baseUrl = "/outlet/list";
  console.log('theparams outlets',payload)
  // return axios.get(`${baseUrl}`, params);
  console.log('theparams',payload)
  const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
  return api.get(`${baseUrl}?${params}`)
  // return api.get(`${baseUrl}?hotel=${params.hotel}&page=${params?.page}&limit=${params?.limit}`, {});
}


function listoutletsByHotel(params: any) {
  const baseUrl = "/outlet/list";
  console.log('theparams outlets',params)
  // return axios.get(`${baseUrl}`, params);
  if(params.hotelName && params.name){
    return api.get(`${baseUrl}?name=${params.name}&hotel=${params?.hotelName}&page=${params?.page}&limit=${params?.limit}`, {});
  }
  if(params.name){
    return api.get(`${baseUrl}?name=${params.name}&page=${params?.page}&limit=${params?.limit}`, {});
  }
  if(params.hotelName){
    return api.get(`${baseUrl}?hotel=${params?.hotelName}&page=${params?.page}&limit=${params?.limit}`, {});
  }
  if(!params.name && !params.hotelName){
    return api.get(`${baseUrl}?page=${params?.page}&limit=${params?.limit}`, {});
 
  }
 }

 function addOutlets(params: any) {
  const baseUrl = "/outlet/add";
  const AUTH_SESSION_KEY = "logged_user";
  // return axios.get(`${baseUrl}`, params);
  const config: any = {
    headers: {
      ...axios.defaults.headers,
      'token': `${localStorage.getItem(AUTH_SESSION_KEY)}`,
      "content-type": "multipart/form-data",
    },
    params,
  };
  // return api.create(`${baseUrl}`, params);
  return axios.post(baseUrl,params,config );
}

function EditOutlets(params: any) {
  const baseUrl = "outlet/edit";
  const AUTH_SESSION_KEY = "logged_user";
  // return axios.get(`${baseUrl}`, params);
  const config: any = {
    headers: {
      ...axios.defaults.headers,
      'token': `${localStorage.getItem(AUTH_SESSION_KEY)}`,
      "content-type": "multipart/form-data",
    },
    params,
  };
  // return api.create(`${baseUrl}`, params);
  return axios.patch(baseUrl,params,config );
}
function listoutletsById(id: any) {
  const baseUrl = "outlet/getOutlet";
  return api.get(`${baseUrl}/${id}`,{} );
}
function deleteoutlets(id: string) {
  console.log("the id", id)
  const baseUrl = `/outlet/delete?hotelId=${id}`;
  return api.updatePatch(`${baseUrl}`,{});
}

function createOutletUsers(body: any){
  const baseUrl = `outletuser/add`
  return api.create(`${baseUrl}`, body)
}

function addExtraColumn(columnName: any){
  const baseUrl = `outlet/extraColumns`
  return api.create(`${baseUrl}?columnName=${columnName}`,{})
}
function getExtraColumns(){
  const baseUrl = `outlet/extraColumns`
  return api.get(`${baseUrl}`)
}



export { addOutlets,addExtraColumn,getExtraColumns,createOutletUsers,EditOutlets, listoutlets,listoutletsById, deleteoutlets, listoutletsByHotel};
