// import React from 'react';
import {  Row, Col, Button } from "react-bootstrap";
import { FormInput } from '../components';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';

const CommonSearchBar = ({searchTitle,filterValueChanged,search,filters,openAddModal,name,searchByName, defaultoptionValue}: any) => {
    console.log('the issue is reaching in the search bar', filters)
    const methods = useForm({
        defaultValues: {
            userStatus: defaultoptionValue,
            status: defaultoptionValue
        },
      });
    const {
        register,
        control,
        formState: { errors },
      } = methods;
   const onFilterChange = (name: any, e: any) => filterValueChanged(name, e)

   const defaultValue = (options:any,id:any) => {
   const filters =  options?.filter((op:any) => op.value === id)
   console.log("the filrers defaulr", filters[0]?.label)
   return filters[0]?.label

   }
    return(
        <>
        <Row>
            {searchTitle && <Col md={3} style={{marginBottom:'10px'}}>
            <input
                type="search"
                className="form-control "
                placeholder={(name === "all") ? `${searchTitle}`: undefined}
                id="top-search"
                onChange={search}
                defaultValue={name !== "all" ? name : null}
                // value={name !== "all" ? name : ``}
            //   onBlur={hideSearchOptions}
            />

            </Col>}
            {filters?.length === 0 && (
                <Col md={6}>
                    <div>{" "}</div>
                </Col>
            )}
            {filters?.map((filter:any, index: any) => {
                if(filter.isSelect){
                    return(
                        <Col md={filters?.length > 0 ? 6/filters?.length : 6}>
                        <Typeahead
                        className=" rounded-pill"
                        
                        // defaultInputValue="Choose an option"
                        defaultInputValue={filter.value}
                        id="basic-typeahead-single"
                        multiple={false}
                        onInputChange={(e) =>  searchByName(filter.name, e)}
                        options={(filter.options)}
                        placeholder={filter.placeholder}
                        onChange={(e) => onFilterChange(filter.name,e)}
                        />
                      </Col>
                )
                }
                else{
               return (
                <Col md={filters?.length > 0 ? 6/filters?.length : 6}>
                <form>
                <FormInput
                    name={filter.name}
                    className="form-control "
                    type="select"
                    key={filter.name}
                    placeholder={filter?.placeholder}
                    containerClass={"mb-3"}
                    register={register}
                    errors={errors}
                    control={control}
                    onChange={(e) => 
                      {
                        
                        searchByName(filter.name, e.target.value)
                      }
                    }
                        >
                    <option value="">{filter.placeholder}</option>
                    {filter.options.map((item:any) => (
                       <>
                        <option  value={`${item.value}`}>{item.label}</option>
                       </>
                    ))}
                </FormInput>
                </form>
                </Col> 
                ) 
                }
                
                  
              
            })}
          {openAddModal &&  (<Col md={!filters ? 9 : 3}>
                <Button
                style={{float:'right'}}
                variant="success"
                className="waves-effect waves-light"
                onClick={openAddModal}
                >
                <i className="mdi mdi-plus-circle me-1"></i> Add New
                </Button>
            </Col>)}
          
        </Row>
      
     
        </>
    )
}
export default  CommonSearchBar;