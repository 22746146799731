import { useState } from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useFieldArray, useForm } from "react-hook-form";

const CreateInvoiceModal = ({
  createInvoiceModal,
  toggleResponsiveModalForCreateInvoice,
  submitNewInvoice,
  filterValueChangeByHotelsOrOutlets,
  hotelsList,
  outletsList,
  searchByName,
  
}: any) => {
  const [selectedHotel, setSelectedHotel] = useState();
    const methodsForUser = useForm({
    defaultValues: {
      hotel: "",
      outlet: "",
      invoiceUid:"",
      invoiceDate:"",
      invoiceType:"",
      reason:""
    },
  });
  const {
    handleSubmit: handleSubmitForNewInvoices,
    register: register,
    control: controlUsers,
    getValues: getNewInvoiceValues,
    reset: resetNewInvoiceValues,
    formState: { errors: errors2 },
  } = methodsForUser;

  const methods = useForm({
    defaultValues: {
      invoiceItems : [
        {
          description: "",
          totalAmount: ""
        },
      ],
    },
  });
  const {
    handleSubmit,
    register:register2,
    control,
    getValues:getInvoiceItems,
    reset,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "invoiceItems",
  });

  return (
    <>
      <Modal
        show={createInvoiceModal}
        onHide={toggleResponsiveModalForCreateInvoice}
      >
        <Modal.Body className="p-2">
          <form
            onSubmit={handleSubmitForNewInvoices(() =>
              submitNewInvoice(getNewInvoiceValues(), selectedHotel, getInvoiceItems())
            )}
          >
            
             <Controller
              control={controlUsers}
              name="invoiceUid"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  Invoice UID
                </label>
                  <Form.Control
                      type="text"
                      placeholder="Invoice UID"
                      {...register(
                        `invoiceUid`
                      )}
                       />
                </div>
              )}
            />
            <Controller
              control={controlUsers}
              name="hotel"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                  <label htmlFor="typeahead" className="form-label">
                    Hotel
                  </label>
                  <Typeahead
                    {...field}
                    register={register}
                    id="basic-typeahead-single"
                    multiple={false}
                    onInputChange={(e: any) => {
                      searchByName("hotels", e, selectedHotel?  selectedHotel :'')
                    }}
                    onChange={(e: any) => {
                      setSelectedHotel(e[0]?.value)
                      filterValueChangeByHotelsOrOutlets("hotels", e);
                    }}
                    options={hotelsList}
                    placeholder="Hotel"
                  />
                </div>
              )}
            />
            <Controller
              control={controlUsers}
              name="outlet"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                  <label htmlFor="typeahead" className="form-label">
                    Outlets
                  </label>
                  <Typeahead
                    {...field}
                    register={register}
                    id="basic-typeahead-single"
                    multiple={false}
                    onInputChange={(e: any) => {
                      searchByName("outlets", e)
                    }}
                    options={outletsList}
                    placeholder="Outlet"
                  />
                </div>
              )}
            />
            <Controller
              control={controlUsers}
              name="invoiceDate"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  Invoice Date
                </label>
                  <Form.Control
                      type="date"
                      placeholder="Remarks"
                      {...register(
                        `invoiceDate`
                      )}
                       />
                </div>
              )}
            />
            <Controller
              control={controlUsers}
              name="reason"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  Reason
                </label>
                  <Form.Control
                      type="text"
                      placeholder="Reason"
                      {...register(
                        `reason`
                      )}
                       />
                </div>
              )}
            />
             <Controller
              control={controlUsers}
              name="invoiceType"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  Invoice Type
                </label>
                <Typeahead
                        {...field}
                        id="basic-typeahead-single"
                        multiple={false}
                        // onInputChange={(e) => searchByName('name', e)}
                        options={[
                          { id: 1, value: "Invoice", label: "Invoice" },
                          { id: 2, value: "Credit Note", label: "Credit Note" },
                          { id: 3, value: "Debit Note", label: "Debit Note" },
                        ]}
                        placeholder="Invoice Type"
                      />
                </div>
              )}
            />
            

              {fields.map((field, index) => (
          <>
           <Controller
                  control={control}
                  name={`invoiceItems.${index}.description`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Description
                      </label>
                      <Form.Control
                    type="text"
                    placeholder="Description"
                  {...register2(`invoiceItems.${index}.description`)}
                 />
                    </div>
                  )}
                />
            <Controller
                  control={control}
                  name={`invoiceItems.${index}.totalAmount`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Total Amount
                      </label>
                      <Form.Control
                    type="text"
                    placeholder="Total Amount"
                  {...register2(`invoiceItems.${index}.totalAmount`)}
                 />
                    </div>
                  )}
                />
                <Row>
              <div className="btn-box" style={{ display: "flex", justifyContent: "space-around" , marginBottom:'5%'}}>
                {fields.length !== 1 && (
                  <Button className="mr10" onClick={() => remove(index)}>
                    Remove Invoice Items
                  </Button>
                )}
                {fields.length - 1 === index && (
                  <Button
                    onClick={() =>
                      append({
                        description:"",
                        totalAmount:""
                      })
                    }
                  >
                    Add Invoice Items
                  </Button>
                )}
              </div>
            </Row>
          </>
              ))}
             
            <div className="text-end">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
              >
                Save
              </Button>
              <Button
                variant="danger"
                className="waves-effect waves-light"
                onClick={
                  toggleResponsiveModalForCreateInvoice
                }
              >
                Close
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateInvoiceModal;
