import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { InvoiceById, splitInvoice, splitInvoiceLoading as splitInvoiceLoadingasAction } from "../../redux/invoice/actions";
import { RootState } from "../../redux/store";
import Loader from "../../components/Loader";
import { Button, Card, Row } from "react-bootstrap";
import { InvoiceDetailsListTableColumns } from "./data";
import Table from "../../components/Table";

const InvoiceDetails = () => {
  // states

  let selectedInvoicesForSplit: any = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { invoiceByIdLoading, invoiceById, splitInvoiceLoading } = useSelector(
    (state: RootState) => state.InvoiceReducer
  );
  const [selectedInvoiceForsplit, setSelectedInoviceSplit] = useState<any>([])
  // initial loading
  useEffect(() => {
    dispatch(InvoiceById(id));
  }, []);

  const columns = [
    {
      Header: "",
      accessor: "splitID",
      sort: false,
    },
    {
      Header: "description",
      accessor: "description",
      sort: false,
    },
    {
      Header: "Hr/Pay",
      accessor: "hourlyPay",
      sort: false,
    },
    {
      Header: "No.of.Hours",
      accessor: "totalHours",
      sort: false,
    },
    {
      Header: "Amount",
      accessor: "totalAmount",
      sort: false,
    },
  ];
  // for split
  const saveSelectedInvoicesForSplit = (e: any, details: any) => {
    console.log("the splot details", e, details);
    
    if (e.target.checked) {
      setSelectedInoviceSplit((prev: any) => [...prev, details._id]);
    } else {
      const index = selectedInvoicesForSplit?.findIndex(
        (merging: any) => merging === details._id
      );
      setSelectedInoviceSplit((prevState: any) => [
        ...prevState,
        prevState.splice(index, 1),
      ]);
     
    }
  };



  const splitInvoices = () => {
   
      dispatch(splitInvoiceLoadingasAction({}));
      dispatch(
        splitInvoice({
          id:id,
          payload: {newInoices:selectedInvoiceForsplit}
        })
      );
    
  };

  return (
    <>
      {invoiceByIdLoading && <Loader />}
      {!invoiceByIdLoading && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h1>INVOICE</h1>
              <div>{invoiceById[0]?.invoiceUID}</div>
              <div>{invoiceById[0]?.invoiceDate}</div>
              <div>{invoiceById[0]?.status}</div>
            </div>
            <span
              style={{ fontSize: "xx-large", cursor:'pointer' }}
              className="bi bi-close"
              onClick={() => navigate(-1)}
            >
              x
            </span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3%",
              marginBottom: "2%",
            }}
          >
            <div>
              <Button
                style={{ float: "right" }}
                variant="success"
                className="waves-effect waves-light"
                onClick={splitInvoices}
              >
                {splitInvoiceLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                    <span>Split Invoice</span>
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-plus-circle me-1"></i> Split Invoice
                  </>
                )}
              </Button>
            </div>
          </div>
          <Card>
            <Card.Body>
              <Row>
                <Table
                  columns={columns}
                  data={InvoiceDetailsListTableColumns(
                    invoiceById,
                    saveSelectedInvoicesForSplit
                  )}
                  isSortable={true}
                  pagination={false}
                />
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
export default InvoiceDetails;
