import { Routes, Route } from "react-router-dom"
import { searchFilterForGroups } from "./data"
import SlotListingPage from "./SlotsListingPage"
import AddSlotsPage from "./addSlotsPage"
import EditSlotDetails from "./editSlotsDetails"


const Slots = () => {
  
        return(
            <>
            <Routes>
              <Route path="/" element={<SlotListingPage />} />
              <Route 
              path={`/:${searchFilterForGroups[0].key}/:${searchFilterForGroups[1].key}/:${searchFilterForGroups[2].key}/:${searchFilterForGroups[3].key}/:${searchFilterForGroups[4].key}`}
              element={<SlotListingPage />} />
               <Route 
          path={`?startDate=`}
          element={<SlotListingPage />} />
          <Route 
          path={`?endDate=`}
          element={<SlotListingPage />} />
              <Route 
              path={`add`}
              element={<AddSlotsPage />} />
              <Route 
              path={`edit/:id`}
              element={<EditSlotDetails />} />
               <Route 
              path={`delete/:id`}
              element={<AddSlotsPage />} />
            </Routes>
            </>
        )

}

export default Slots