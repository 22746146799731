import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import Select from 'react-select';
import BasicForms from "../forms/Basic";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { RootState } from "../../redux/store";
import { FormInput } from "../../components";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { listingHotels } from "../../redux/hotels/actions";
import {
  addOutlets,
  createOutletUsers,
  editSelectedOutlet,
  editSelectedOutlets,
  getColumns,
  listingOutlets,
  listingOutletsByhotels,
} from "../../redux/outlets/actions";
import FileUpload from "../forms/FileUpload";
import axios from "axios";
import _ from "lodash";
import { APICore } from "../../helpers/api/apiCore";

const HotelOutLetEditForm = () => {
  const { selectedUsers, colleges } = useSelector(
    (state: RootState) => state.UsersReducer
  );
  const { hotelsList, page, limit, selectedHotels } = useSelector(
    (state: RootState) => state.HotelsReducer
  );
  const { outletsList, selectedOutlets, columnsList } = useSelector(
    (state: RootState) => state.OutletsReducer
  );

  const user: any = localStorage.getItem("userLoggedIn");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [responsiveModal, setResponsiveModal] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  console.log("te ud", id);
  useEffect(() => {
    if (id) {
      dispatch(editSelectedOutlets({ id: id }));
      dispatch(listingHotels({ page: 1, limit: 1000 }));
    } else {
      dispatch(listingHotels({ page: 1, limit: 1000 }));
    }
  }, []);
  console.log("the list", columnsList);
  useEffect(() => {
    dispatch(getColumns());
    if (selectedOutlets) {
      reset({
        outletName: selectedOutlets?.outletName,
        hotel: selectedOutlets?.hotelId,
        Abbreviation: selectedOutlets?.Abbreviation,
        billingAddress: selectedOutlets?.billingAddress,
        attendanceEmail: selectedOutlets?.attendanceEmail,
        invoiceEmail: selectedOutlets?.invoiceEmail,
        outletAdminNo: selectedOutlets?.outletAdminNo,
        startingSerialNumber: selectedOutlets?.startingSerialNumber,
        youtubeLink: selectedOutlets?.youtubeLink,
        sunday: selectedOutlets?.sunday,
        monday: selectedOutlets?.monday,
        tuesday: selectedOutlets?.tuesday,
        wednesday: selectedOutlets?.wednesday,
        thursday: selectedOutlets?.thursday,
        friday: selectedOutlets?.friday,
        saturday: selectedOutlets?.saturday,
        jobRemarks: selectedOutlets?.jobRemarks,
        phRate: selectedOutlets?.phRate,
        specialRate: selectedOutlets?.specialRate,
      });
      if (!_.isEmpty(selectedOutlets?.groomingImages)) {
        const list = selectedOutlets?.groomingImages?.map((images: any) => {
          return OutletImageReRendering(
            "Grooming Image",
            `https://jobs.doingbydo.com/api/user/images/${images}`,
            "setgroomingImagesFile"
          );
        });
        console.log("the lsist fo the images", list);
      }
      if (!_.isEmpty(selectedOutlets?.howToImages)) {
        selectedOutlets?.howToImages?.map((images: any) => {
          return OutletImageReRendering(
            "Navigation Image",
            `https://jobs.doingbydo.com/api/user/images/${images}`,
            "sethowToImages"
          );
        });
      }
      if (!_.isEmpty(selectedOutlets?.outletImages)) {
        selectedOutlets?.outletImages?.map((images: any) => {
          return OutletImageReRendering(
            "Outlet Image",
            `https://jobs.doingbydo.com/api/user/images/${images}`,
            "setoutletImages"
          );
        });
      }
    }
    const arra:any[] =[]
    // const intialOptions:any[] =[]
    // columnsList?.map((list: any, index: any) =>
    //   intialOptions.push({
    //     id: index,
    //     value: list._id,
    //     label: list.columnName ? list.columnName : " ",
    //   })
    // )
    // setOptions(intialOptions)
    const resultArr  =  selectedOutlets?.invoiceColumns?.map((col:any, index:any) =>{
    
        arra.push({columns:[
          { id: index,
           value: col._id,
           label: col.columnName ? col.columnName : " ",}
         ]})
         console.log("te arra o/p in resrXol",arra )
        return arra;
      }
        )
    
        console.log("te arra o/p in resrXol",resultArr )
    resetCol({
      extraInvoiceColumns: arra
    })
    console.log("te col", getValuesCol())
   
  }, [selectedOutlets]);

  console.log("the outlets", selectedOutlets);
  const methods = useForm({
    defaultValues: {
      outletName: "",
      hotel: "",
      Abbreviation: "",
      billingAddress: "",
      attendanceEmail: "",
      invoiceEmail: "",
      outletAdminNo: "",
      startingSerialNumber: "",
      youtubeLink: "",
      sunday: "10",
      monday: "10",
      tuesday: "10",
      wednesday: "10",
      thursday: "10",
      friday: "10",
      saturday: "10",
      jobRemarks: "",
      phRate: "",
      specialRate: "",
     
    },
  });

  


  const methodsForUser = useForm({
    defaultValues: {
      fullname: "",
      username: "",
      password: "",
    },
  });
  const {
    handleSubmit: handleSubmitForUsers,
    register: register2,
    control: controlUsers,
    getValues: getUsersValues,
    reset: resetUsersValues,
    formState: { errors: errors2 },
  } = methodsForUser;

  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  
  
  const methodsForCol = useForm({
    defaultValues: {
      extraInvoiceColumns: [
        {
          columns: [],
        },
      ],
    },
  });
  const {
    handleSubmit:handleSubmitForCol,
    register:registerCol,
    control:controleCol,
    getValues: getValuesCol,
    reset: resetCol,
    formState: { errors: errorsCol },
  } = methodsForCol;
  const { fields, append, remove } = useFieldArray({
    control:controleCol,
    name: "extraInvoiceColumns",
  });

  const [groomingImages, setgroomingImagesFile] = useState<any[]>([]);
  const [howToImages, sethowToImages] = useState<any[]>([]);
  const [outletImages, setoutletImages] = useState<any[]>([]);
  const OutletImageReRendering = (name: any, url: any, type: any) => {
    axios
      .get(url)
      .then((response) => new Blob([response.data]))
      .then((blob) => {
        const f = new File([blob], name);
        if (type === "setgroomingImagesFile") {
          debugger;
          setgroomingImagesFile([
            ...groomingImages,
            Object.assign(f, { preview: url }),
          ]);
        }
        if (type === "setoutletImages") {
          debugger;
          setoutletImages([
            ...outletImages,
            Object.assign(f, { preview: url }),
          ]);
        }
        if (type === "sethowToImages") {
          debugger;
          sethowToImages([...howToImages, Object.assign(f, { preview: url })]);
        }
      });
  };
  const addOutletUsers = (details: any) => {
    dispatch(createOutletUsers({ outlet: id, ...details }));
    setResponsiveModal(!responsiveModal);
  };
  const api = new APICore();
  const BasicInputElements = () => {
    const goBack = () => navigate(-1);
    const formData = new FormData();
    const saveUsersDetails = async (values: any) => {
      let columnsListArray:any =[];
      

      await getValuesCol()?.extraInvoiceColumns?.map(async (col:any) => {
        columnsListArray.push(col?.columns?.value)
        console.log("te a", columnsListArray,getValuesCol()?.extraInvoiceColumns?.length)
       
      })

   
    

          formData.append(
          "data",
          JSON.stringify({
            extraInvoiceColumns:columnsListArray,
            outletName: values.outletName,
            hotel: values.hotel[0]?.value,
            Abbreviation: values.Abbreviation,
            billingAddress: values.billingAddress,
            attendanceEmail: values.attendanceEmail,
            invoiceEmail: values.invoiceEmail,
            outletAdminNo: values.outletAdminNo,
            startingSerialNumber: values.startingSerialNumber,
            youtubeLink: values.youtubeLink,
            sunday: values.sunday,
            monday: values.monday,
            tuesday: values.tuesday,
            wednesday: values.wednesday,
            thursday: values.thursday,
            friday: values.friday,
            saturday: values.saturday,
            jobRemarks: values.jobRemarks,
            phRate: values.phRate,
            specialRate: values.specialRate,
          })
        );
    
    
    
      
     
      if (id) {
        formData.append("outletId", id);
        await dispatch(editSelectedOutlet(formData));
      } else {
        await dispatch(addOutlets(formData));
      }

      setgroomingImagesFile([]);
      sethowToImages([]);
      setoutletImages([]);
      // goBack();
    };
    const hotelOptionsListForSearch = (data: any) => {
      let hotel: any = [];
      data?.data?.map((list: any, index: any) =>
        hotel.push({
          id: index,
          value: list._id,
          label: list.hotelName ? list.hotelName : " ",
        })
      );
      return hotel;
    };

    const columsnListForSearch = (data: any) => {
      let columns: any = [{ value: 'new', label: 'Add New...' }];
      console.log("thedata", data);
      data?.map((list: any, index: any) =>
        columns.push({
          id: index,
          value: list._id,
          label: list.columnName ? list.columnName : " ",
        })
      );
      
      return columns;
    };
    

    const searchByName = (keyname: any, e: any) => {
      console.log("te keyname", e, keyname);
      // dispatch(listingHotels({name: e}))
      dispatch(listingOutletsByhotels({ name: e }));
    };

    const uploadFiles = (files: any, name: any) => {
      console.log("te files", files[0]);
      formData.append(name, files[0]);
    };

    console.log("the grooi", selectedOutlets?.monday, getValues());

    return (
      <>
        <Card>
          <Card.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "x-large",
                cursor: "pointer",
              }}
              className="mb-3"
            >
              <h4>Outlet Information</h4>
              <div onClick={() => goBack()}>
                <i className="bi bi-x"></i>
              </div>
            </div>
            <form onSubmit={handleSubmit(() => saveUsersDetails(getValues()))}>
              <Row>
                <Col md={3}>
                  <Controller
                    control={control}
                    name="hotel"
                    rules={{
                      required: "Please, select at least one hotel",
                    }}
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Hotel Name
                        </label>
                        <Typeahead
                          {...field}
                          id="basic-typeahead-single"
                          multiple={false}
                          register={register}
                          // onInputChange={(e) => searchByName('name', e)}
                          options={hotelOptionsListForSearch(hotelsList)}
                          placeholder="Hotel Name"
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Outlet Name"
                    type="text"
                    name="outletName"
                    placeholder="Outlet Name"
                    containerClass={"mb-3"}
                    register={register}
                    key="outletName"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    name="Abbreviation"
                    label="Abbreviation"
                    type="text"
                    placeholder="Abbreviation"
                    containerClass="mb-3"
                    register={register}
                    key="Abbreviation"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Billing Address"
                    type="text"
                    name="billingAddress"
                    placeholder="Billing Address"
                    containerClass={"mb-3"}
                    register={register}
                    key="billingAddress"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormInput
                    label="Attendance Email"
                    type="text"
                    name="attendanceEmail"
                    placeholder="Phone"
                    containerClass={"mb-3"}
                    register={register}
                    key="attendanceEmail"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Invoice Email"
                    type="text"
                    name="invoiceEmail"
                    placeholder="Inovice Email"
                    containerClass={"mb-3"}
                    register={register}
                    key="invoiceEmail"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Outlet Admin Number"
                    type="text"
                    name="outletAdminNo"
                    placeholder="Admin Number"
                    containerClass={"mb-3"}
                    register={register}
                    key="outletAdminNo"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="Starting Serial Number"
                    type="text"
                    name="startingSerialNumber"
                    placeholder="Starting Serial Number"
                    containerClass={"mb-3"}
                    register={register}
                    key="startingSerialNumber"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormInput
                    label="Youtube Link"
                    type="text"
                    name="youtubeLink"
                    placeholder="Youtube Link"
                    containerClass={"mb-3"}
                    register={register}
                    key="youtubeLink"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="PH (Public Hotels) Rate"
                    type="text"
                    name="phRate"
                    placeholder="PH (Public Hotels) Rate"
                    containerClass={"mb-3"}
                    register={register}
                    key="phRate"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={3}>
                  <FormInput
                    label="OC (Holiday) Rate"
                    type="text"
                    name="specialRate"
                    placeholder="OC (Holiday) Rate"
                    containerClass={"mb-3"}
                    register={register}
                    key="specialRate"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={9}>
                  <FormInput
                    label="Job remarks"
                    type="textarea"
                    name="jobRemarks"
                    placeholder="Job remarks"
                    containerClass={"mb-3"}
                    register={register}
                    key="jobRemarks"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Navigation Images</label>
                  <FileUpload
                    name={"howToImages"}
                    files={howToImages}
                    uploadFiles={uploadFiles}
                  />
                </Col>
                <Col md={4}>
                  <label>Groomings Images</label>
                  <FileUpload
                    name={"groomingImages"}
                    files={groomingImages}
                    uploadFiles={uploadFiles}
                  />
                </Col>
                <Col md={4}>
                  <label>Outlet Images</label>
                  <FileUpload
                    name={"outletImages"}
                    files={outletImages}
                    uploadFiles={uploadFiles}
                  />
                </Col>
              </Row>
              <h1>Hourly Rates ($)</h1>
              <Row>
                <Col md={1}>
                  <FormInput
                    label="Monday"
                    type="text"
                    name="monday"
                    placeholder="Monday"
                    containerClass={"mb-3"}
                    register={register}
                    key="monday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Tuesday"
                    type="text"
                    name="tuesday"
                    placeholder="Tuesday"
                    containerClass={"mb-3"}
                    register={register}
                    key="tuesday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Wednesday"
                    type="text"
                    name="wednesday"
                    placeholder="Wednesday"
                    containerClass={"mb-3"}
                    register={register}
                    key="wednesday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Thursday"
                    type="text"
                    name="thursday"
                    placeholder="Thursday"
                    containerClass={"mb-3"}
                    register={register}
                    key="thursday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Friday"
                    type="text"
                    name="friday"
                    placeholder="Friday"
                    containerClass={"mb-3"}
                    register={register}
                    key="friday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Saturday"
                    type="text"
                    name="saturday"
                    placeholder="Saturday"
                    containerClass={"mb-3"}
                    register={register}
                    key="saturday"
                    errors={errors}
                    control={control}
                  />
                </Col>
                <Col md={1}>
                  <FormInput
                    label="Sunday"
                    type="text"
                    name="sunday"
                    placeholder="Sunday"
                    containerClass={"mb-3"}
                    register={register}
                    key="sunday"
                    errors={errors}
                    control={control}
                  />
                </Col>
              </Row>
                   
      {fields.map((field, index) => (
        <div key={field.id} className="mb-3">
          <Controller
            control={controleCol}
            name={`extraInvoiceColumns.${index}.columns`}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
              options={columsnListForSearch(columnsList)}
                onChange={(selectedOption:any) =>{
                if (selectedOption?.value === 'new') {
                  const newLabel = window.prompt('Enter new option:');
                  if (newLabel) {
                    
                    const baseUrl = `outlet/extraColumns`
                    api.create(`${baseUrl}?columnName=${newLabel}`,{}).then((response:any) =>
                    {
                      onChange({ value: response?.data?.result?._id, label: newLabel });
                    }
                    )
                  }
                } else {
                  onChange(selectedOption);
                }
              }}
                onBlur={onBlur}
                value={value}
                isClearable
                placeholder="Select an option"
              />
            )}
            
          />
          <button type="button" className="btn btn-danger mt-2" onClick={() => remove(index)}>
            Remove
          </button>
        </div>
      ))}
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => append({ columns: [] })}
      >
        Add More Invoice Columns
      </button>
      <button type="button"
        className="btn btn-primary" style={{marginLeft:'2%'}}>
        Create New Invoice Column Option
      </button>
 
              <Row>
                <Col style={{ display: "flex", justifyContent: "end" }}>
                  <Button type="submit">Save User Details</Button>
                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
      </>
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {id ? <h1>Edit Outlet</h1> : <h1>Add Outlet</h1>}
        <div>
          {id && (
            <Button
              style={{ float: "right" }}
              variant="success"
              className="waves-effect waves-light"
              onClick={() => setResponsiveModal(true)}
            >
              <i className="mdi mdi-plus-circle me-1"></i> Add New User
            </Button>
          )}
        </div>
      </div>
      <>
        <Card>
          <Card.Body>
            <BasicInputElements />
          </Card.Body>
        </Card>
        <Modal show={responsiveModal} onHide={() => setResponsiveModal(false)}>
          <Modal.Body className="p-2">
            {
              <>
                <form onSubmit={handleSubmitForUsers(addOutletUsers)}>
                  <FormInput
                    label="Full Name"
                    type="text"
                    name="fullname"
                    placeholder="Enter name"
                    containerClass={"mb-3"}
                    register={register2}
                  />
                  <FormInput
                    label="User Name"
                    type="text"
                    name="username"
                    placeholder="Enter user name"
                    containerClass={"mb-3"}
                    register={register2}
                  />
                  <FormInput
                    label="Password"
                    type="password"
                    name="password"
                    placeholder="Enter name"
                    containerClass={"mb-3"}
                    register={register2}
                  />
                  <div className="text-end">
                    <Button
                      variant="success"
                      type="submit"
                      className="waves-effect waves-light me-1"
                    >
                      Save
                    </Button>
                    <Button
                      variant="danger"
                      className="waves-effect waves-light"
                      onClick={() => {
                        // reset({
                        //   name: "",
                        //   email: "",
                        //   roles: "",
                        //   contactNo: "",
                        //   password: "",
                        //   isAvailable:""
                        // })
                        setResponsiveModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </form>
              </>
            }
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default HotelOutLetEditForm;
