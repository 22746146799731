import React from "react";
import { Row, Col, Card } from "react-bootstrap";

// components
import PageTitle from "../../components/PageTitle";
import FileUploader from "../../components/FileUploader";

const FileUpload = ({uploadFiles, name, files}: any) => {
  console.log('te files', files)
  return (
    <React.Fragment>
      {/* <PageTitle
        breadCrumbItems={[
          { label: "Forms", path: "/forms/upload" },
          { label: "Form Upload", path: "/forms/upload", active: true },
        ]}
        title={"Form Upload"}
      /> */}

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <FileUploader
              files={files}
                onFileUpload={(files) => {
                  console.log("Uploaded files - ", files);
                  uploadFiles(files, name)
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FileUpload;
