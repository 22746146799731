import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  InvoiceById,
  updateInvoice,
  updateInvoiceLoading as updateInvoiceLoadingAction,
} from "../../redux/invoice/actions";
import { RootState } from "../../redux/store";
import { Card, Col, Row, Form, Button } from "react-bootstrap";
import { Loader, Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import FormInput from "../../components/FormInput";

const EditInvoice = () => {
  // inital imports
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceByIdLoading, invoiceById, updateInvoiceLoading } = useSelector(
    (state: RootState) => state.InvoiceReducer
  );
  const navigate = useNavigate();
  const [finalAmountState, setFinalAmount] = useState(false);
  const [initialAmountState, setInitialAmount] = useState(false);

  // inital loading
  useEffect(() => {
    dispatch(InvoiceById(id));
  }, []);

  useEffect(() => {
    reset({
      invoiceUID: invoiceById[0] ? invoiceById[0].invoiceUID : "",
      status: invoiceById[0] ? invoiceById[0].status : "",
      totalAmount: invoiceById[0] ? invoiceById[0].totalAmount : "0",
      invoiceDate: invoiceById[0]
        ? moment(invoiceById[0]?.invoiceDate).format("YYYY-MM-DD")
        : "",
      receivedDate: invoiceById[0]
        ? moment(invoiceById[0]?.receivedDate).format("YYYY-MM-DD")
        : "",
      finalAmount: invoiceById[0] ? invoiceById[0].finalAmount : "",
      initialAmount: invoiceById[0] ? invoiceById[0].initialAmount : "",
      initialDate: invoiceById[0]
        ? moment(invoiceById[0]?.initialDate).format("YYYY-MM-DD")
        : "",
      final_date: "",
      invoice_id: id,
    });
  }, [invoiceById]);

  const methods = useForm({
    defaultValues: {
      invoice_id: "",
      invoiceUID: "",
      status: "",
      totalAmount: "",
      invoiceDate: "",
      receivedDate: "",
      finalAmount: "",
      initialAmount: "",
      initialDate: "",
      final_date: "",
    },
  });
  const {
    handleSubmit,
    register,
    control: controlUsers,
    formState: { errors },
    getValues,
    reset,
    formState,
    formState: { isSubmitSuccessful },
  } = methods;

  //   edit invoice subbmit
  const editInvoice = () => {
    console.log("the submitted values", getValues());
    const payload = {
      invoice_id: id,
      status: getValues().status,
      initial_payment: initialAmountState,
      initial_date: getValues().initialDate,
      final_date: getValues().final_date,
      received_date: getValues().receivedDate,
      final_payment: finalAmountState,
    };
    dispatch(updateInvoiceLoadingAction({}));
    dispatch(updateInvoice(payload));
  };
  console.log("the lits", id, invoiceByIdLoading, invoiceById);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3%",
          marginBottom: "2%",
        }}
      >
        <h1>Edit Invoice</h1>
        <Button
          style={{ float: "right" }}
          variant="success"
          className="waves-effect waves-light"
          onClick={editInvoice}
        >
          {updateInvoiceLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                aria-hidden="true"
              ></span>
              <span> Edit Invoice Details</span>
            </>
          ) : (
            <>
              <i className="mdi mdi-plus-circle me-1"></i> Edit Invoice Details
            </>
          )}
        </Button>
      </div>
      <Card>
        <Card.Body>
          {invoiceByIdLoading ? (
            <Loader />
          ) : (
            <div>
              <form
              // onSubmit
              // ={handleSubmitForNewInvoices(() =>
              //   submitNewInvoice(getNewInvoiceValues(), selectedHotel, getInvoiceItems())
              // )}
              ></form>
              <Row>
                <Col md={4}>
                  <Controller
                    control={controlUsers}
                    name="invoiceUID"
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Invoice UID
                        </label>
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="Invoice UID"
                          {...register(`invoiceUID`)}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    control={controlUsers}
                    name="totalAmount"
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Total Amount
                        </label>
                        <Form.Control
                          type="number"
                          disabled
                          placeholder="Total Amount"
                          {...register(`totalAmount`)}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    control={controlUsers}
                    name="invoiceDate"
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Invoice Date
                        </label>
                        <Form.Control
                          type="date"
                          disabled
                          placeholder="Invoice Date"
                          {...register(`invoiceDate`)}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Controller
                    control={controlUsers}
                    name="status"
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <FormInput
                          style={{
                            width: "100%",
                            height: "auto",
                            position: "relative",
                            left: "0",
                            top: 0,
                            margin: 0,
                          }}
                          label="Status"
                          type="select"
                          name="status"
                          key="selectMulti"
                          register={register}
                          placeholder="Select a roles"
                          containerClass={"mb-3"}
                          defaultValue={
                            invoiceById[0] ? invoiceById[0].status : ""
                          }
                        >
                          <option value="PENDING">PENDING</option>
                          <option value="SUBMITTED">SUBMITTED</option>
                          <option value="RECEIVED BACK">RECEIVED BACK</option>
                          <option value="RECEIVED BY THIRD PARTY">
                            RECEIVED BY THIRD PARTY
                          </option>
                        </FormInput>
                      </div>
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Controller
                    control={controlUsers}
                    name="receivedDate"
                    render={({ field, fieldState }: any) => (
                      <div className="mb-3">
                        <label htmlFor="typeahead" className="form-label">
                          Received Date
                        </label>
                        <Form.Control
                          type="date"
                          disabled
                          placeholder="Recevied Date"
                          {...register(`receivedDate`)}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label>Initial amount</label>
                  <Form.Check
                    // defaultChecked={(selectedtomerge.findIndex((invoice:any) => invoice === details._id) !== -1) ?  false : true}
                    type="switch"
                    id="custom-switch"
                    // disabled={count === 2 ? true : false}
                    onChange={(e) => setInitialAmount(e.target.checked)}
                  />
                </Col>
                {initialAmountState && (
                  <Col md={5}>
                    <Controller
                      control={controlUsers}
                      name="initialAmount"
                      render={({ field, fieldState }: any) => (
                        <div className="mb-3">
                          <label htmlFor="typeahead" className="form-label">
                            Initial Amount
                          </label>
                          <Form.Control
                            type="number"
                            placeholder="Initial amount"
                            {...register(`initialAmount`)}
                          />
                        </div>
                      )}
                    />
                  </Col>
                )}
                {initialAmountState && (
                  <Col md={5}>
                    <Controller
                      control={controlUsers}
                      name="initialDate"
                      render={({ field, fieldState }: any) => (
                        <div className="mb-3">
                          <label htmlFor="typeahead" className="form-label">
                            Initial Date
                          </label>
                          <Form.Control
                            type="date"
                            placeholder="Initial Date"
                            {...register(`initialDate`)}
                          />
                        </div>
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={2}>
                  <label>Final Payment</label>
                  <Form.Check
                    // defaultChecked={(selectedtomerge.findIndex((invoice:any) => invoice === details._id) !== -1) ?  false : true}
                    type="switch"
                    id="custom-switch"
                    // disabled={count === 2 ? true : false}
                    onChange={(e) => setFinalAmount(e.target.checked)}
                  />
                </Col>
                {finalAmountState && (
                  <Col md={5}>
                    <Controller
                      control={controlUsers}
                      name="finalAmount"
                      render={({ field, fieldState }: any) => (
                        <div className="mb-3">
                          <label htmlFor="typeahead" className="form-label">
                            Final Amount
                          </label>
                          <Form.Control
                            type="number"
                            // disabled
                            placeholder="Final amount"
                            {...register(`finalAmount`)}
                          />
                        </div>
                      )}
                    />
                  </Col>
                )}
                {finalAmountState && (
                  <Col md={5}>
                    <Controller
                      control={controlUsers}
                      name="final_date"
                      render={({ field, fieldState }: any) => (
                        <div className="mb-3">
                          <label htmlFor="typeahead" className="form-label">
                            Final Date
                          </label>
                          <Form.Control
                            type="date"
                            placeholder="Recevied Date"
                            {...register(`final_date`)}
                          />
                        </div>
                      )}
                    />
                  </Col>
                )}
              </Row>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default EditInvoice;
