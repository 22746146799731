import { Form } from "react-bootstrap";
// import { SupportTableColums } from "../../types/support";


export const searchFilterForGroups = 
[
    {
        key:"page"
    },
    {
        key: "limit"
    },
    {
        key: "hotel"
    },
    {
        key: "outlet"
    }
]

// table columns
export const SupportTableColumns =  (data: any, changeAvailability: any) => {
   const arr1: any= [];
   data?.data?.map((details:any) => {
   return details?.users?.map((staff:any) => {
     return   arr1.push(staff)
    })
    
   })
    console.log('the support tal', data )
const arr:any= [];
data?.data?.map((details:any) => {
     arr.push({
        date: details.date,
        fullName:details.fullName,
        contactNumber: details.contactNumber,
        hotel: details.hotel,
        outlet: details.outlet,
        shiftName: details.shiftName,
        slotId: details.slot_id,
        status: details.status
    }) 
})

return arr;
}

