// constants
import { OutletsActionTypes } from "./constants";

export interface OutletsActionType {
  type:
    | OutletsActionTypes.API_OUTLETS_RESPONSE_SUCCESS
    | OutletsActionTypes.API_OUTLETS_RESPONSE_ERROR
    | OutletsActionTypes.LIST_OUTLETS
    | OutletsActionTypes.CREATE_OUTLETS
    | OutletsActionTypes.EDIT_OUTLETS
    | OutletsActionTypes.DELET_OUTLETS
    | OutletsActionTypes.RESET_OUTLETS
    | OutletsActionTypes.SEARCH_OUTLETS_BY_HOTELS
    | OutletsActionTypes.ADD_OUTLET_USERS
    | OutletsActionTypes.GET_COLUMNS
    | OutletsActionTypes.CREATE_COLUMNS
    | any;
  payload: any;
}

interface OutletsData {
  type: string;
  group_name: string;
  users: Array<[]>;
  hotel?: string;
  outlet?: string;
}

// common succes
export const apiOutletsResponseSucces = (
  actionType: string,
  data: OutletsData | {}
): OutletsActionType => ({
  type: OutletsActionTypes.API_OUTLETS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiOutletsResponseError = (
  actionType: string,
  error: string
): any => ({
  type: OutletsActionTypes.API_OUTLETS_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingOutlets = (
  actionType: string,
  searchParams: any
): any => ({
  type: OutletsActionTypes.LIST_OUTLETS,
  payload: searchParams,
});

export const addOutlets = (
  searchParams: any
): any => ({
  type: OutletsActionTypes.CREATE_OUTLETS,
  payload: searchParams,
});

export const listingOutletsByhotels = (
  searchParams: Object
): any => ({
  type: OutletsActionTypes.SEARCH_OUTLETS_BY_HOTELS,
  payload: searchParams,
});
export const editSelectedOutlets = (
  payload: any,
): any => ({
  type:OutletsActionTypes.EDIT_OUTLETS_SELECTED,
  payload: payload,
})

export const editSelectedOutlet = (
  payload:any
): any => ({
  type: OutletsActionTypes.EDIT_OUTLETS,
  payload: payload
})
export const deleteOutlets =(
  id: any
): any => ({
  type:OutletsActionTypes.DELET_OUTLETS,
  id:id
})

export const createOutletUsers =(
details: any
): any => ({
  type:OutletsActionTypes.ADD_OUTLET_USERS,
  details:details
})
export const createColumns =(
  details: any
  ): any => ({
    type:OutletsActionTypes.CREATE_COLUMNS,
    details:details
  })
export const getColumns =(
  
  ): any => ({
    type:OutletsActionTypes.GET_COLUMNS,
    details:{}
  })

export const resetSelectedOutlets = (): OutletsActionType => ({
  type: OutletsActionTypes.RESET_OUTLETS,
  payload: {},
});
