import { Form } from "react-bootstrap";

// table columns
export const WalletTableColumns =  (data: any, confirmWallet: any) => {
    console.log('the wallet tal', data?.finance_list)
const arr:any= [];
 data?.finance_list?.map((details:any) => {
     arr.push({
        id: details._id,
        amount:details.amount,
        category:details.category,
        subCategoryName: details.subCategoryName,
        status:  <Form>
        <Form.Check
          defaultChecked={details.status === "APPROVED" ? true: false}
          type="switch"
          id="custom-switch"
          disabled={details.status === "APPROVED" ? true: false}
          onChange={(e) => confirmWallet(e.target.checked, details)}
        />
      </Form>
    }) 
})

return arr;
}

export const PendingFinanceTableColumns =  (data: any, confirmWallet: any) => {
  console.log('the wallet tal', data?.finance_list)
const arr:any= [];
data?.data?.map((details:any) => {
   arr.push({
      id: details._id,
      amount:details.amount,
      category:details.category,
      subCategoryName: details.subCategory,
      status:  <Form>
      <Form.Check
        defaultChecked={details.status === "APPROVED" ? true: false}
        type="switch"
        id="custom-switch"
        // disabled={details.status === "APPROVED" ? true: false}
        onChange={(e) => confirmWallet(e.target.checked, details)}
      />
    </Form>
  }) 
})

return arr;
}