import { Modal, Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";

const CreateWalletRequestModal = ({
  responsiveModalForAddWalletRequest,
  toggleResponsiveModalForAddWalletRequest,
  addHotelUsersForAddSlots,
  filterValueChangeByHotelsOrOutlets,
  hotelsList,
  outletsList,
  setResponsiveModalForAddWalletRequest,
  searchByName,
  usersList
}: any) => {
  const methodsForUser = useForm({
    defaultValues: {
      hotel: "",
      outlet: "",
      amount: "",
      remarks: "",
      users:"",
      hhotel: ""
    },
  });
  const {
    handleSubmit: handleSubmitForUsers,
    register: register,
    control: controlUsers,
    getValues: getWalletValues,
    reset: resetWalletValues,
    formState: { errors: errors2 },
  } = methodsForUser;

  return (
    <>
      <Modal
        show={responsiveModalForAddWalletRequest}
        onHide={toggleResponsiveModalForAddWalletRequest}
      >
        <Modal.Body className="p-2">
          <form
            onSubmit={handleSubmitForUsers(() =>
              addHotelUsersForAddSlots(getWalletValues())
            )}
          >
            <Controller
              control={controlUsers}
              name="users"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                  <label htmlFor="typeahead" className="form-label">
                    User
                  </label>
                  <Typeahead
                    {...field}
                    register={register}
                    id="basic-typeahead-single"
                    multiple={false}
                    onInputChange={(e: any) => {
                      searchByName("user", e)
                    }}
                    options={usersList}
                    placeholder="Users"
                  />
                </div>
              )}
            />
             <Controller
              control={controlUsers}
              name="amount"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  Amount
                </label>
                  <Form.Control
                      type="number"
                      placeholder="Amount"
                      {...register(
                        `amount`
                      )}
                       />
                </div>
              )}
            />
            <Controller
              control={controlUsers}
              name="hotel"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                  <label htmlFor="typeahead" className="form-label">
                    Hotel
                  </label>
                  <Typeahead
                    {...field}
                    register={register}
                    id="basic-typeahead-single"
                    multiple={false}
                    onInputChange={(e: any) => {
                      searchByName("hotels", e)
                    }}
                    onChange={(e: any) => {
                      filterValueChangeByHotelsOrOutlets("hotels", e);
                    }}
                    options={hotelsList}
                    placeholder="Hotel"
                  />
                </div>
              )}
            />
            <Controller
              control={controlUsers}
              name="outlet"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                  <label htmlFor="typeahead" className="form-label">
                    Outlets
                  </label>
                  <Typeahead
                    {...field}
                    register={register}
                    id="basic-typeahead-single"
                    multiple={false}
                    onInputChange={(e: any) => {
                      searchByName("outlets", e)
                    }}
                    options={outletsList}
                    placeholder="Outlet"
                  />
                </div>
              )}
            />
            <Controller
              control={controlUsers}
              name="remarks"
              render={({ field, fieldState }: any) => (
                <div className="mb-3">
                 <label
                  htmlFor="typeahead"
                  className="form-label"
                >
                  Amount
                </label>
                  <Form.Control
                      type="textarea"
                      placeholder="Remarks"
                      {...register(
                        `remarks`
                      )}
                       />
                </div>
              )}
            />
            <div className="text-end">
              <Button
                variant="success"
                type="submit"
                className="waves-effect waves-light me-1"
              >
                Save
              </Button>
              <Button
                variant="danger"
                className="waves-effect waves-light"
                onClick={() => {
                  setResponsiveModalForAddWalletRequest(false);
                }}
              >
                Close
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateWalletRequestModal;
