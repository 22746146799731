import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { listSOA } from "../../helpers/api/soa";
import { RootState } from "../../redux/store";
import { DownloadInvoice, listingSOA, listingSOALoading, RemoveSOAById, SendInvoice } from "../../redux/soa/actions";
import CommonSearchBar from "../../layouts/SearchBar";
import { listingHotels } from "../../redux/hotels/actions";
import { Card, Row } from "react-bootstrap";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { count } from "console";
import { InvoiceListTableColumns } from "../invoices/data";
import { SOAListTableColumns } from "./data";

const SOALisitingPage = () => {
  // initial Loading
  const { hotel, page, limit } = useParams();
  const dispatch = useDispatch();
  const { soalistLoading, soalist, downloadSOALoading,sendSOALoading ,removeSOALaoding} = useSelector(
    (state: RootState) => state.SOAReducer
  );
  const { hotelsList } = useSelector((state: RootState) => state.HotelsReducer);
  const navigate = useNavigate();
  // states

  console.log("the soa", soalist, soalistLoading);
  useEffect(() => {
    dispatch(listingHotels({ page: 1, limit: 10000 }));
    if (hotel !== "all") {
      dispatch(listingSOALoading({}));
      dispatch(
        listingSOA({
          page: page,
          limit: limit,
          hotel: (hotel === "all" || hotel === undefined) ? "" : hotel,
        })
      );
    }
  }, [page, limit, downloadSOALoading,sendSOALoading,removeSOALaoding]);

  // soa column
    const columns = [ 
      {
        Header: "Invoice ID",
        accessor: "invoiceUID",
        sort: false,
      },
      {
        Header: "Credited",
        accessor: "creditedAmount",
        sort: false,
      },
      {
        Header: "Charges",
        accessor: "charges",
        sort: false,
      },
      {
        Header:"Un Settled",
        accessor:"unsetteledBalance",
        sort: false
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
        sort: false,
      },
      // {
      //   Header: "Invoice Details",
      //   accessor: "",
      //   Cell: ({ cell }: any) => (
      //     <div
      //       style={{
      //         display: "flex",
      //         justifyContent: "space-evenly",
      //         cursor: "pointer",
      //       }}
      //     >
      //      {/* { cell.row.original.amount ? <span onClick={() => handleEditInvoice(cell.row.original.id)}>
      //         <i className="bi bi-pencil-square"></i>
      //       </span> : <></>}{" "}
      //       &nbsp;
      //       <span onClick={() => handleViewInvoice(cell.row.original.id)}>
      //         <i className="bi bi-eye"></i>
      //       </span> */}
      //     </div>
      //   ),
      // },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ cell }: any) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              cursor: "pointer",
            }}
          >
             <span
             style={{cursor:"pointer"}}
              onClick={() => {
                dispatch(RemoveSOAById(cell.row.original.id));
              }}
            >
              {removeSOALaoding ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                     
                    ></span>
                  </>
                ) : (
                  <>
                    <i className="bi bi-trash3"></i>
                  </>
                )}
             
            </span>
            &nbsp;
            <span
            style={{cursor:"pointer"}}
              onClick={() => {
                dispatch(SendInvoice(cell.row.original.id));
              }}
            >
              {sendSOALoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                      
                    ></span>
                  </>
                ) : (
                  <>
                    <i className="bi bi-send"></i>
                  </>
                )}
             
            </span>
            &nbsp;
            <span
            style={{cursor:"pointer"}}
              onClick={() => {
                console.log("the id", cell);
                dispatch(DownloadInvoice(cell.row.original.id));
              }}
            >
               {downloadSOALoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  <>
                    <i className="bi bi-download"></i>
                  </>
                )}
            </span>
          </div>
        ),
      },
    ];

  const searchByHotelName = (name: any, hotelsValue: any) => {

    if (name === "hotels") {
      // navigate(
      //   `/soa/list/${page}/${limit}/${hotelsValue ? hotelsValue : "all"}`
      // );
      dispatch(
        listingHotels({ hotelName: hotelsValue, limit: 10000, page: 0 })
      );
    }
  };

  const filterValueChangeByHotelsOrOutlets = (name: any, value: any) => {
    if (name === "hotels") {
      navigate(
        `/soa/list/${page}/${limit}/${
          value[0]?.value ? value[0]?.value : "all"
        }`
      );

      dispatch(
        listingSOA({
          limit: limit,
          page: 1,
          hotel: value[0]?.value,
        })
      );
    }
  };
  const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.hotelName ? list.hotelName : " ",
      })
    );
    return hotel;
  };

  //   set default value for filters
  const defaultValue = (options: any, id: any) => {
    const filters = options?.filter((op: any) => op.value === id);
    return filters[0]?.label;
  };

  return (
    <>
      <h1>SOA</h1>
      <CommonSearchBar
        searchByName={searchByHotelName}
        filterValueChanged={filterValueChangeByHotelsOrOutlets}
        filters={[
          {
            label: "Hotels",
            name: "hotels",
            placeholder: "Please select a hotel",
            options: hotelOptionsListForSearch(hotelsList),
            value: defaultValue(hotelOptionsListForSearch(hotelsList), hotel),
            isSelect: true,
          },
        ]}
      />
      <Card style={{marginTop:'2%'}}>
        <Card.Body>
        <Row>
         
            <Table
              columns={columns}
              data={SOAListTableColumns(
                soalist
              )}
              isSortable={true}
              pagination={false}
            />
          </Row>
        {soalistLoading && <Loader />}
        </Card.Body>
      </Card>
    </>
  );
};

export default SOALisitingPage;
