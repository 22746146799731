import { Routes, Route } from "react-router-dom"
import InvoiceListingPage from "./invoiceListingPage"
import EditInvoice from "./editInvoiceDetails"


const Invoice = () => {
  
        return(
            <>
            <Routes>
              <Route path="/" element={<InvoiceListingPage />} />
              <Route 
              path={`/:page/:limit/:hotel/:outlet/:weeknumber/:invoiceName/:status`}
              element={<InvoiceListingPage />} />
              <Route 
              path={`/:id`}
              element={<InvoiceListingPage />} />
               <Route 
              path={`/edit/:id`}
              element={<EditInvoice />} />
            </Routes>
            </>
        )

}

export default Invoice