import axios from "axios";
import config from "../../config";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;
axios.defaults.withCredentials = true;
const AUTH_SESSION_KEY = "logged_user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  localStorage.setItem(AUTH_SESSION_KEY, token);
  if (token) axios.defaults.headers.common["token"] = "JWT " + token;
  else delete axios.defaults.headers.common["token"];
};

const getUserFromCookie = (): any => {
  const user = localStorage.getItem(AUTH_SESSION_KEY);
  return user;
  // return user ? (typeof user == "object" ? user : (user)) : null;
};
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, {
        headers: {
          token: `${localStorage.getItem(AUTH_SESSION_KEY)}`,
          "Content-Type": "application/json", // Overrides any defaults that are set
        },
        withCredentials: true,
      });
    } else {
      response = axios.get(`${url}`, {
        params,
        headers: {
          token: `${localStorage.getItem(AUTH_SESSION_KEY)}`, // Overrides any defaults that are set
        },
        withCredentials: true,
      });
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, {headers: {
        token: `${localStorage.getItem(AUTH_SESSION_KEY)}`,
        "Content-Type": "application/json", // Overrides any defaults that are set
      },
      withCredentials: true, responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any) => {
    return axios.post(url, data, {
      url,
      method: "POST",
      headers: {
        token: `${localStorage.getItem(AUTH_SESSION_KEY)}`, // Overrides any defaults that are set
      },
      withCredentials: true,
    });
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data, {
      headers: {
        token: `${localStorage.getItem(AUTH_SESSION_KEY)}`, // Overrides any defaults that are set
      },
      withCredentials: true,
    });
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data, {
      headers: {
        token: `${localStorage.getItem(AUTH_SESSION_KEY)}`, // Overrides any defaults that are set
      },
      withCredentials: true,
    });
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url, {
      headers: {
        token: `${localStorage.getItem(AUTH_SESSION_KEY)}`, // Overrides any defaults that are set
      },
      withCredentials: true,
    });
  };

  /**
   * post given data to url with file
   */

  /**
   * post given data to url with file
   */

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    } else {
      return true;
    }
  };

  setLoggedInUser = (session: any) => {
    if (session)
      localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      localStorage.removeItem(AUTH_SESSION_KEY);
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

export { APICore, setAuthorization };
