
// constants
import { GroupSupportActionTypes } from "./constants";


const INIT_STATE = {
  supportLoading: false,
  supportList:[],
  supportFilterParams:{},
  selectedSupports:{},
  listingerror:{},
};

interface SupportData {
    id?:String,
    name?:String,
    email?: String,
    contactNo?: String,
    altContactNo?:String,
    password?:String,
    roles?:String,
    isAvailable?: Boolean,
    isDeleted?: Boolean,
    createdAt?: Date,
    updatedAt?:Date,
}

interface AuthActionType {
  type:
    | GroupSupportActionTypes.SUPPORT_API_RESPONSE_ERROR
    | GroupSupportActionTypes.SUPPORT_API_RESPONSE_SUCCESS
    | GroupSupportActionTypes.LIST_SUPPORTS
    | GroupSupportActionTypes.CREATE_SUPPORTS
    | GroupSupportActionTypes.DELETE_SUPPORTS
    | GroupSupportActionTypes.EDIT_SUPPORTS
    | GroupSupportActionTypes.RESET_SUPPORTS
    | GroupSupportActionTypes.UPDATE_ADMIN_STATUS;
  payload: {
    actionType?: string;
    data?: SupportData | {};
    error?: string;
  };
}

interface State {
    supportLoading:Boolean,
    supportList:any[],
    supportFilterParams:{},
    listingerror:{};
}

const SupportReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case GroupSupportActionTypes.SUPPORT_API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GroupSupportActionTypes.LIST_SUPPORTS: {
          return {
            ...state,
            supportList: action.payload.data,
            supportLoading: false,
          };
        }
        case GroupSupportActionTypes.CREATE_SUPPORTS: {
          return {
            ...state,
            supportLoading: false,
          };
        }
        case GroupSupportActionTypes.UPDATE_ADMIN_STATUS:{
          return{
            ...state,
            supportLoading: false
          }
        }
        case GroupSupportActionTypes.EDIT_SUPPORTS: {
          return {
            ...state,
            supportLoading: false,
          };
        }
        case GroupSupportActionTypes.EDIT_SUPPORTS_SELECTED: {
          return {
            ...state,
            supportLoading: false,
            selectedSupports:action.payload.data
          };
        }
        case GroupSupportActionTypes.DELETE_SUPPORTS: {
          return {
            ...state,
            supportLoading: false,
          };
        }
        default:
          return { ...state };
      }

    case GroupSupportActionTypes.SUPPORT_API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GroupSupportActionTypes.LIST_SUPPORTS: {
          return {
            ...state,
            listingerror: action.payload.error,
            supportLoading: false,
          };
        }
        case GroupSupportActionTypes.CREATE_SUPPORTS: {
          return {
            ...state,
            error: action.payload.error,
            supportLoading: false,
          };
        }
        case GroupSupportActionTypes.EDIT_SUPPORTS: {
          return {
            ...state,
            error: action.payload.error,
            supportLoading: false,
          };
        }
        case GroupSupportActionTypes.UPDATE_ADMIN_STATUS: {
          return {
            ...state,
            error: action.payload.error,
            supportLoading: false,
          };
        }
        case GroupSupportActionTypes.DELETE_SUPPORTS: {
            return {
              ...state,
              error: action.payload.data,
              supportLoading: false,
            };
          }
          default:
            return { ...state };
      }
    case GroupSupportActionTypes.RESET_SUPPORTS:
      return {
        ...state,
        supportLoading: false,
        error: false,
        supportFilterParams: {},
      };
    default:
      return { ...state };
  }
};

export default SupportReducer;
