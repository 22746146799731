import { all, fork, put, takeEvery, call, } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// constants
import { FinanceActionTypes } from "./constants";
// actions
import { apiFINANCEResponseError, apiFINANCEResponseSuccess } from "./actions";
import {
  listfinance as listFINANCEApi,
  downloadfinance as downloadFINANCEApi,
  saveFinance as sentFINANCEApi,
  removeFinanceById as removeFINANCEByIdApi,
  listPendingfinance as listPendingfinanceApi,
  approveFinance as approveFinanceApi,
  AddSubcategory as AddSubcategoryApi,
  listSubcategory as listSubcategoryApi
} from "../../helpers/api/finance";
import { toast } from "react-toastify";

function* createSubCategoryLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.CREATE_SUBCATEGORY_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.CREATE_SUBCATEGORY_LOADING, err)
    );
  }
}

function* createSubCategory({ payload,type }: any): SagaIterator {
  try {
    const response = yield call(AddSubcategoryApi, payload);
    toast.success(response.data.info.msg);
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.CREATE_SUCATEGORY, {})
    );
  } catch (err: any) {
    toast.error(err.response.data.error.msg);
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.CREATE_SUCATEGORY, err)
    );
  }
}

function* listinFINANCELoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.LIST_FINANCE_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.LIST_FINANCE_LOADING, err)
    );
  }
}

function* listinFINANCE({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(listFINANCEApi, payload);
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.LIST_FINANCE, response?.data?.data)
    );
   
  } catch (err: any) {
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.LIST_FINANCE, err)
    );
  }
}

function* listingsubcategories({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(listSubcategoryApi, payload);
    console.log("the respon on  subcate", response)
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.LISTING_SUBCATEGORY, response?.data?.result)
    );
   
  } catch (err: any) {
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.LISTING_SUBCATEGORY, err)
    );
  }
}

function* ApproveFINANCE({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(approveFinanceApi, payload);
    toast.success(response.data.info.msg);
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.APPROVE_FINANCE, {})
    );
   
  } catch (err: any) {
    toast.error(err.response.data.error.msg);
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.APPROVE_FINANCE, err)
    );
  }
}



function* listinFINANCEPendingLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.LIST_FINANACE_PENDINNG_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.LIST_FINANACE_PENDINNG_LOADING, err)
    );
  }
}

function* listinPendingFINANCE({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(listPendingfinanceApi, payload);
    console.log("the resdada", response)
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.PENDING_FINANCE, response?.data?.result)
    );
   
  } catch (err: any) {
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.PENDING_FINANCE, err)
    );
  }
}



function* downloadFINANCELoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.DOWNLOAD_FINANCE_LOADING, {})
    );
   
  } catch (err: any) {
    yield put(apiFINANCEResponseError(FinanceActionTypes.DOWNLOAD_FINANCE_LOADING, err));
  }
}

function* downloadFINANCE({ payload, type }: any): SagaIterator {
  try {
    console.log("the dow", payload)
    const response = yield call(downloadFINANCEApi, payload);
    yield put(
      apiFINANCEResponseSuccess(
        FinanceActionTypes.DOWNLOAD_FINANCE,
        response.data.result
      )
    );
    toast.success(response.data.info.msg);
  } catch (err: any) {
    toast.error(err.response.data.error.msg);
    yield put(apiFINANCEResponseError(FinanceActionTypes.DOWNLOAD_FINANCE, err));
  }
}

function* saveFINANCELoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.SAVE_FINANCE_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.SAVE_FINANCE_LOADING, err)
    );
  }
}

function* saveFINANCE({ payload, type }: any): SagaIterator {
  try {
    console.log("the paylaod in sending", payload);
    const response = yield call(sentFINANCEApi, payload);
    yield put(
      apiFINANCEResponseSuccess(
        FinanceActionTypes.SAVE_FINANCE,
        response.data.result
      )
    );
    toast.success(response.data.info.msg);
  } catch (err: any) {
    toast.error(err.response.data.error.msg);
    yield put(apiFINANCEResponseError(FinanceActionTypes.SAVE_FINANCE, err));
  }
}

function* removeFINANCEByIdLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiFINANCEResponseSuccess(FinanceActionTypes.DELETE_FINANCE_BY_ID_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.DELETE_FINANCE_BY_ID_LOADING, err)
    );
  }
}

function* removeFINANCEById({ payload, type }: any): SagaIterator {
  try {
    debugger;
    const response = yield call(removeFINANCEByIdApi, payload);
    yield put(
      apiFINANCEResponseSuccess(
        FinanceActionTypes.DELETE_FINANCE_BY_ID,
        response.data.result
      )
    );
    toast.success(response.data.info.msg);
  } catch (err: any) {
    toast.error(err.response.data.error.msg);
    yield put(
      apiFINANCEResponseError(FinanceActionTypes.DELETE_FINANCE_BY_ID, err)
    );
  }
}



// watch

export function* watchListingFINANCELoading() {
  yield takeEvery(
    FinanceActionTypes.LIST_FINANCE_LOADING,
    listinFINANCELoading
  );
}
export function* watchListingSubCategory() {
  yield takeEvery(
    FinanceActionTypes.LISTING_SUBCATEGORY,
    listingsubcategories
  );
}
export function* watchListingFINANCEs() {
  yield takeEvery(FinanceActionTypes.LIST_FINANCE, listinFINANCE);
}
export function* watchCreateSubCategoryLoading() {
  yield takeEvery(FinanceActionTypes.CREATE_SUBCATEGORY_LOADING, createSubCategoryLoading);
}
export function* watchCreateSubCategory() {
  yield takeEvery(FinanceActionTypes.CREATE_SUCATEGORY, createSubCategory);
}

export function* watchApproveFinance(){
  yield takeEvery(FinanceActionTypes.APPROVE_FINANCE, ApproveFINANCE)
}

export function* watchListingFINANCEPendingLoading() {
  yield takeEvery(
    FinanceActionTypes.LIST_FINANACE_PENDINNG_LOADING,
    listinFINANCEPendingLoading
  );
}

export function* watchListingPendingFINANCEs() {

  yield takeEvery(FinanceActionTypes.PENDING_FINANCE, listinPendingFINANCE);
}

export function* watchDownloadingFINANCEs() {
  yield takeEvery(
    FinanceActionTypes.DOWNLOAD_FINANCE_LOADING,
    downloadFINANCELoading
  );
}
export function* watchDownloadFINANCEs() {
  yield takeEvery(FinanceActionTypes.DOWNLOAD_FINANCE, downloadFINANCE);
}
export function* watchSendingFINANCEs() {
  yield takeEvery(
    FinanceActionTypes.SAVE_FINANCE_LOADING,
    saveFINANCELoading
  );
}
export function* watchSendFINANCEs() {
  yield takeEvery(FinanceActionTypes.SAVE_FINANCE, saveFINANCE);
}

export function* watchRemoveFINANCEsByIdLoading() {
  yield takeEvery(
    FinanceActionTypes.DELETE_FINANCE_BY_ID_LOADING,
    removeFINANCEByIdLoading
  );
}

export function* watchRemoveFINANCEsById() {
  yield takeEvery(FinanceActionTypes.DELETE_FINANCE_BY_ID, removeFINANCEById);
}



function* FinanceSaga() {
  yield all([
    fork(watchListingFINANCEs),
    fork(watchListingFINANCELoading),
    fork(watchDownloadingFINANCEs),
    fork(watchDownloadFINANCEs),
    fork(watchSendingFINANCEs),
    fork(watchSendFINANCEs),
    fork(watchRemoveFINANCEsByIdLoading),
    fork(watchRemoveFINANCEsById),
    fork(watchListingFINANCEPendingLoading),
    fork(watchListingPendingFINANCEs),
    fork(watchApproveFinance),
    fork(watchCreateSubCategoryLoading),
    fork(watchCreateSubCategory),
    fork(watchListingSubCategory)
  ]);
}

export default FinanceSaga;
