import { APICore } from "./apiCore";

const api = new APICore();

export function listStaffAttendances(payload: any){
    const baseUrl = "staffAttendance/list"; 
    const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
        
    return api.get(`${baseUrl}?${params}`)
}

export function updateStaffAttendanceById(details: any){
    const baseUrl = "staffAttendance/update"; 
    return api.updatePatch(`${baseUrl}`,details)
}

export function completeStaffAttendanceById(id: any){
    const baseUrl = "/staffattendance/complete"; 
    return api.updatePatch(`${baseUrl}/${id}`, {})
}






