export enum OutletsActionTypes {
  API_USERS_OUTLETS_RESPONSE_SUCCESS = "@@outlets/API_USERS_OUTLETS_RESPONSE_SUCCESS",
  API_USERS_OUTLETS_RESPONSE_ERROR = "@@outlets/API_USERS_OUTLETS_RESPONSE_ERROR",

  LIST_USERS_OUTLETS = "@@outlets/LIST_USERS_OUTLETS",
  CREATE_USERS_OUTLETS = "@@outlets/CREATE_USERS_OUTLETS",
  EDIT_USERS_OUTLETS = "@@outlets/EDIT_USERS_OUTLETS",
  DELET_USERS_OUTLETS = "@@outlets/DELETE_USERS_OUTLETS",
  EDIT_USERS_OUTLETS_SELECTED = "@@outlets/EDIT_USERS_OUTLETS_SELECTED",
  SEARCH_USERS_OUTLETS_BY_HOTELS = "@@outlets/SEARCH_USERS_OUTLEST_BY_HOTELS",

  RESET_USERS_OUTLETS = "@@outlets/RESET_OUTLETS",
}
