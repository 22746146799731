import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WalletListPage from './FinanceList';


const FinancePages = () => {

    return(
        <>
        <Routes>
          <Route path="/" element={<WalletListPage />} />
          <Route 
          path={`/:page/:limit/:tab/:name`}
          element={<WalletListPage />} />
           <Route 
          path={`?add=`}
          element={<WalletListPage />} />
        </Routes>
        </>
    )
}

export default FinancePages;