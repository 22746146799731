// constants
import { HotelActionTypes } from "./constants";

export interface HotelActionType {
  type:
    | HotelActionTypes.API_HOTELS_RESPONSE_SUCCESS
    | HotelActionTypes.API_HOTELS_RESPONSE_ERROR
    | HotelActionTypes.LIST_HOTELS
    | HotelActionTypes.CREATE_HOTELS
    | HotelActionTypes.EDIT_HOTELS
    | HotelActionTypes.DELET_HOTELS
    | HotelActionTypes.RESET_HOTELS
  payload: {} | string;
}

interface HotelData {
  type: string;
  group_name: string;
  users: Array<[]>;
  hotel?: string;
  outlet?: string;
}

// common success
export const apiHotelsResponseSuccess = (
  actionType: string,
  data: HotelData | {}
): HotelActionType => ({
  type: HotelActionTypes.API_HOTELS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiHotelsResponseError = (
  actionType: string,
  error: string
): HotelActionType => ({
  type: HotelActionTypes.API_HOTELS_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingHotels = (
  searchParams: Object
): any => ({
  type: HotelActionTypes.LIST_HOTELS,
  payload: searchParams,
});
export const createHotels = (
  payload: any,
): any => ({
  type:HotelActionTypes.CREATE_HOTELS,
  payload: payload,
})
export const editSelectedHotels = (
  payload: any,
): any => ({
  type:HotelActionTypes.EDIT_HOTELSS_SELECTED,
  payload: payload,
})
export const updateSelectedHotels = (
  payload: any,
): any => ({
  type:HotelActionTypes.EDIT_HOTELS,
  payload: payload,
})
export const deleteHotel =(
  id: any
): any => ({
  type:HotelActionTypes.DELET_HOTELS,
  id:id
})
// export const logoutUser = (): HotelActionType => ({
//   type: HotelActionTypes.LOGOUT_USER,
//   payload: {},
// });

// export const signupUser = (
//   fullname: string,
//   email: string,
//   password: string
// ): HotelActionType => ({
//   type: HotelActionTypes.SIGNUP_USER,
//   payload: { fullname, email, password },
// });

// export const forgotPassword = (username: string): HotelActionType => ({
//   type: HotelActionTypes.FORGOT_PASSWORD,
//   payload: { username },
// });

export const resetSelectedHotels = (): HotelActionType => ({
  type: HotelActionTypes.RESET_HOTELS,
  payload: {},
});
