import { all, fork, put, takeEvery, call, } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// constants
import { SOAActionTypes } from "./constants";
// actions
import { apiSOAResponseError, apiSOAResponseSuccess } from "./actions";
import {
  listSOA as listSOAApi,
  downloadSOA as downloadSOAApi,
  sentSOA as sentSOAApi,
  removeSOAById as removeSOAByIdApi
} from "../../helpers/api/soa";
import { toast } from "react-toastify";

function* listinSOALoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiSOAResponseSuccess(SOAActionTypes.LIST_SOA_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiSOAResponseError(SOAActionTypes.LIST_SOA_LOADING, err)
    );
  }
}

function* listinSOA({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(listSOAApi, payload);
    yield put(
      apiSOAResponseSuccess(SOAActionTypes.LIST_SOA, response?.data?.result)
    );
   
  } catch (err: any) {
    yield put(
      apiSOAResponseError(SOAActionTypes.LIST_SOA, err)
    );
  }
}



function* downloadSOALoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiSOAResponseSuccess(SOAActionTypes.DOWNLOAD_SOA_LOADING, {})
    );
   
  } catch (err: any) {
    yield put(apiSOAResponseError(SOAActionTypes.DOWNLOAD_SOA_LOADING, err));
  }
}

function* downloadSOA({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(downloadSOAApi, payload);
    yield put(
      apiSOAResponseSuccess(
        SOAActionTypes.DOWNLOAD_SOA,
        response.data.result
      )
    );
    toast.success(response.data.info.msg);
  } catch (err: any) {
    toast.error(err.response.data.error.msg);
    yield put(apiSOAResponseError(SOAActionTypes.DOWNLOAD_SOA, err));
  }
}

function* sendingSOALoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiSOAResponseSuccess(SOAActionTypes.SEND_SOA_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiSOAResponseError(SOAActionTypes.SEND_SOA_LOADING, err)
    );
  }
}

function* sendSOA({ payload, type }: any): SagaIterator {
  try {
    console.log("the paylaod in sending", payload);
    const response = yield call(sentSOAApi, payload);
    yield put(
      apiSOAResponseSuccess(
        SOAActionTypes.SEND_SOA,
        response.data.result
      )
    );
    toast.success(response.data.info.msg);
  } catch (err: any) {
    toast.error(err.response.data.error.msg);
    yield put(apiSOAResponseError(SOAActionTypes.SEND_SOA, err));
  }
}

function* removeSOAByIdLoading({ type }: any): SagaIterator {
  try {
    yield put(
      apiSOAResponseSuccess(SOAActionTypes.REMOVE_SOA_BY_ID_LOADING, {})
    );
  } catch (err: any) {
    yield put(
      apiSOAResponseError(SOAActionTypes.REMOVE_SOA_BY_ID_LOADING, err)
    );
  }
}

function* removeSOAById({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(removeSOAByIdApi, payload);
    yield put(
      apiSOAResponseSuccess(
        SOAActionTypes.REMOVE_SOA_BY_ID,
        response.data.result
      )
    );
    toast.success(response.data.info.msg);
  } catch (err: any) {
    toast.error(err.response.data.error.msg);
    yield put(
      apiSOAResponseError(SOAActionTypes.REMOVE_SOA_BY_ID, err)
    );
  }
}



// watch
export function* watchListingSOALoading() {
  yield takeEvery(
    SOAActionTypes.LIST_SOA_LOADING,
    listinSOALoading
  );
}
export function* watchListingSOAs() {
  yield takeEvery(SOAActionTypes.LIST_SOA, listinSOA);
}

export function* watchDownloadingSOAs() {
  yield takeEvery(
    SOAActionTypes.DOWNLOAD_SOA_LOADING,
    downloadSOALoading
  );
}
export function* watchDownloadSOAs() {
  yield takeEvery(SOAActionTypes.DOWNLOAD_SOA, downloadSOA);
}
export function* watchSendingSOAs() {
  yield takeEvery(
    SOAActionTypes.SEND_SOA_LOADING,
    sendingSOALoading
  );
}
export function* watchSendSOAs() {
  yield takeEvery(SOAActionTypes.SEND_SOA, sendSOA);
}

export function* watchRemoveSOAsByIdLoading() {
  yield takeEvery(
    SOAActionTypes.REMOVE_SOA_BY_ID_LOADING,
    removeSOAByIdLoading
  );
}

export function* watchRemoveSOAsById() {
  yield takeEvery(SOAActionTypes.REMOVE_SOA_BY_ID_LOADING, removeSOAById);
}



function* soaSaga() {
  yield all([
    fork(watchListingSOAs),
    fork(watchListingSOALoading),
    fork(watchDownloadingSOAs),
    fork(watchDownloadSOAs),
    fork(watchSendingSOAs),
    fork(watchSendSOAs),
    fork(watchRemoveSOAsByIdLoading),
    fork(watchRemoveSOAsById)
  ]);
}

export default soaSaga;
