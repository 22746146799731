export enum ChatActionTypes {
    API_RESPONSE_SUCCESS = "@@chat/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@chat/API_RESPONSE_ERROR",
  
    GET_CHATS = "@@chat/GET_CHATS",
    GET_MESSAGES ="@@chat/GET_MESSAGES",

    GET_CHATS_LOADING = "@@chat/GET_CHATS_LOADING",
    GET_MESSAGES_LOADING= "@@chat/GET_MESSAGES_LOADING",
  
    RESET = "@@chat/RESET",
  }
  