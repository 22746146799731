export enum StaffStatusActionTypes {
  API_STAFF_STATUS_RESPONSE_SUCCESS = "@@staffStatus/API_STAFF_STATUS_RESPONSE_SUCCESS",
  API_STAFF_STATUS_RESPONSE_ERROR = "@@staffStatus/API_STAFF_STATUS_RESPONSE_ERROR",

  LIST_STAFF_STATUS = "@@staffStatus/LIST_STAFF_STATUS",
  LIST_STAFF_STATUS_BY_OUTLET = "@@staffStatus/LIST_STAFF_STATUS_BY_OUTLET",

  LIST_STAFF_STATUS_LOADING = "@@staffStatus/LIST_STAFF_STATUS_LOADING",

  RESET_STAFF_STATUS = "@@staffStatus/RESET_STAFF_STATUS",
}
