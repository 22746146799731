export enum WalletActionTypes {
    API_WALLET_RESPONSE_SUCCESS = "@@wallet/API_WALLET_RESPONSE_SUCCESS",
    API_WALLET_RESPONSE_ERROR = "@@wallet/API_WALLET_RESPONSE_ERROR",
  
    LIST_WALLET_CONFIRMATION_LIST = "@@wallet/LIST_WALLET_CONFIRMATION_LIST",
    LIST_WALLET_CONFIRMATION_LIST_LOADING = "@@wallet/LIST_WALLET_CONFIRMATIONN_LIST_LOADING",
    LIST_REDEEM_LIST = "@@wallet/LIST_REDEEM_LIST",
    LIST_REDEEM_LIST_LOADING= "@@wallet/LIST_REDEEM_LIST_LOADING",
    LIST_PAID_LIST = "@@wallet/LIST_PAID_LIST",
    LIST_PAID_LIST_LOADING ="@@wallet/LIST_PAID_LIST_LOADING",

    CREATE_MANUAL_WALLET_REQUEST = "@@wallet/CREATE_MANUAL_WALLET_REQUEST",
    CREATE_MANUAL_WALLET_REQUEST_LOADING = "@@wallet/CREATE_MANUAL_WALLET_REQUEST_LOADING",

    ACCEPT_PAID_LIST = "@@wallet/ACCEPT_PAID_LIST",
    WALLET_APPROVAL="@@wallet/WALLET_APPROVAL",
 
    RESET_WALLET = "@@wallet/RESET_WALLET",
    NAVIGATION = "@@wallet/NAVIGATION"
  }
  