import { APICore } from "./apiCore";

const api = new APICore();

// account
function listUsersForOutlets(params: any) {
  const baseUrl = "/outlet/list";
  console.log('theparams outlets',params)
  // return axios.get(`${baseUrl}`, params);
  return api.get(`${baseUrl}?&page=${params?.page}&limit=${params?.limit}`, {});
}

function addUsers(params: any) {
  const baseUrl = "/outlet/add";
  return api.get(`${baseUrl}?&page=${params?.page}&limit=${params?.limit}`, {});
}

function listoutletUserByOutleId(params: any) {
  console.log("the value", params)
  const baseUrl = "/outletuser/list";
  return api.get(`${baseUrl}?&page=${params?.page}&limit=${params?.limit}`,{oulet: params.outlet} );
}

function editUserForOutlet(id: any) {
    const baseUrl = "/outlet/getOutlet";
    return api.get(`${baseUrl}/${id}`,{} );
  }
function deleteoutletsUser(id: string) {
  console.log("the id", id)
  const baseUrl = `/outlet/delete?hotelId=${id}`;
  return api.updatePatch(`${baseUrl}`,{});
}



export { listUsersForOutlets,addUsers,listoutletUserByOutleId,editUserForOutlet, deleteoutletsUser};
